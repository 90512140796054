import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  debug: false,
};

export const modalsSlice = createSlice({
  name: 'modalsReducer',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state[action.payload] = true;
    },
    closeModals: () => initialState,
  },
});

export const { openModal, closeModals } = modalsSlice.actions;

export default modalsSlice.reducer;
