import { useAuth } from '../contexts/auth';

const useFilters = () => {
  const { idStudio, userId, level } = useAuth();

  switch (level) {
    case 1:
      //Studio admin
      return {
        defaultStudio: idStudio,
        defaultManager: -1,
      };
    case 2:
      //Manager
      return {
        defaultStudio: idStudio,
        defaultManager: userId,
      };

    case 12:
      //SuperAdmin
      return {
        defaultStudio: -1,
        defaultManager: -1,
      };

    default:
      return {
        defaultStudio: 99999999,
        defaultManager: 9999999,
      };
  }
};
export default useFilters;
