import { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';

import Button from '../atoms/Button';

const CustomizeAvatar = ({
  image,
  handleSelectedAvatar = () => {},
  onDismiss = () => {},
  bgColor = [255, 255, 255, 0.6],
  ...props
}) => {
  const AVATAR_WIDTH = 128;
  const editorRef = useRef(null);
  const [zoom, setZoom] = useState(1.2);
  const [rotate, setRotate] = useState(0);

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  return (
    <div className="flex flex-col items-center">
      <AvatarEditor
        ref={editorRef}
        image={image}
        color={bgColor} // RGBA
        scale={zoom}
        rotate={rotate}
        border={0}
        borderRadius={AVATAR_WIDTH}
        width={AVATAR_WIDTH}
        height={AVATAR_WIDTH}
        {...props}
      />
      <div className="flex flex-col gap-2 p-2 w-full">
        <div className="flex flex-col">
          <span className="font-bold">Zoom</span>
          <input
            type="range"
            min={1}
            max={2}
            step={0.1}
            value={zoom}
            onChange={(e) => setZoom(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <span className="font-bold">Enderezar</span>
          <input
            type="range"
            min={0}
            max={180}
            step={1}
            value={rotate}
            onChange={(e) => setRotate(e.target.value)}
          />
        </div>
      </div>

      <div className="flex flex-col gap-2 w-full">
        <Button
          fullWidth
          onClick={() => {
            if (editorRef) {
              // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
              // drawn on another canvas, or added to the DOM.
              //const canvas = editorRef.current.getImage();

              // If you want the image resized to the canvas size (also a HTMLCanvasElement)
              //const canvasScaled = editorRef.current.getImageScaledToCanvas();

              let image = new Image();
              image.src = editorRef.current
                .getImageScaledToCanvas()
                .toDataURL();

              handleSelectedAvatar(
                DataURIToBlob(
                  editorRef.current.getImageScaledToCanvas().toDataURL()
                )
              );
            }
          }}
        >
          Guardar avatar
        </Button>

        <button
          className="text-red-600 font-semibold text-sm hover:underline"
          onClick={onDismiss}
        >
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default CustomizeAvatar;
