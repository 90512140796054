import { useIntl } from 'react-intl';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { twMerge } from 'tailwind-merge';

import { openModal } from '../store/slices/modals';

import ListsService from '../shared/list-service';

import { useAuth } from '../contexts/auth';

import { SUPERADMIN_LEVEL } from '../shared/constants';

import useFilters from '../hooks/useFilters';

import OutsideClickAlerter from './OutsideClickAlerter';
import Input from './atoms/Input';
import Link from 'next/link';
import { debounce } from '../helpers/utils';

const GlobalSearch = () => {
  const dispatch = useDispatch();
  const { formatMessage: t } = useIntl();
  const MIN_SEARCH_TAG_LENGTH = 3;

  const { level } = useAuth();
  const { defaultManager, defaultStudio } = useFilters();
  const [performersList, setPerformersList] = useState([]);
  const [loading, setLoading] = useState(false);

  const debouncedPromiseOptions = useCallback(
    debounce((searchString, callback) => {
      promiseOptions(searchString).then((options) => callback(options));
    }, 250),
    []
  );

  const promiseOptions = useCallback(
    (searchString) => {
      return new Promise((resolve) => {
        setLoading(true);
        ListsService.getPerformers({
          studio: level === SUPERADMIN_LEVEL ? 0 : defaultStudio,
          manager: level === SUPERADMIN_LEVEL ? 0 : defaultManager,
          page: 0,
          limit: 50,
          sort: 'nick',
          order: 'asc',
          search: searchString,
          banned: 2,
        })
          .then((performersList) => setPerformersList(performersList.data.list))
          .catch((e) => toast.error(e.data.error))
          .finally(() => setLoading(false));
      });
    },
    [defaultManager, defaultStudio, level]
  );

  const cleanSearch = () => {
    setPerformersList([]);
  };

  return (
    <div className="hidden relative sm:block">
      <Input.Text
        autoComplete="off"
        type="search"
        className={twMerge(
          'rounded-lg',
          loading && 'input-search-clean-button-hidden'
        )}
        fullWidth={false}
        placeholder={t({ id: 'search_room' })}
        isLoading={loading}
        onChange={(e) => {
          if (e.target.value === 'devbug') {
            dispatch(openModal('debug'));
            setPerformersList([]);
          }

          if (e.target.value.length < MIN_SEARCH_TAG_LENGTH) {
            setPerformersList([]);
          } else {
            if (e.target.value !== 'devbug')
              debouncedPromiseOptions(e.target.value);
          }
        }}
      />

      {performersList && performersList.length > 0 && (
        <OutsideClickAlerter onClickOutside={cleanSearch}>
          <div className="absolute top-9 right-0 z-10 w-[250px] w-max-[450px] max-h-[80vh] overflow-scroll bg-teal-700 border flex flex-col border-teal-900 text-white">
            <h6 className="p-2 border-b text-sm bg-teal-900 text-center">
              {t({ id: 'search_results' })}
            </h6>
            {performersList.map((performer) => (
              <li
                key={performer.id}
                className="flex items-center gap-1 border-b border-white p-2 hover:bg-teal-900"
              >
                {loading && <span className="block h-5 w-full bg-gray-400" />}

                {!loading && (
                  <Link
                    href={`/performers/${performer.id}`}
                    target="_blank"
                    className="flex items-center gap-2 text-sm"
                    onClick={() => {
                      cleanSearch();
                    }}
                  >
                    <img
                      width="32"
                      height="32"
                      src={performer.profile_photo_sm}
                      className="rounded-full"
                    />
                    {performer.nick}
                  </Link>
                )}
              </li>
            ))}
          </div>
        </OutsideClickAlerter>
      )}
    </div>
  );
};

export default GlobalSearch;
