import { useMonitor } from '../../../contexts/monitor';

import IconUnMute from '../../../public/assets/images/icons/volume-high.svg';

const SoundVolumeControl = () => {
  const { setVolume, volume } = useMonitor();
  return (
    <div className="flex items-center p-1 bg-black/50 rounded-lg gap-1">
      <IconUnMute className="w-4 h-4 text-white" />
      <input
        className="accent-white h-2 rounded-lg range-sm cursor-pointer"
        type="range"
        max={1}
        min={0}
        step={0.1}
        onChange={(e) => setVolume(e.target.value)}
        value={volume}
      />
    </div>
  );
};

export default SoundVolumeControl;
