import PropTypes from 'prop-types';

import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';

import IconClose from '../../public/assets/images/icons/close.svg';

const Card = ({
  children,
  variant = 'default',
  title = null,
  Icon = null,
  closeModal = false,
  footer = null,
  withoutPadding = false,
  className = '',
}) => {
  return (
    <div
      className={twMerge(
        'flex flex-col text-[#4b5056] bg-white b-black/10 min-w-[350px]',
        variant === 'devbug' && 'bg-gray-600',
        variant === 'birthday' && 'bg-gray-800 text-white',
        className
      )}
      variant={variant}
    >
      <div className="flex items-center gap-2 bg-black/5 py-2 px-4 border-b border-black/10">
        {Icon && (
          <Icon
            className={twMerge(
              'w-[22px] h-[22px]',
              variant === 'devbug' && 'text-white',
              variant === 'birthday' && 'text-white'
            )}
          />
        )}
        <div
          className={twMerge(
            'grow shrink font-bold',
            variant === 'devbug' && 'text-white'
          )}
        >
          {title}
        </div>
        {closeModal && (
          <button className="ml-auto" onClick={closeModal}>
            <IconClose className="w-5 h-5 text-gray-800" />
          </button>
        )}
      </div>
      <div
        className={classnames(
          'grow overflow-y-auto',
          withoutPadding && 'p-0',
          !withoutPadding && 'p-4'
        )}
      >
        {children}
      </div>
      {footer && <div className="p-2">{footer}</div>}
    </div>
  );
};

Card.propTypes = {
  icon: PropTypes.element,
  variant: PropTypes.oneOf(['devbug', 'birthday']),
};

export default Card;
