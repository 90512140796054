import { FormattedMessage } from 'react-intl';
import IconClose from '../../public/assets/images/icons/close.svg';
import IconActiveCall from '../../public/assets/images/icons/phone-in-talk.svg';
import IconStopCall from '../../public/assets/images/icons/phone-hangup.svg';
import Logger from '../../shared/logger';
import { useRealtime } from '../../contexts/realtime';
import { useEffect } from 'react';

const logger = new Logger('Realtime - CallRequest');
const CallRequest = () => {
  const { rejectCall, acceptCall, calling, callingId, setRemoteNick } =
    useRealtime();

  useEffect(() => {
    if (!calling) return;
    setRemoteNick(calling);
  }, [calling]);

  return (
    <>
      {calling && (
        <div className="animate-in fade-in shadow-lg">
          <div className="border-1 bg-gray-800 ">
            <div className=" text-white flex justify-between px-2 text-xs font-bold py-3 ">
              <span className="flex-none text-xl">
                <IconActiveCall className="animate-bounce duration-500" />
              </span>
              <span className="flex-auto pl-2 pt-[1px]">
                <FormattedMessage id="call" values={{ nick: calling }} />
              </span>
              <IconClose
                className="cursor-pointer hover:text-black text-xl"
                onClick={(e) => {
                  rejectCall(callingId);
                }}
              />
            </div>
          </div>
          <div className="flex p-4 gap-2 justify-center bg-black/70 ">
            <button
              onClick={(e) => acceptCall(callingId)}
              className="flex gap-1 bg-green-500 rounded-md px-2 py-1 text-white font-semibold text-5xl items-center hover:bg-success/50"
            >
              <IconActiveCall />
            </button>
            <button
              onClick={(e) => rejectCall(callingId)}
              className="flex gap-1 bg-red-600 rounded-md py-1 px-2 text-white font-semibold text-5xl items-center hover:bg-red-600/50"
            >
              <IconStopCall />
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default CallRequest;
