import request from './api';

function getStreamingProfiles() {
  return request({
    url: 'admin/streamingprofiles',
    method: 'GET',
  });
}

const StreamingProfilesService = {
  getStreamingProfiles,
};

export default StreamingProfilesService;
