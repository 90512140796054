import { useIntl } from 'react-intl';
import { useAuth } from '../contexts/auth';
import { useRouter } from 'next/router';
import Link from 'next/link';

import IconMenu from '/public/assets/images/icons/hamburguer.svg';

import GlobalSearch from './GlobalSearch';
import Button from './atoms/Button';
import Alert from '../components/atoms/Alert';
import Modal from '../components/Modal';
import Card from '../components/Card';
import useSideBar from '../hooks/useSidebar';
import Tooltip from '../components/Tooltip';
import Avatar from './Avatar/Avatar';

import IconAccountSettings from '../public/assets/images/icons/cog.svg';
import OldReleaseAlert from './OldReleaseAlert';

const Header = () => {
  const { isAuthenticated } = useAuth();
  const router = useRouter();

  return (
    <>
      {isAuthenticated && !router.asPath.includes('/login') ? (
        <HeaderLogged />
      ) : (
        <HeaderNotLogged />
      )}
      <OldReleaseAlert />
    </>
  );
};

const HeaderLogged = () => {
  const { formatMessage: t } = useIntl();
  const { userName, pwdAboutToExpire, pwdAlertShowed, setPwdAlertShowed } =
    useAuth();
  const { togleMenu } = useSideBar();

  /*   const handleLogout = () => {
    logout();
  }; */

  return (
    <header className="flex justify-between items-center p-2 bg-gray-800 text-white gap-2 border-b-2 border-cyan-200">
      <div className="flex flex-row">
        <button className="sm:hidden" onClick={() => togleMenu('mobile')}>
          <IconMenu width="32" height="32" />
        </button>
        <button
          className="hidden sm:block"
          onClick={() => togleMenu('desktop')}
        >
          <IconMenu width="32" height="32" />
        </button>
        <Link href="/">
          <img
            className="hidden sm:block"
            src="/assets/images/logo.svg"
            alt="Modelsonwebcam Logo"
          />
          <img
            className="sm:hidden"
            src="/assets/images/logo_sm.svg"
            alt="Modelsonwebcam Logo"
          />
        </Link>
      </div>
      <div className="flex gap-3 items-center justify-end">
        <GlobalSearch />

        <div className="relative capitalize flex items-center gap-2">
          <Tooltip content="Click para cambiar tu avatar">
            <Avatar width={48} showUserStatus={true} />
          </Tooltip>

          <div className="flex gap-1 items-center">
            {pwdAboutToExpire && (
              <span className="w-2 h-2 rounded-full block bg-yellow-500" />
            )}
            {userName}
          </div>
        </div>

        <div className="flex items-center">
          <Button href="/settings" color={Button.VARIANT_TRANSPARENT}>
            <Tooltip content={t({ id: 'panel_settings' })}>
              <IconAccountSettings className="w-6 h-6" />
            </Tooltip>
          </Button>

          {/*  <Button onClick={handleLogout} color={Button.VARIANT_TRANSPARENT}>
            <Tooltip content={t({ id: "logout" })}>
              <IconLogout className="w-6 h-6" />
            </Tooltip>
          </Button> */}
        </div>
      </div>

      {pwdAboutToExpire && !pwdAlertShowed && (
        <Modal>
          <Card
            title={t({ id: 'alert_password_will_expire_title' })}
            className="max-w-[460px]"
          >
            <div className="flex flex-col gap-2">
              <Alert variant="warning">
                {t({ id: 'alert_password_will_expire_txt' })}
              </Alert>
              <Button
                href="/settings"
                onClick={() => {
                  setPwdAlertShowed(true);
                }}
                fullWidth
              >
                {t({ id: 'reset_password' })}
              </Button>
            </div>
          </Card>
        </Modal>
      )}
    </header>
  );
};

const HeaderNotLogged = () => {
  return (
    <header className="p-2 bg-gray-800 w-100 border-b-2 border-cyan-200">
      <Link href="/login">
        <img
          src="/assets/images/logo.svg"
          className="mx-auto"
          alt="Modelsonwebcam Logo"
        />
      </Link>
    </header>
  );
};

export default Header;
