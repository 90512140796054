import { FormattedMessage } from 'react-intl';
import IconActiveCall from '../../public/assets/images/icons/phone-in-talk.svg';
import IconStopCall from '../../public/assets/images/icons/phone-hangup.svg';
import Logger from '../../shared/logger';
import { useUserCam } from '../../contexts/userCam';
import { useRealtime } from '../../contexts/realtime';
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { CALL_STATUS } from '../../shared/constants';
const logger = new Logger('Realtime - Call');
const Call = () => {
  const {
    startCall,
    socketId,
    hangCall,
    callStatus,
    setCallStatus,
    callReceiverId,
    setCallReceiverId,
    callingId,
    remoteNick,
    callAccepted,
    setCallAccepted,
    setRemoteUserProperty,
  } = useRealtime();

  const camRef = useRef();
  const camRemoteRef = useRef();

  const { camActive, localStream, remoteStream, enableCam, disableCam } =
    useUserCam();

  useEffect(() => {
    if (!camActive) return;
    if (!camRef.current) return;

    camRef.current.srcObject = localStream.current || null;
    if (!callAccepted) {
      startCall(callReceiverId);
      setCallStatus(CALL_STATUS.PREPARING_CALL);
    }
  }, [camActive, localStream, callAccepted, socketId, callReceiverId]);

  useEffect(() => {
    if (!camRemoteRef.current) return;
    logger.log('Remote stream received', { remoteStream, camRemoteRef });
    setCallStatus(CALL_STATUS.ACTIVE_CALL);
    camRemoteRef.current.srcObject = remoteStream || null;
  }, [remoteStream]);

  const stopCall = () => {
    disableCam();
    const remoteId = callReceiverId ? callReceiverId : callingId;
    hangCall(remoteId);
    setCallStatus(null);
    setRemoteUserProperty('call', null);
    setCallReceiverId(null);
    setCallAccepted(false);
  };

  //This disables user's cam and this component when caller exits
  useEffect(() => {
    if (callStatus === CALL_STATUS.CALL_ENDED) stopCall();
  }, [callStatus]);

  //notify user call status to socket
  useEffect(() => {
    if (!callStatus) return;
    setRemoteUserProperty('call', callStatus);
  }, [callStatus]);

  return (
    <>
      {camActive && (
        <div className="animate-in fade-in shadow-lg w-[320px]">
          <div className="border-1 bg-gray-900 ">
            <div className="text-white flex justify-between px-2 text-xs font-bold py-3 ">
              <span className="flex-none text-xl">
                <IconActiveCall
                  className={classNames(
                    callStatus !== CALL_STATUS.ACTIVE_CALL &&
                      'animate-bounce duration-500 text-yellow-300',
                    callStatus === CALL_STATUS.ACTIVE_CALL && 'text-green-500 '
                  )}
                />
              </span>

              {callStatus === CALL_STATUS.ACTIVE_CALL && (
                <span className="flex-auto pl-2 pt-[1px]">{remoteNick}</span>
              )}
              {callStatus === CALL_STATUS.PREPARING_CALL && (
                <span className="flex-auto pl-2 pt-[1px]">
                  <FormattedMessage
                    id="calling"
                    values={{ nick: remoteNick }}
                  />
                </span>
              )}
              <IconStopCall
                className="cursor-pointer hover:text-red-900 text-xl text-red-600"
                onClick={(e) => {
                  stopCall();
                }}
              />
            </div>
          </div>

          <div className="absolute">
            <video
              ref={camRef}
              autoPlay
              muted
              playsInline
              className="absolute w-[106px] h-[80px] after:object-cover bg-black cursor-move m-1 border border-black"
            />

            <video
              ref={camRemoteRef}
              autoPlay
              playsInline
              className="w-[320px] h-[240px] object-cover bg-black cursor-move"
            />
          </div>
        </div>
      )}
    </>
  );
};
export default Call;
