import request from './api';

function getChatLogs(data) {
  const params = new URLSearchParams(data);
  return request({
    url: `admin/chatlogs?${params.toString()}`,
    method: 'GET',
  });
}

function getPanelReports(data) {
  const params = new URLSearchParams(data);
  return request({
    url: `admin/panelreports?${params.toString()}`,
    method: 'GET',
  });
}

function updateChatLog(data) {
  const params = new URLSearchParams(data);

  return request({
    url: `admin/chatlogs?${params.toString()}`,
    method: 'PUT',
  });
}

function deleteChatLog(id) {
  return request({
    url: `admin/chatlogs?id=${id}`,
    method: 'DELETE',
  });
}

function updatePanelReport(data) {
  const params = new URLSearchParams(data);
  return request({
    url: `admin/panelreports?${params.toString()}`,
    method: 'PUT',
  });
}

function deletePanelReport(id) {
  return request({
    url: `admin/panelreports?id=${id}`,
    method: 'DELETE',
  });
}

function kickRoom(id) {
  return request({
    url: `admin/closeroom/${id}`,
    method: 'DELETE',
  });
}

function kickUser(performer_id, socket_id) {
  return request({
    url: `admin/kick/${performer_id}/${socket_id}`,
    method: 'DELETE',
  });
}

function banUser(data) {
  return request({
    url: 'admin/banuser',
    data,
    method: 'POST',
  });
}

function addReport(data) {
  return request({
    url: `admin/panelreports`,
    data,
    method: 'POST',
  });
}

function searchEndUser(nick) {
  return request({
    url: `endusers/search?nick=${nick}`,
    method: 'GET',
  });
}

const SuperviseService = {
  getPanelReports,
  getChatLogs,
  updateChatLog,
  deleteChatLog,
  updatePanelReport,
  deletePanelReport,
  kickRoom,
  kickUser,
  banUser,
  addReport,
  searchEndUser,
};

export default SuperviseService;
