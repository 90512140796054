import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import IconClose from '../../public/assets/images/icons/close.svg';
import IconChat from '../../public/assets/images/icons/chat.svg';
import IconLoader from '../../public/assets/images/icons/loader.svg';
import IconSend from '../../public/assets/images/icons/send.svg';
import IconActiveCall from '../../public/assets/images/icons/phone-in-talk.svg';
import IconPhone from '../../public/assets/images/icons/call.svg';
import Logger from '../../shared/logger';
import { TextMessage } from './Message';
import { useRealtime } from '../../contexts/realtime';
import { useUserCam } from '../../contexts/userCam';
import { useAuth } from '../../contexts/auth';
import { CALL_STATUS } from '../../shared/constants';
const logger = new Logger('Realtime - Chat');
const Chat = () => {
  const {
    socketConnected,
    setDisplayChats,
    displayChats,
    chats,
    setChats,
    sendMessage,
    setSelectedUser,
    messages,
    setMessages,
  } = useRealtime();
  const { userName: nick, userId, token } = useAuth();
  const [openTab, setOpenTab] = useState(null);
  const intl = useIntl();
  const [message, setMessage] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    setMessages((currentMessages) => {
      return [...currentMessages, { nick, text: message, userId: openTab }];
    });
    sendMessage(message, openTab);
    setMessage('');
  };

  useEffect(() => {
    if (!chats) return;
    if (chats.length === 0) return;
    logger.log('No tab selected, setting first tab');
    if (!openTab) {
      setOpenTab(chats[0].userId);
      markSelectedChat(chats[0].userId);
    }
  }, [chats, openTab]);

  useEffect(() => {
    if (!displayChats) return;
    //set first tab - TODO in case there's more than one chat, mark selected, not the first one
    setOpenTab(chats[0].userId);
    markSelectedChat(chats[0].userId);
  }, [displayChats]);

  const closeChat = () => {
    setDisplayChats(false);
    setOpenTab('none'); //with "none" we avoid useEffect select first chat by default
    markSelectedChat(null);
  };
  const tabClick = (tabId, id) => {
    setOpenTab(tabId);
    setSelectedUser(id);
    markSelectedChat(tabId);
  };

  const markSelectedChat = (tabId) => {
    //flag selected tab on chats state
    const newState = chats.map((obj) => {
      if (obj.userId === tabId) {
        return { ...obj, openTab: true };
      } else {
        return { ...obj, openTab: false };
      }
      return obj;
    });

    setChats(newState);
  };

  //filters messages for current tab
  const reduced = messages.reduce(function (result, option) {
    if (option.userId === openTab) {
      return result.concat({
        nick: option.nick,
        text: option.text,
      });
    }

    return result;
  }, []);
  return (
    <>
      {displayChats && token && (
        <div className=" shadow-lg bg-white/90">
          <div className="border-1 bg-gray-500/80 lg:bg-gray-500/80 xl:bg-gray-500/80 ">
            <div className="bg-bg-gray-800 text-white flex justify-between px-2 text-xs font-bold py-3 ">
              <span className="flex-none text-xl">
                <IconChat />
              </span>
              <span className="flex-auto pl-2 pt-[1px]">Chats</span>
              <IconClose
                className="cursor-pointer hover:text-black text-xl"
                onClick={(e) => {
                  closeChat();
                }}
              />
            </div>
          </div>
          <div className="flex flex-col items-left justify-left mt-1 border">
            {chats && chats.length > 0 && (
              <ul className="flex gap-1">
                {chats.map((tab) => {
                  return (
                    <li key={tab.userId}>
                      <a
                        href="#"
                        onClick={() => tabClick(tab.userId, tab.id)}
                        className={classNames(
                          'inline-block px-4 py-2 rounded shadow border-gray-200',
                          'cursor-pointer hover:opacity-70 h-full text-center',
                          openTab === tab.userId && 'bg-gray-200 text-black',
                          openTab !== tab.userId && 'bg-white text-black'
                        )}
                      >
                        {tab.nick}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
            {chats && chats.length > 0 && (
              <div className="flex flex-col bg-white/80 lg:bg-white/80  xl:bg-white/80">
                <div className="border">
                  <div className="p-2 flex gap-2 justify-end  border-b">
                    <ButtonCall />
                  </div>

                  {chats.map((tab) => {
                    return (
                      <div
                        className={classNames(
                          'block ',
                          openTab !== tab.userId && 'hidden'
                        )}
                        key={tab.userId}
                      >
                        <div className="p-2 flex flex-col-reverse gap-2 overflow-scroll scrollbar-thumb-gray-600 scrollbar-thin h-96">
                          {[...reduced].reverse().map((message, index) => {
                            return (
                              <TextMessage
                                key={index}
                                nick={message.nick}
                                text={message.text}
                              />
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <form
            className="inset-x-0 bottom-0 p-2 bg-[#e9eef0] flex flex-wrap items-center gap-2"
            onSubmit={onSubmit}
          >
            <input
              className="bg-white border-gray-800 p-2 grow rounded"
              placeholder={intl.formatMessage({ id: 'message' })}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              disabled={!socketConnected}
            />
            <button disabled={!socketConnected} className="disabled:opacity-50">
              {!socketConnected && <IconLoader className="w-6 h-6" />}
              {socketConnected && (
                <IconSend className="w-6 h-6 animate-in fade-in zoom-in" />
              )}
            </button>
          </form>
        </div>
      )}
    </>
  );
};
export default Chat;

const ButtonCall = () => {
  const { callStatus, setRemoteNick, setCallReceiverId, roster, selectedUser } =
    useRealtime();
  const { enableCam } = useUserCam();

  const callUser = (id) => {
    //find user nick
    let us = roster.users.find((element) => element.id === id);
    if (!us) return;
    setCallReceiverId(id);
    setRemoteNick(us.nick);
    enableCam();
  };
  return (
    <div
      className={classNames('cursor-pointer hover:shadow-xl hover:opacity-50')}
    >
      {callStatus === CALL_STATUS.PREPARING_CALL && (
        <IconActiveCall className="animate-bounce duration-500 text-yellow-400" />
      )}
      {callStatus === CALL_STATUS.ACTIVE_CALL && (
        <IconActiveCall className="text-success" />
      )}
      {!callStatus && (
        <IconPhone
          onClick={(e) => {
            if (callStatus) return;
            callUser(selectedUser);
          }}
        />
      )}
    </div>
  );
};
