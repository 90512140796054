import { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAuth } from './auth';

import {
  MESSAGE_CONTENT_PAYONER,
  MESSAGE_CONTENT_FORMATION,
  MESSAGE_CONTENT_BROADCAST,
  MESSAGE_CONTENT_MAILCHIMP,
  MESSAGE_CONTENT_OTHER,
  MESSAGE_CONTENT_NO_BROADCAST_DAYS,
  MESSAGE_CONTENT_BROADCASTED_HOURS,
  MESSAGE_CONTENT_NOTIFICATION_TO_ONE_PERFORMER,
  MESSAGE_CONTENT_NOTIFICATION_TO_ALL_PERFORMER,
  MESSAGE_CONTENT_PAXUM,
  MESSAGE_CONTENT_NOTIFICATION_TO_STUDIO,
  MESSAGE_CONTENT_BETWEEN_USERS,
} from '../shared/constants';

import MessagesService from '../shared/messages-service';

const MessagesContext = createContext({});

export const MessagesProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { newsRead } = useSelector((state) => state.messages);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUnread, setTotalUnread] = useState(0);
  const [filters, setFilters] = useState({ sent: -1 });

  useEffect(() => {
    setTotalUnread(
      messages.filter((message) => {
        return message.leida === 0 && !newsRead.includes(message.id);
      }).length
    );
  }, [messages, setTotalUnread, newsRead]);

  const getMessageType = (type) => {
    switch (type) {
      case 1:
        return MESSAGE_CONTENT_PAYONER;
      case 2:
        return MESSAGE_CONTENT_FORMATION;
      case 3:
        return MESSAGE_CONTENT_BROADCAST;
      case 4:
        return MESSAGE_CONTENT_MAILCHIMP;
      case 5:
        return MESSAGE_CONTENT_OTHER;
      case 6:
        return MESSAGE_CONTENT_NO_BROADCAST_DAYS;
      case 7:
        return MESSAGE_CONTENT_BROADCASTED_HOURS;
      case 8:
        return MESSAGE_CONTENT_NOTIFICATION_TO_ONE_PERFORMER;
      case 9:
        return MESSAGE_CONTENT_NOTIFICATION_TO_ALL_PERFORMER;
      case 10:
        return MESSAGE_CONTENT_PAXUM;
      case 11:
        return MESSAGE_CONTENT_NOTIFICATION_TO_STUDIO;
      default:
        return MESSAGE_CONTENT_BETWEEN_USERS;
    }
  };

  const getMessagesList = () => {
    setLoading(true);
    MessagesService.getMessages(page, itemsPerPage, filters, newsRead)
      .then((data) => {
        setTotalUnread(data.total_unread);
        setMessages(data.list);
        setTotal(data.total);
        setTotalPages(data.pagination.pages);
      })
      .catch((e) => setMessages([]))
      .finally(() => setLoading(false));
  };

  /* useEffect(() => {
    getMessagesList();
  }, [isAuthenticated]); */

  useEffect(() => {
    if (!isAuthenticated) return;
    getMessagesList();
  }, [isAuthenticated, filters, itemsPerPage, page]);

  return (
    <MessagesContext.Provider
      value={{
        loading,
        messages,
        filters,
        setFilters,
        setMessages,
        getMessageType,
        total,
        page,
        setPage,
        itemsPerPage,
        setItemsPerPage,
        totalPages,
        totalUnread,
        getMessagesList,
        newsRead,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessages = () => useContext(MessagesContext);
