import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useRealtime } from '../../contexts/realtime';
import { useAuth } from '../../contexts/auth';

import { SUPERADMIN_LEVEL } from '../../shared/constants';

import IconLoading from '../../public/assets/images/icons/loader.svg';
import { generateRandomString } from '../../helpers/utils';

const AvatarImage = ({
  width = 100,
  onAvatarClick = () => {},
  editable = false,
  showUSerStatus = false,
}) => {
  //Icon color based on connection status to realtime
  const { socketConnected, socketConnecting } = useRealtime();
  const { level, userAvatar, loadingAvatar, userName } = useAuth();

  const [hashToPreventCache, setHashToPreventCache] = useState(null);

  //realtime icon color, based on ws connection status
  const getColor = (socketConnected, socketConnecting) => {
    if (socketConnecting && level === SUPERADMIN_LEVEL)
      return 'border rounded-full border-yellow-600 animate-pulse';
    if (socketConnected && level === SUPERADMIN_LEVEL)
      return 'border-2 rounded-full border-green-500';
    if (level !== SUPERADMIN_LEVEL)
      return 'border rounded-full border-green-300';
    return 'border rounded-full border-red-500';
  };

  useEffect(() => {
    setHashToPreventCache(generateRandomString(4));
  }, []);

  return (
    <button
      onClick={() => {
        if (editable) onAvatarClick();
      }}
      className={twMerge(!editable && 'cursor-default')}
    >
      <span
        className={twMerge(
          'inline-block',
          showUSerStatus && getColor(socketConnected, socketConnecting)
        )}
        style={{ width, height: width }}
      >
        {!loadingAvatar && userAvatar && hashToPreventCache && (
          <img
            alt="Avatar"
            src={`${userAvatar}?` + hashToPreventCache}
            width={width}
            height={width}
            className={twMerge(
              'rounded-full bg-gray-400',
              showUSerStatus && getColor(socketConnected, socketConnecting)
            )}
          />
        )}

        {!userAvatar && (
          <span className="w-full h-full text-[150%] bg-white text-black rounded-full flex items-center justify-center uppercase ">
            {userName && userName[0]}
          </span>
        )}

        {loadingAvatar && (
          <span className="w-full h-full flex items-center justify-center border border-gray-400 rounded-full">
            <IconLoading className="w-2/3 h-2/3 text-gray-400" />
          </span>
        )}
      </span>
    </button>
  );
};

export default AvatarImage;
