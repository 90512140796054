import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useAuth } from '../contexts/auth';

import Logger from '../shared/logger';

import IconLoading from '../public/assets/images/icons/refresh.svg';

const logger = new Logger('ProtectedRoute');

const ProtectedRoute = ({ children }) => {
  const router = useRouter();
  const {
    isAuthenticated,
    isAuthLoading,
    twoFactorRequired,
    dataToken,
    token,
  } = useAuth();

  const shouldRedirect = useMemo(() => {
    //never redirect in restore password page
    if (router.asPath.includes('/login/restore')) return false;

    if (!isAuthLoading && !isAuthenticated && !router.asPath.includes('/login'))
      return '/login';
    if (
      !isAuthLoading &&
      twoFactorRequired &&
      !router.asPath.includes('/login/twofactor')
    )
      return 'twofactor';
    if (!isAuthLoading && isAuthenticated && router.asPath.includes('/login'))
      return '/';
    return false;
  }, [isAuthLoading, isAuthenticated, twoFactorRequired, router]);

  useEffect(() => {
    if (shouldRedirect) {
      if (shouldRedirect === 'twofactor') {
        router.push(
          {
            pathname: '/login/twofactor',
            query: {
              dt: dataToken,
              t: token,
            },
          },
          '/login/twofactor'
        );
      } else {
        router.push(shouldRedirect);
      }
    }
  }, [shouldRedirect, dataToken, token, router]);

  if (shouldRedirect || isAuthLoading)
    return (
      <div className="absolute inset-0 bg-gray-700 text-white flex items-center justify-center">
        <span className="animate-spin">
          <IconLoading className="text-white w-7 h-7" />
        </span>
      </div>
    );

  return children;
};

export default ProtectedRoute;
