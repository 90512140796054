import { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { useAuth } from '../../contexts/auth';
import useBigBrother from '../../hooks/useBigBrother';

import PhotosService from '../../shared/photos-service';

import CustomizeAvatar from './CustomizeAvatar';
import AvatarImage from './AvatarImage';
import Modal from '../Modal';
import Card from '../Card';
import Button from '../atoms/Button';

import Logger from '../../shared/logger';
const logger = new Logger('AvatarDetail');

const AvatarDetail = ({ onClose = () => {}, onAvatarChanged = () => {} }) => {
  const { formatMessage: t } = useIntl();
  const { log: logToBigBrother } = useBigBrother();
  const inputFileRef = useRef(null);
  const { getUserAvatar, loadingAvatar, setLoadingAvatar } = useAuth();

  const [fileSelected, setFileSelected] = useState(null);
  const [uploadingProgress, setUploadingProgress] = useState(null);

  const handleFileSelected = (e) => {
    e.preventDefault();
    setFileSelected(inputFileRef.current.files[0]);
  };

  const selectFilesToUpload = () => inputFileRef.current.click();

  const handleUploadProgress = (progressEvent) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    setUploadingProgress(percentCompleted);
  };

  const uploadAvatar = (avatarFile) => {
    setLoadingAvatar(true);
    PhotosService.uploadUserAvatar({
      avatar: avatarFile,
      onUploadProgress: handleUploadProgress,
    })
      .catch(() => {
        toast.error('Upload avatar: ' + t({ id: 'error_generic' }));
        getUserAvatar();
        //log to BB
        logToBigBrother({
          message: 'Error trying to upload avatar',
          event: 'PHOTOS',
          code: 500,
          data: {
            function: 'uploadAvatar',
            file: 'AvatarDetail.js',
            profile: { id: userId, nick: userName, level },
            error: e.message ?? e.data.error,
          },
        });
      })
      .then(() => {
        toast.success('Avatar modificado correctamente');
        setUploadingProgress(null);
        onAvatarChanged();
      })
      .finally(() => setLoadingAvatar(false));
  };

  return (
    <Modal>
      <Card closeModal={onClose} title="Avatar seleccionado">
        {!fileSelected && (
          <div className="flex flex-col items-center justify-center gap-2">
            <AvatarImage width={128} loading={uploadingProgress !== null} />
            <Button fullWidth onClick={selectFilesToUpload}>
              Cambiar avatar
            </Button>
          </div>
        )}

        {fileSelected && (
          <>
            <CustomizeAvatar
              image={URL.createObjectURL(fileSelected)}
              handleSelectedAvatar={(newAvatar) => {
                setFileSelected(null);

                //start upload
                uploadAvatar(newAvatar);
              }}
              onDismiss={() => setFileSelected(null)}
            />

            {uploadingProgress && (
              <div className="w-full bg-gray-300 rounded-full h-3 mt-2">
                <div
                  className="bg-green-600 h-full rounded-full animate-pulse"
                  style={{ width: `50%` }}
                />
              </div>
            )}
          </>
        )}

        <input
          type="file"
          ref={inputFileRef}
          onChange={handleFileSelected}
          accept="image/jpeg, image/png"
          className="hidden"
        />
      </Card>
    </Modal>
  );
};

export default AvatarDetail;
