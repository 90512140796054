import { useIntl } from 'react-intl';

import { useRealtime } from '../../../contexts/realtime';
import { useMonitor } from '../../../contexts/monitor';

import Tooltip from '../../Tooltip';

import IconClose from '../../../public/assets/images/icons/close.svg';

const ButtonCloseMonitor = () => {
  const { formatMessage: t } = useIntl();
  const { closeRoom } = useMonitor();
  const { setRemoteUserProperty } = useRealtime();

  return (
    <Tooltip content={t({ id: 'quit_monitorization' })}>
      <button
        onClick={(e) => {
          e.preventDefault();
          closeRoom();
          setRemoteUserProperty('currentRoom', null);
          setRemoteUserProperty('currentRoomNick', null);
          setRemoteUserProperty('monitoringTimestamp', 0);
        }}
      >
        <IconClose className="w-5 h-5 bg-gray-300 rounded-full p-1 text-black font-bold hover:bg-gray-800 hover:text-white" />
      </button>
    </Tooltip>
  );
};

export default ButtonCloseMonitor;
