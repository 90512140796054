import { useIntl } from 'react-intl';
import Link from 'next/link';

import Tooltip from '../../Tooltip';

const LinkAnonymousProfile = ({ nick = '' }) => {
  const { formatMessage: t } = useIntl();

  return (
    <Link
      href={`${process.env.NEXT_PUBLIC_VCE_PROFILE_URL}${nick}`}
      target="_blank"
    >
      <Tooltip content={t({ id: 'vce_profile' })}>
        <img src="/assets/images/icons/vce_logo.png" width={12} height={18} />
      </Tooltip>
    </Link>
  );
};

export default LinkAnonymousProfile;
