//https://lenguajejs.com/javascript/fechas/formatear-fechas-con-intl/

import { useRouter } from 'next/router';
import { APP_TIMEZONE } from '../shared/constants';

const useDateTime = () => {
  const { locale } = useRouter();
  const formatDate = (dateToFormat, format = 'Y-m-d') => {
    switch (format) {
      case 'm':
        return new Intl.DateTimeFormat('fr-CA', {
          timezone: APP_TIMEZONE,
          month: '2-digit',
        }).format(dateToFormat);
      case 'Y':
        return new Intl.DateTimeFormat('fr-CA', {
          timezone: APP_TIMEZONE,
          year: 'numeric',
        }).format(dateToFormat);
      case 'Y-m':
        return new Intl.DateTimeFormat('fr-CA', {
          timezone: APP_TIMEZONE,
          month: '2-digit',
          year: 'numeric',
        }).format(dateToFormat);
      case 'month-year':
        return new Intl.DateTimeFormat(locale, {
          timezone: APP_TIMEZONE,
          month: 'long',
          year: 'numeric',
        }).format(dateToFormat);
      case 'Y-m-d':
        return new Intl.DateTimeFormat('fr-CA', {
          timezone: APP_TIMEZONE,
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(dateToFormat);
      case 'd/m/Y':
        return new Intl.DateTimeFormat(locale, {
          timezone: APP_TIMEZONE,
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(dateToFormat);
      case 'hour':
        return new Intl.DateTimeFormat(locale, {
          timezone: APP_TIMEZONE,
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }).format(dateToFormat);
      default:
        return new Intl.DateTimeFormat(locale).format(dateToFormat);
    }
  };

  const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  };

  //Min date for the birthday datepicker
  const getDateFromYearsAgo = (yearsAgo) => {
    const today = new Date();
    const eighteenYearsAgo = today.setFullYear(today.getFullYear() - yearsAgo);

    return formatDate(eighteenYearsAgo);
  };

  const calculateAge = (dateOfBirth = '2000-01-01') => {
    const diff_ms = Date.now() - new Date(dateOfBirth).getTime();
    const age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  const getMaxDateRange = (fromDate, maxRangeDays = 31) => {
    let today = new Date();
    let rangeMax = new Date(
      fromDate.getFullYear(),
      fromDate.getMonth(),
      fromDate.getDate() + (maxRangeDays - 1)
    );

    if (rangeMax > today) {
      return today;
    }

    return rangeMax;
  };

  const getDateFromString = (stringDate, format = 'd/m/y') => {
    let timestamp = new Date(stringDate);
    let dateFormatted = '';

    switch (format) {
      case 'd/m/y h:i:s':
        dateFormatted =
          timestamp.getDate().toString().padStart(2, '0') +
          '/' +
          (timestamp.getMonth() + 1).toString().padStart(2, '0') +
          '/' +
          timestamp.getFullYear() +
          ' ' +
          timestamp.getHours().toString().padStart(2, '0') +
          ':' +
          timestamp.getMinutes().toString().padStart(2, '0') +
          ':' +
          timestamp.getSeconds().toString().padStart(2, '0') +
          'h';
        break;
      case 'd/m/y':
        dateFormatted =
          timestamp.getDate().toString().padStart(2, '0') +
          '/' +
          (timestamp.getMonth() + 1).toString().padStart(2, '0') +
          '/' +
          timestamp.getFullYear();
        break;
      default:
        dateFormatted = stringDate;
    }
    return dateFormatted;
  };

  const secondsToHumanReadable = (seconds) => {
    var numyears = Math.floor(seconds / 31536000);
    var numdays = Math.floor((seconds % 31536000) / 86400);
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

    let humanFormat = numyears > 0 ? numyears + ' años ' : '';
    humanFormat += numdays > 0 ? numdays + ' días ' : '';
    humanFormat += numhours > 0 ? numhours + ' horas ' : '';
    humanFormat += numminutes > 0 ? numminutes + ' min. ' : '';
    humanFormat += numseconds > 0 ? numseconds + ' seg.' : '';

    return humanFormat;
  };

  return {
    formatDate,
    getFirstDayOfMonth,
    getDateFromYearsAgo,
    calculateAge,
    getMaxDateRange,
    getDateFromString,
    secondsToHumanReadable,
  };
};

export default useDateTime;
