import { useAuth } from '../../../contexts/auth';
import { useMonitor } from '../../../contexts/monitor';
import { useIntl } from 'react-intl';

import { SUPERADMIN_LEVEL } from '../../../shared/constants';
import Logger from '../../../shared/logger';

import Tooltip from '../../Tooltip';

import IconMod from '../../../public/assets/images/icons/account-star.svg';
import IconExit from '../../../public/assets/images/icons/exit-run.svg';

const logger = new Logger('ButtonModerateRoom');

const ButtonModerateRoom = ({ performer = null }) => {
  const { formatMessage: t } = useIntl();
  const {
    paymentType,
    setPaymentType,
    closeRoom,
    setRoomData,
    setModelId,
    prepareRoom,
  } = useMonitor();
  const { level } = useAuth();

  const moderateRoom = (performer, paymentType) => {
    closeRoom();
    setPaymentType(paymentType);
    setRoomData(performer);
    setModelId(performer.id);
    (async () => {
      await prepareRoom({
        modelId: performer.id,
        instance: performer.instance,
        roomName: performer.roomName,
        manifest: performer.manifest,
        roomMode: performer.roomMode,
        paymentType: paymentType,
      });

      logger.log('prepareRoom on moderateRoomfinished');
    })();
  };

  if (!performer) return null;
  if (level !== SUPERADMIN_LEVEL) return null;

  return (
    <>
      {paymentType !== 25010 && (
        <Tooltip content={t({ id: 'enter_as_admin' })}>
          <IconMod
            onClick={() => {
              moderateRoom(performer, 25010);
            }}
            className="cursor-pointer"
          />
        </Tooltip>
      )}
      {paymentType !== 20010 && (
        <Tooltip content={t({ id: 'exit' })}>
          <IconExit
            onClick={() => {
              moderateRoom(performer, 20010);
            }}
            className="cursor-pointer"
          />
        </Tooltip>
      )}
    </>
  );
};

export default ButtonModerateRoom;
