import { createSlice } from '@reduxjs/toolkit';

import { setNewsRead } from '../../shared/cookies';

const initialState = {
  totalUnread: 0,
  newsRead: [],
};

export const messagesSlice = createSlice({
  name: 'messagesReducer',
  initialState,
  reducers: {
    setTotalUnread: (state, action) => {
      state.totalUnread = action.payload;
    },
    setNewsReadList: (state, action) => {
      state.newsRead = action.payload;
    },
    setNewsMessageAsRead: (state, action) => {
      if (
        !state.newsRead.some(
          (news) => parseInt(news) === parseInt(action.payload)
        )
      ) {
        state.newsRead.push(parseInt(action.payload));
        setNewsRead(state.newsRead); //set news read in cookie
      }
    },
  },
});

export const { setTotalUnread, setNewsMessageAsRead, setNewsReadList } =
  messagesSlice.actions;

export default messagesSlice.reducer;
