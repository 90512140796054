import SanitizeHTML from './SanitizeHTML';

import Modal from './Modal';
import Card from './Card';
import Button from './atoms/Button';

import IconExclamation from '../public/assets/images/icons/exclamation.svg';

const ModalAlert = ({
  title = '',
  description = '',
  labelCancel = 'Cancelar',
  labelSuccess = 'Aceptar',
  buttonSuccessColor = 'green',
  buttonCancelColor = 'red',
  handleSuccess = () => {},
  handleCancel = () => {},
}) => {
  return (
    <Modal>
      <Card title={title} Icon={IconExclamation} className="max-w-[450px]">
        <SanitizeHTML html={description} />
        <div className="flex gap-2 mt-3">
          <span className="grow">
            <Button
              rounded
              fullWidth={true}
              minWidth={false}
              color={buttonCancelColor}
              onClick={handleCancel}
              type="outline"
            >
              {labelCancel}
            </Button>
          </span>
          <span className="grow">
            <Button
              rounded
              minWidth={false}
              fullWidth={true}
              color={buttonSuccessColor}
              onClick={handleSuccess}
            >
              {labelSuccess}
            </Button>
          </span>
        </div>
      </Card>
    </Modal>
  );
};

export default ModalAlert;
