import fscreen from 'fscreen';

import { useEffect } from 'react';

import useKeyPress from '../../../hooks/useKeyPress';

import { useMonitor } from '../../../contexts/monitor';

//Icons
import IconFullScreenExit from '../../../public/assets/images/icons/fullscreen-exit.svg';
import IconFullScreen from '../../../public/assets/images/icons/full-screen.svg';

const ButtonFullScreen = ({ containerRef = null }) => {
  const { fullscreen, setFullscreen } = useMonitor();
  const escKeyPress = useKeyPress('Escape');

  useEffect(() => {
    if (!escKeyPress) return;
    if (!fscreen.fullscreenElement) return;
    fscreen.exitFullscreen();
  }, [escKeyPress]);

  useEffect(() => {
    if (!fscreen.fullscreenElement) {
      setFullscreen(false);
    } else {
      setFullscreen(true);
    }
  }, [fscreen.fullscreenElement]);

  useEffect(() => {
    const onChange = (event) =>
      setFullscreen(Boolean(fscreen.fullscreenElement));
    fscreen.addEventListener('fullscreenchange', onChange);
    return () => fscreen.removeEventListener('fullscreenchange', onChange);
  }, []);

  if (!containerRef) return null;

  return (
    <>
      {!fullscreen && fscreen.fullscreenEnabled && (
        <button
          onClick={() => {
            fscreen.requestFullscreen(containerRef.current);
            setFullscreen(true);
          }}
          className="m-2 bg-gray-800/40  rounded-lg text-white p-1"
        >
          <IconFullScreen className="w-5 h-5" />
        </button>
      )}

      {fullscreen && (
        <button
          onClick={() => {
            fscreen.exitFullscreen();
            setFullscreen(false);
          }}
          className="m-2 bg-gray-800/40  rounded-lg fill-white p-1"
        >
          <IconFullScreenExit className="w-5 h-5" />
        </button>
      )}
    </>
  );
};

export default ButtonFullScreen;
