import { createContext, useContext } from 'react';

import Logger from '../shared/logger';

import useReleaseChecker from '../hooks/useReleaseChecker';

const logger = new Logger('AppProvider');

const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const { isOldRelease, lastVersion } = useReleaseChecker();

  return (
    <AppContext.Provider value={{ isOldRelease, lastVersion }}>
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => useContext(AppContext);
