import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const CustomCheckBox = ({
  label,
  checked = false,
  onChange: _onChange = () => {},
  visible = true,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = () => {
    setIsChecked(!isChecked);
    _onChange(!isChecked);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  if (!visible) return;

  return (
    <>
      <button
        type="button"
        className="flex items-center gap-2 h-6"
        onClick={handleChange}
      >
        <div
          className={twMerge(
            'relative h-2 w-10 rounded-full',
            isChecked && 'bg-teal-500',
            !isChecked && 'bg-gray-400'
          )}
        >
          <span
            className={twMerge(
              'shadow absolute top-1/2 -translate-y-1/2 block h-5 w-5 rounded-full border',
              isChecked && 'right-0 bg-teal-400  border-teal-500',
              !isChecked && 'left-0 bg-gray-400  border-gray-500'
            )}
          />
        </div>
        <label className="text-gray-600 text-sm">{label}</label>
      </button>
    </>
  );
};
export default CustomCheckBox;
