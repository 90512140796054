import { useCallback } from 'react';
import { useStore } from 'react-redux';
import pjson from '../package.json';

import BigBrotherService from '../shared/bb-service';

const useBigBrother = () => {
  const store = useStore();

  const log = useCallback(
    ({
      // Big Brother parameters:
      message = 'Undefined message',
      event = 'undefined-event',
      code = 200,
      data = {},
      // Hook options:
      logCurrentState = false,
    }) => {
      const state = store.getState();
      if (logCurrentState) {
        data.currentState = state;
      }
      data.version = pjson.version;

      //only log to BB in production
      if (process.env.NODE_ENV === 'production')
        BigBrotherService.log(message, event, code, data).catch((e) => {
          console.error('BB log failed', e);
        });
    },
    [store]
  );

  return { log };
};

export default useBigBrother;
