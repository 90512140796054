import request from './api';

function getPerformersOnline() {
  return request({
    url: `/admin/performersonline`,
    method: 'GET',
  });
}

function getPerformers({
  studio = 0,
  manager = 0,
  room_type = 0,
  page = 0,
  limit = 25,
  sort = 'nick',
  order = 'asc',
  search = null,
  banned = 0,
  profile_pending_validation = null,
}) {
  const params = new URLSearchParams({
    page,
    page_items: limit,
    field_sort: sort,
    sort_order: order,
    banned,
  });

  if (studio > 0) params.set('study', studio);
  if (manager > 0) params.set('manager', manager);
  if (room_type > 0) params.set('room_type', room_type);
  if (search && search.length > 1) params.set('search', search);
  if (profile_pending_validation)
    params.set('profile_data_pending', profile_pending_validation);

  return request({
    url: `/performer/list?${params.toString()}`,
    method: 'GET',
  });
}

function getUsers({
  sortfield = 'nick',
  sortorder = 'asc',
  limit = 25, //items per page
  page = 1, //page
  studioid = 0,
  search = null,
  active = null,
}) {
  let url = `/users?paginationpage=${page}&paginationitems=${limit}&sortfield=${sortfield}&sortorder=${sortorder}`;

  if (studioid > 0) url += `&id_estudio=${studioid}`;
  if (search && search.length > 1) url += `&search=${search}`;
  if (active) url += `&active=${active}`;

  return request({
    url,
    method: 'GET',
  });
}

function getPendingValidate({
  sortField = 'fecha_alta',
  sortOrder = 'DESC',
  paginationItems = 25,
  paginationPage = 1,
  option = 'si',
  idwebcammer = null,
}) {
  const params = new URLSearchParams({
    'sort[field]': sortField,
    'sort[order]': sortOrder,
    'pagination[items]': paginationItems,
    'pagination[page]': paginationPage,
    option,
  });

  if (idwebcammer) params.append('idwebcammer', idwebcammer);

  return request({
    url: `admin/performer/notvalidated?${params.toString()}`,
    method: 'GET',
  });
}

function getThumbsPendingValidation({
  paginationItems = 25,
  paginationPage = 1,
}) {
  const params = new URLSearchParams({
    paginationitems: paginationItems,
    paginationpage: paginationPage,
  });

  return request({
    url: `admin/photos/notvalidated?${params.toString()}`,
    method: 'GET',
  });
}

function checkOnlineList() {
  return request({
    url: '/admin/checkperformersonline',
    method: 'GET',
  });
}

const ListsService = {
  getPendingValidate,
  getPerformersOnline,
  getPerformers,
  getUsers,
  getThumbsPendingValidation,
  checkOnlineList,
};

export default ListsService;
