import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ROOM_MODE, SPY_USERS_IDENTIFIERS } from '../../shared/constants';
import { useMonitor } from '../../contexts/monitor';

import { useRealtime } from '../../contexts/realtime';
import IconUsers from '../../public/assets/images/icons/account-multiple.svg';
import IconSpyUsers from '../../public/assets/images/icons/spy.svg';

const UserCounter = ({ performer }) => {
  const { roomUsers } = useMonitor();
  const { userCounter, rMode } = useRealtime();
  const { roomMode, paused, id } = performer;
  const [free, setFree] = useState(0);
  const [paying, setPaying] = useState(null);
  const [spyUsersCounter, setSpyUsersCounter] = useState(null);
  const [mode, setMode] = useState(roomMode);
  const getRealRoomMode = (roomMode, paused) => {
    if (roomMode === ROOM_MODE.FREE || roomMode === ROOM_MODE.FREECHAT) {
      return ROOM_MODE.FREE;
    } else if (roomMode === ROOM_MODE.PRIVATE && !paused) {
      return ROOM_MODE.PRIVATE;
    } else if (roomMode === ROOM_MODE.PRIVATE && paused) {
      return ROOM_MODE.PAUSED;
    } else {
      return roomMode;
    }
  };

  useEffect(() => {
    if (!userCounter) return;
    if (userCounter.id !== id) return;
    if (userCounter.key === 'freeusers') {
      setFree(userCounter.value > 0 ? userCounter.value : 0);
    } else {
      setPaying(userCounter.value > 0 ? userCounter.value : 0);
      setSpyUsersCounter(
        userCounter.sypusers && userCounter.sypusers > 0
          ? userCounter.sypusers
          : 0
      );
    }
  }, [userCounter, id]);

  useEffect(() => {
    if (!performer) return;
    setFree(parseInt(performer.freeusers) > 0 ? performer.freeusers : 0);
    setMode(performer.roomMode);
    setPaying(parseInt(performer.users) > 0 ? performer.users : 0);
  }, [performer]);

  useEffect(() => {
    if (!rMode) return;
    if (rMode.id !== id) return;
    if (rMode.key === 'roomMode') {
      setMode(rMode.value);
    } else {
      setMode(rMode.value);
    }
  }, [rMode, id]);

  return (
    <>
      {(getRealRoomMode(mode, paused) === ROOM_MODE.FREE ||
        getRealRoomMode(mode, paused) === ROOM_MODE.PRIVATE ||
        getRealRoomMode(mode, paused) === ROOM_MODE.PAUSED) && (
        <>
          <div
            className={classNames(
              'flex text-xl p-1',
              mode === ROOM_MODE.PRIVATE && 'text-orange-500',
              mode === ROOM_MODE.VIP && 'text-yellow-500',
              (mode === ROOM_MODE.FREE || mode === 'freechat') &&
                'text-green-500',
              paying === null && 'animate-pulse'
            )}
          >
            <IconUsers />
            <div className="text-sm px-1">
              {mode === ROOM_MODE.PRIVATE && (
                <span>{paying - spyUsersCounter}</span>
              )}
              {(mode === ROOM_MODE.FREE || mode === 'freechat') && (
                <span>{free}</span>
              )}
            </div>
          </div>

          {mode === ROOM_MODE.PRIVATE && (
            <div
              className={classNames(
                'flex text-xl p-1',
                mode === ROOM_MODE.PRIVATE && 'text-gray-900',
                mode === ROOM_MODE.VIP && 'text-yellow-500',
                (mode === ROOM_MODE.FREE || mode === 'freechat') &&
                  'text-green-500',
                spyUsersCounter === null && 'animate-pulse'
              )}
            >
              <IconSpyUsers />
              <div className="text-sm px-1">
                <span>{spyUsersCounter ?? '-'}</span>
              </div>
            </div>
          )}

          <IconSpyUsers />
          <div className="text-sm px-1">
            <span>{spyUsersCounter ?? '-'}</span>
          </div>
        </>
      )}
    </>
  );
};
export default UserCounter;
