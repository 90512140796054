import request from './api';

function checkToken(token) {
  return request({
    url: `/token/${token}`,
    method: 'GET',
  });
}

function login(user, pwd) {
  return request({
    url: `/login`,
    method: 'POST',
    data: {
      user,
      pwd,
    },
  });
}

function logout(token = null) {
  let url = '/logout';
  if (token) url += `?sessiontoclose=${token}`;

  return request({
    url,
    method: 'DELETE',
  });
}

function checkTwoFactorCode(code, token) {
  return request({
    url: `/login/twofactorcode`,
    method: 'POST',
    data: {
      code,
      tmpauthtoken: token,
    },
  });
}

function getUserDataFromToken() {
  return request({
    url: `/token/data/getuserdatafromtoken`,
    method: 'GET',
  });
}

function requestResetPassword(user) {
  return request({
    url: `/reset_pwd`,
    method: 'POST',
    data: {
      user,
    },
  });
}

function resetPasswordWithToken(token, newPassword) {
  let url = `/set_pwd?pwdtoken=${token}&new_password=${newPassword}`;
  return request({
    url,
    method: 'PUT',
    data: {},
  });
}

function getPerformerKey(performer_id, livelystaging = 0) {
  return request({
    url: `/performer/performerkey`,
    method: 'POST',
    params: {
      performer_id: performer_id,
      livelystaging,
      regenerate: 0,
    },
  });
}

function generateLivelyTokenForUpload(
  id,
  nick,
  livelypvtkey,
  livelystaging = 0
) {
  let options = [];
  options.msvcBaseUrl = 'https://media.olecams.com/v3';

  return request({
    url: `/performer/livelyauth`,
    method: 'POST',
    params: {
      id,
      nick,
      livelypvtkey,
      scopes: 'upload',
      livelystaging,
      options,
    },
  });
}

function updateCurrentUserPassword(oldPassword, newPassword) {
  return request({
    url: `/new_pwd?oldpassword=${oldPassword}&newpassword=${newPassword}`,
    method: 'PUT',
  });
}

function getUserSessions() {
  return request({
    url: `/token/user/list`,
    method: 'GET',
  });
}

function updateSessionName(token = null, name = null) {
  return request({
    url: `/token/user/update?name=${name}&tokentoupdate=${token}`,
    method: 'PUT',
  });
}

const AuthService = {
  checkToken,
  login,
  logout,
  checkTwoFactorCode,
  getUserDataFromToken,
  requestResetPassword,
  resetPasswordWithToken,
  generateLivelyTokenForUpload,
  getPerformerKey,
  updateCurrentUserPassword,
  getUserSessions,
  updateSessionName,
};

export default AuthService;
