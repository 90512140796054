export const APP_TIMEZONE = 'Europe/Madrid';

export const SUPERADMIN_LEVEL = 12;
export const ADMINISTRATOR_LEVEL = 1;
export const MANAGER_LEVEL = 2;

export const ROOM_STATUS_STANDBY = 'sb';
export const ROOM_STATUS_DISABLED = 'no';
export const ROOM_STATUS_ACTIVE = 'si';

export const HOME_GRID_MODES = {
  LIST: 'list',
  GRID: 'grid',
};

export const VIDEO_STATUS = {
  PENDING_VALIDATION: 0,
  TRANSCODING: 1,
  VALIDATED: 2,
  TRANSCODING_ERROR: 3,
};

export const THUMB_STATUS = {
  PENDING_VALIDATION: 0,
  VALIDATED: 1,
  DELETED: 2,
};

export const GALLERY_STATUS = {
  PENDING_VALIDATION: 0,
  VALIDATED: 1,
};

export const DRIVER_HLSJS = {
  id: 'hlsjs',
  options: { autoPlay: true },
};
export const DRIVER_NATIVE_HLS = { id: 'native-hls' };

export const MESSAGE_CONTENT_PAYONER = 'Payoner';
export const MESSAGE_CONTENT_FORMATION = 'Formación';
export const MESSAGE_CONTENT_BROADCAST = 'Emisión';
export const MESSAGE_CONTENT_MAILCHIMP = 'Mailchimp';
export const MESSAGE_CONTENT_OTHER = 'Otros';
export const MESSAGE_CONTENT_NO_BROADCAST_DAYS = 'Días sin emitir';
export const MESSAGE_CONTENT_BROADCASTED_HOURS = 'Horas emitidas';
export const MESSAGE_CONTENT_NOTIFICATION_TO_ONE_PERFORMER =
  'Notificación a modelo';
export const MESSAGE_CONTENT_NOTIFICATION_TO_ALL_PERFORMER =
  'Notificación a todas las modelos';
export const MESSAGE_CONTENT_PAXUM = 'Paxum';
export const MESSAGE_CONTENT_NOTIFICATION_TO_STUDIO =
  'Notificación por estudio';
export const MESSAGE_CONTENT_BETWEEN_USERS = 'Mensaje entre usuarios';
export const MAX_PHOTOS_MAIN_GALLERY = 25;
export const MIN_PHOTOS_PRIVATE_GALLERY = 8;
export const MIN_PHOTOS_FREE_GALLERY = 3;
export const ROOM_EVENTS = {
  CONNECTION_LOST: 'connection lost',
  FORBIDDEN: 'auth forbidden',
  USER_EXISTS: 'user exists',
  CALL_REQUEST: 'callRequest',
  CALL_REJECTED: 'callRejected',
  CALL_ACCEPTED: 'callAccepted',
  CALL_CANCELLED: 'callCancelled',
  TURN_CREDENTIALS: 'turnCredentials',
  WEBRTC_SIGNAL: 'wbertc_signal',
  ROSTER: 'roster',
  CHAT: 'chat',
  CHAT_ALERT: 'chatAlert',
  ROOM_UPDATE: 'roomUpdate',
  REPORT_RECEIVED: 'reportReceived',
};

export const MESSAGE_TYPES = {
  TEXT: 'text',
};

export const CALL_STATUS = {
  PREPARING_CALL: 'preparing call',
  ANSWERING_CALL: 'answering call',
  CALLING: 'calling',
  ESTABLISING: 'establishing',
  ACTIVE_CALL: 'active_call',
  CALL_ENDED: 'call ended',
};

export const ROOM_MODE = {
  FREECHAT: 'freechat',
  FREE: 'free',
  PRIVATE: 'private',
  VIP: 'vip',
  OFFLINE: 'offline',
  PAUSED: 'paused',
};

export const DOWNLOADS = {
  APP_LINKS: {
    APP_WIN: {
      LINK: 'https://operadoras.modelsonwebcam.com/contenidos/appOTA/MOWSetup4.0.0.zip',
      VERSION: '3.5.52',
      RELEASE_DATE: '10-05-2022',
    },
    APP_MAC: {
      LINK: 'https://operadoras.modelsonwebcam.com/contenidos/appOTA/MOW-4.0.0.dmg',
      VERSION: '3.5.52',
      RELEASE_DATE: '10-05-2022',
    },
  },
  DOCUMENT_LINK: {
    LINK: 'https://api.modelsonwebcam.com/downloads/mow_broadcast_requirements.pdf',
    RELEASE_DATE: '04-05-2020',
  },
};

export const BROWSER_NOTIFICATIONS_TYPES = {
  FORBIDDEN_WORD: 'forbiddenWord',
  REPORT_RECEIVED: 'reportReceived',
};

export const USERS_TO_REPORT = [876, 258, 424, 564, 587, 405, 629, 876, 1623];
export const AUTHORIZED_USERS_SPECIAL_ACTIONS = [2, 547, 876]; //leo, marco, jose
export const USER_WARNING_SECONDS_IN_ROOM = 300; //5 minutes

export const VIDEOS_WITH_DEFAULT_THUMB_DAYS_AGO = 30;

export const PERFORMERS_PANEL_URL = 'https://emisor.modelsonwebcam.com';

export const GRID_MODES = {
  ORIGINAL: 'ORIGINAL',
  REGISTERED_USERS: 'REGISTERED_USERS',
  ANONYMOUS_USERS: 'ANONYMOUS_USERS',
};

export const REMARKS_TYPES = {
  OTHERS: 'O',
  VIDEOS: 'V',
  IMAGES: 'I',
  NOTICES: 'N',
};

export const ALLOWED_URLS = {
  MANAGER: [
    '/messages',
    '/messages/thread/[id]',
    '/messages/new',
    '/performers',
    '/performers/add',
    '/performers/[performerid]',
    '/performers/validation',
    '/performers/standby',
    '/stats/studiosStats',
    '/stats/performersStats',
    '/stats/broadcastedStudios',
    '/support',
    '/settings',
    '/downloads',
  ],
  ADMINISTRATOR: [
    '/messages',
    '/messages/thread/[id]',
    '/messages/new',
    '/performers',
    '/performers/add',
    '/performers/[performerid]',
    '/performers/validation',
    '/performers/standby',
    '/stats/studiosStats',
    '/stats/performersStats',
    '/stats/broadcastedStudios',
    '/users',
    '/users/[userid]',
    '/users/add',
    '/settings',
    '/support',
    '/downloads',
  ],
};

export const STATS_RANGE_TYPES = {
  MONTH: 'month',
  DAY: 'day',
  RANGE: 'custom',
};

//PRICES: CREDITS PER MINUTE
export const REGULAR_PRICES_USERS_IDENTIFIERS = [500, 401, 299];
export const PRICES_CREDITS_PER_MIN_GROUP = [16, 18, 20, 24, 26, 28, 30];
export const PRICES_DEFAULT_CREDITS_PER_MIN_GROUP = 24;
export const PRICES_MIN_CREDITS_PER_MIN_GROUP = 16;
export const PRICES_MAX_CREDITS_PER_MIN_GROUP = 30;

export const PRICES_CREDITS_PER_MIN_VIP = [36, 38, 40, 42, 44, 46, 48];
export const PRICES_DEFAULT_CREDITS_PER_MIN_VIP = 48;
export const PRICES_MIN_CREDITS_PER_MIN_VIP = 36;
export const PRICES_MAX_CREDITS_PER_MIN_VIP = 48;

export const PRICES_DEFAULT_CREDITS_PER_MIN_SPY = 12;
export const PRICES_DEFAULT_CREDITS_PER_MIN_AUDIO = 12;

export const PRICES_RANGES_FILTER = [
  {
    value: '1620',
    label: '16-20',
    min: 16,
    max: 20,
  },
  {
    value: '2426',
    label: '24-26',
    min: 24,
    max: 26,
  },
  {
    value: '2830',
    label: '28-30',
    min: 28,
    max: 30,
  },
];

export const SITES_LIST_TYPES = {
  ANONYMOUS: 'ANONYMOUS',
  BROKER: 'BROKER',
  REGISTERED: 'REGISTERED',
  ALL: 'ALL_SITES',
};

export const NEWS_CONFIG = {
  TITLE_MAX_LENGTH: 30,
  SUBTITLE_MAX_LENGTH: 70,
};
export const SPY_USERS_IDENTIFIERS = [701, 702, 751];

export const END_USERS_REPORTS_STATUS = {
  OPENED: 'opened',
  MANAGED: 'managed',
  CLOSED: 'closed',
};
