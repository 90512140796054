import { useIntl } from 'react-intl';
import html2canvas from 'html2canvas';
import { SUPERADMIN_LEVEL } from '../shared/constants';

import IconWindows from '../public/assets/images/icons/windows.svg';
import IconAndroid from '../public/assets/images/icons/android.svg';
import IconApple from '../public/assets/images/icons/apple.svg';

export const SINGLE_ROOMS_ID = [1, 2, 3, 6, 7];

export const decodeJWT = (token) => {
  try {
    return JSON.parse(window.atob(token.split('.')[1])).data;
  } catch (e) {
    return {};
  }
};

export const userIsAllowed = (userLevel, usersLevelsGranted) => {
  if (userLevel === SUPERADMIN_LEVEL) return true;

  if (usersLevelsGranted.includes(userLevel)) {
    return true;
  } else {
    return false;
  }
};

export const getRoomTypeNameById = (roomTypeId) => {
  const { formatMessage: t } = useIntl();
  switch (parseInt(roomTypeId)) {
    case 1:
      return t({ id: 'girls' });
    case 2:
      return t({ id: 'gay' });
    case 3:
      return t({ id: 'trans' });
    case 4:
      return t({ id: 'couples' });
    case 6:
      return t({ id: 'pornstars' });
    case 7:
      return t({ id: 'rookies' });
    case 8:
      return t({ id: 'trans_couple' });
    case 9:
      return t({ id: 'boy_couple' });
    default:
      return 'unknown';
  }
};

export const isValidDNIFileSize = (fileToCheck) => {
  if (!fileToCheck) return false;

  const MAX_FILE_SIZE = 5 * 1024 * 1024; //5MB

  const fileSize = fileToCheck.size ?? 99999999;
  if (fileSize > MAX_FILE_SIZE) return false;
  return true;
};

export const sortList = (
  data,
  fieldToSort,
  type = 'number',
  direction = 'DESC'
) => {
  let d = Object.values(data);
  if (direction === 'ASC') {
    if (type == 'number') {
      d.sort((a, b) =>
        parseFloat(a[fieldToSort]) > parseFloat(b[fieldToSort]) ? 1 : -1
      );
    } else {
      d.sort((a, b) =>
        a[fieldToSort].toLowerCase() > b[fieldToSort].toLowerCase() ? 1 : -1
      );
    }
  } else {
    if (type == 'number') {
      d.sort((a, b) =>
        parseFloat(a[fieldToSort]) < parseFloat(b[fieldToSort]) ? 1 : -1
      );
    } else {
      d.sort((a, b) =>
        a[fieldToSort].toLowerCase() < b[fieldToSort].toLowerCase() ? 1 : -1
      );
    }
  }
  return d;
};

export const isEmailValid = (email) => {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email.match(mailformat)) return true;

  return false;
};

export const generateRandomString = (length = 8) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789.*-$%&';
  let generated = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    generated += characters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }

  return generated;
};

export const exportAsImage = async (element) => {
  const canvas = await html2canvas(element);
  const image = canvas.toDataURL('image/png', 1.0);
  return image;
};

export const isObjectEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
};

export const getOs = (os) => {
  const Icon = getOsIcon(os);
  if (!Icon) return null;
  if (os.toLowerCase().includes('win'))
    return <Icon className=" h-5 w-5 text-blue-600" />;
  if (os.toLowerCase().includes('mac')) return <Icon className=" h-5 w-5" />;
  if (os.toLowerCase().includes('android'))
    return <Icon className=" h-6 w-6 text-green-600" />;
  return <Icon />;
};

export const getOsIcon = (os) => {
  if (typeof os === 'undefined') return null;
  if (os.toLowerCase().includes('win')) return IconWindows;
  if (os.toLowerCase().includes('mac')) return IconApple;
  if (os.toLowerCase().includes('android')) return IconAndroid;
  return IconApple;
};

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    if (immediate && !timeout) func.apply(context, args);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
  };
};
