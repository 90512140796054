import request from './api';

function getUsers({
  sortfield = 'id',
  sortorder = 'ASC',
  paginationitems = 20,
  paginationpage = 1,
  studio = 0,
  level = 0,
  searchstr = '',
  login = '',
  email = '',
}) {
  const params = new URLSearchParams({
    sortfield,
    sortorder,
    paginationitems,
    paginationpage,
  });

  if (studio > 0) params.set('id_estudio', studio);
  if (level > 0) params.set('nivel', level);
  if (searchstr && searchstr.length > 1) params.set('searchstr', searchstr);
  if (login && login.length > 1) params.set('login', login);
  if (email && email.length > 1) params.set('email', email);

  return request({
    url: `/users?${params.toString()}`,
    method: 'GET',
  });
}

function getUser(id) {
  return request({
    url: `/user?id=${id}`,
    method: 'GET',
  });
}

function addUser(newUserData) {
  return request({
    url: '/users',
    data: newUserData,
    method: 'POST',
  });
}

function updateUser(userData) {
  return request({
    url: '/user',
    data: userData,
    method: 'PUT',
  });
}

function changeUserState({ id, active }) {
  return request({
    url: '/user/changestate',
    data: {
      id,
      active,
    },
    method: 'PUT',
  });
}

function checkNickExists(nick) {
  return request({
    url: `/admin/checknick?nick=${nick}`,
    method: 'GET',
  });
}

function checkEmailExists(email) {
  return request({
    url: `/admin/checkemail?email=${email}`,
    method: 'GET',
  });
}

const UsersService = {
  getUsers,
  addUser,
  getUser,
  updateUser,
  changeUserState,
  checkNickExists,
  checkEmailExists,
};

export default UsersService;
