import { useState } from 'react';
import { useIntl } from 'react-intl';

import { useMonitor } from '../../../contexts/monitor';

import IconLoader from '../../../public/assets/images/icons/loader.svg';
import IconSend from '../../../public/assets/images/icons/send.svg';
import { useAuth } from '../../../contexts/auth';
import { SUPERADMIN_LEVEL } from '../../../shared/constants';

const ChatInputMessage = () => {
  const { formatMessage: t } = useIntl();
  const { level } = useAuth();
  const { paymentType, socketConnected, sendMessage } = useMonitor();
  const [message, setMessage] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    sendMessage(message);
    setMessage('');
  };

  if (level !== SUPERADMIN_LEVEL) return null;
  if (paymentType !== 25010) return null;

  return (
    <div className="col-start-4 col-end-6">
      <form
        className="inset-x-0 bottom-0 bg-[#e9eef0] flex flex-wrap items-center gap-2"
        onSubmit={onSubmit}
      >
        <input
          className="bg-white border-gray-800 p-2 grow rounded"
          placeholder={t({ id: 'message' })}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disabled={!socketConnected}
        />
        <button disabled={!socketConnected} className="disabled:opacity-50">
          {!socketConnected && <IconLoader className="w-6 h-6" />}
          {socketConnected && (
            <IconSend className="w-6 h-6 animate-in fade-in zoom-in" />
          )}
        </button>
      </form>
    </div>
  );
};

export default ChatInputMessage;
