import { createContext, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { BROWSER_NOTIFICATIONS_TYPES } from '../shared/constants';
import Logger from '../shared/logger';

import useSounds from '../hooks/useSounds';

const logger = new Logger('NotificationsContext');
const NotificationsContext = createContext({});

export const NotificationsProvider = ({ children }) => {
  const { playWarning } = useSounds();
  const [allowed, setAllowed] = useState(false);
  const router = useRouter();
  const { formatMessage } = useIntl();
  const [roomNotified, setRoomNotified] = useState(null);

  useEffect(() => {
    if ('Notification' in window) {
      Notification.requestPermission().then((result) => {
        if (result === 'denied') {
          logger.warn('Notifications disabled');
          //ToDo shall we warn user???
          setAllowed(false);
        } else if (result === 'granted') {
          logger.log('Notifications enabled');
          setAllowed(true);
        }
      });
    }
  }, []);
  const browserNotification = (content) => {
    logger.log('browserNotification received', { allowed, content });
    if (!allowed) return; //shall we alert user anyway???
    let body;
    switch (content.type) {
      case BROWSER_NOTIFICATIONS_TYPES.FORBIDDEN_WORD:
        playWarning();
        body = `${content.forbiddenText}`;
        display({
          title: formatMessage(
            { id: 'using_forbidden_words' },
            { nick: content.nick }
          ),
          body,
          onClick: () => setRoomNotified(content.id),
        });
        break;
      case BROWSER_NOTIFICATIONS_TYPES.REPORT_RECEIVED:
        playWarning();

        body = formatMessage(
          { id: 'report_received' },
          {
            nick: content.nick,
            subject: content.subject,
          }
        );

        display({
          title: formatMessage(
            { id: 'report_received_title' },
            { nick: content.nick }
          ),
          body,
          onClick: () => setRoomNotified(content.performer_id),
        });
        break;
      // no-default
    }
  };
  const display = ({ title, body, onClick = () => {} }) => {
    const img = 'assets/images/logo_sm.svg';
    try {
      const notification = new Notification(title, {
        body: body,
        icon: img,
        badge: img,
      });

      notification.onclick = () => {
        onClick();
      };
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <NotificationsContext.Provider
      value={{ browserNotification, roomNotified, setRoomNotified, allowed }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationsContext);
