import classNames from 'classnames';

const Select = ({
  labelInline = true,
  label = null,
  onChange = () => {},
  value = '',
  fullWidth = true,
  children,
  className,
  invalidData = false,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'flex gap-1',
        labelInline && 'flex-row items-center grow',
        !labelInline && 'flex-col'
      )}
    >
      {label && (
        <label className="block text-gray-600 text-sm first-letter:uppercase">
          {label}
        </label>
      )}
      <select
        value={value}
        onChange={onChange}
        className={classNames(
          'text-sm border border-gray-300 bg-white py-[6px] px-4 pr-9 truncate',
          "bg-[url('/assets/images/icons/arrow_down_select.svg')] bg-[calc(100%-12px)_center] bg-no-repeat bg-[length:12px_12px] appearance-none",
          'focus:border-[#92dcdc] focus:ring-[rgba(37,185,185,25%)] focus:ring-4 focus:ring-opacity-50 focus:outline-none',
          fullWidth && 'w-full',
          invalidData && 'bg-red-100 border border-red-500',
          className
        )}
        {...props}
      >
        {children}
      </select>
    </div>
  );
};

export default Select;
