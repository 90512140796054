import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

import AuthService from '../shared/auth-service';
import {
  unsetCookieToken,
  getCookieToken,
  setCookieToken,
  NAMES as COOKIE_NAMES,
} from '../shared/cookies';

import { setAuthInClient } from '../shared/api';
import Logger from '../shared/logger';

import useInterval from '../hooks/useInterval';
import PhotosService from '../shared/photos-service';

import { isObjectEqual } from '../helpers/utils';

const logger = new Logger('AuthProvider');

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const checkTokenInterval = 60 * 1000; //every minute when messages section
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [dataToken, setDataToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [idStudio, setIdStudio] = useState(null);
  const [studioName, setStudioName] = useState(null);
  const [userName, setUserName] = useState(null);
  const [level, setLevel] = useState(-1);
  const [monitorAllowed, setMonitorAllowed] = useState(false);
  const [twoFactorRequired, setTwoFactorRequired] = useState(false);
  const [pwdAboutToExpire, setPwdAboutToExpire] = useState(false);
  const [pwdAlertShowed, setPwdAlertShowed] = useState(false); //modal alert password is about to expire
  const [userAvatar, setUserAvatar] = useState(null);
  const [loadingAvatar, setLoadingAvatar] = useState(true);
  const [prevUserData, setPrevUserData] = useState({});

  const logout = useCallback(() => {
    setUserId(null);
    setUserName(null);
    setLevel(null);
    setStudioName(null);
    setIdStudio(null);
    setToken(null);
    setIsAuthenticated(false);
    setTwoFactorRequired(false);
    setDataToken(null);
    unsetCookieToken();
    setIsAuthLoading(false);
    setPwdAboutToExpire(false);
    setPwdAlertShowed(false);
    setMonitorAllowed(false);
    unsetCookieToken();

    AuthService.logout();
  }, []);

  const setUserData = useCallback(
    (userData) => {
      if (isObjectEqual(userData, prevUserData)) return false;
      //if userdata change
      setUserId(userData.id);
      setUserName(userData.username);
      setLevel(userData.level);
      setStudioName(userData.studio);
      setIdStudio(userData.id_studio);
      setPwdAboutToExpire(userData.pwdAboutToExpire);
      setMonitorAllowed(userData.monitor);
      getUserAvatar();
      setPrevUserData(userData);
    },
    [prevUserData]
  );

  const checkToken = () => {
    // check token in API
    AuthService.checkToken(token)
      .then((tokenIsValid) => {
        if (tokenIsValid) {
          //token is valid:

          AuthService.getUserDataFromToken()
            .then((userData) => {
              setUserData(userData);
              setIsAuthenticated(true);
            })
            .catch((e) => {
              logger.log(
                'No se pudieron obtener los datos de usuario desde API',
                { e }
              );
              unsetCookieToken();
            })
            .finally(() => {
              setIsAuthLoading(false);
            });
        } else {
          unsetCookieToken();
        }
      })
      .catch(() => unsetCookieToken());
  };

  const getUserAvatar = () => {
    setLoadingAvatar(true);
    PhotosService.getUserAvatar()
      .then((avatarURL) => setUserAvatar(avatarURL))
      .catch((e) => {
        logger.error('Ha ocurrido un error al intentar recuperar el avatar', e);
      })
      .finally(() => setLoadingAvatar(false));
  };

  useEffect(() => {
    if (token === null) return;
    setCookieToken(token);
    setAuthInClient(token);
  }, [token, setAuthInClient]);

  useEffect(() => {
    let _token = getCookieToken();
    if (!_token) {
      setIsAuthLoading(false);
      return;
    }
    setToken(_token);
  }, [setToken, getCookieToken, setIsAuthLoading]);

  useEffect(() => {
    // Si tienes cookie:
    if (!token || twoFactorRequired) {
      setIsAuthenticated(false);
      return;
    }
    checkToken();
  }, [token, isAuthenticated, twoFactorRequired, logout]);

  useEffect(() => {
    const onCookieChange = (event) => {
      for (const { name } of event.deleted) {
        if (name === COOKIE_NAMES.TOKEN) {
          logger.log(`${name} deleted, resetting auth state...`);
          setToken(null);
        }
      }
    };
    window.cookieStore?.addEventListener('change', onCookieChange);
    return () => {
      window.cookieStore?.removeEventListener('change', onCookieChange);
    };
  }, []);

  useInterval(() => checkToken(), isAuthenticated ? checkTokenInterval : null);

  return (
    <AuthContext.Provider
      value={{
        isAuthLoading,
        isAuthenticated,
        twoFactorRequired,
        token,
        dataToken,
        userName,
        userId,
        level,
        idStudio,
        studioName,
        monitorAllowed,
        setToken,
        setIsAuthenticated,
        setTwoFactorRequired,
        setIsAuthLoading,
        setDataToken,
        logout,
        pwdAboutToExpire,
        pwdAlertShowed,
        setPwdAlertShowed,
        checkToken,
        getUserAvatar,
        userAvatar,
        setUserAvatar,
        loadingAvatar,
        setLoadingAvatar,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
