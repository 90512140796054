import { twMerge } from 'tailwind-merge';

const SubtitleForms = ({ children, className }) => {
  return (
    <h3
      className={twMerge(
        'text-lg py-1 border-b text-gray-600 border-b-gray-700 mb-2',
        className
      )}
    >
      {children}
    </h3>
  );
};

export default SubtitleForms;
