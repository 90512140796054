import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { useMonitor } from '../../contexts/monitor';
import { useAuth } from '../../contexts/auth';

import UsersService from '../../shared/users-service';
import { USERS_TO_REPORT } from '../../shared/constants';
import {
  SUPERADMIN_LEVEL,
  ADMINISTRATOR_LEVEL,
  MANAGER_LEVEL,
} from '../../shared/constants';

import Modal from '../Modal';
import Card from '../Card';
import SubtitleForms from '../SubtitleForms';
import Select from '../atoms/Select';
import Input from '../atoms/Input';
import CustomCheckBox from '../atoms/CustomCheckBox';
import Button from '../atoms/Button';
import SuperviseService from '../../shared/supervise-service';

const PanelReport = () => {
  const { formatMessage: t } = useIntl();
  const { level } = useAuth();
  const {
    roomData: monitorRoomData,
    roomUsers,
    roomMessages,
    screenShot,
    setScreenShot,
  } = useMonitor();

  const defaultReportData = {
    assignTo: -2, //nobody
    user_to_notify: [],
    user_to_notify_nick: 'Nadie',
    manager_notification: 0,
    webcammer_notification: 0,
    support_notification: 0,
    report_txt: '',
    users_in_room: [],
    chat: [],
    room_data: monitorRoomData,
  };

  const [addingReport, setAddingReport] = useState(false);
  const [reportableUsers, setReportableUsers] = useState([]);
  const [reportData, setReportData] = useState(defaultReportData);

  const getReportableUsers = () => {
    UsersService.getUsers({ level: 12, paginationitems: 100 })
      .then((usersList) =>
        setReportableUsers(
          usersList.list.filter((us) => USERS_TO_REPORT.includes(us.id))
        )
      )
      .catch((e) => toast.error(e.data.message));
  };

  const setDefaultReportData = () => {
    let usersNick = [];
    if (roomUsers)
      roomUsers.forEach((user) => {
        usersNick.push(user.nick);
      });

    let chat = [];
    if (roomMessages)
      roomMessages.forEach((line) => {
        if (line.messageType === 'text') {
          let msg = `${line.nick}: ${line.text}`;
          chat.push(msg);
        }
      });

    let newReportData = {
      ...defaultReportData,
      users_in_room: usersNick,
      chat,
      room_data: monitorRoomData,
      support_notification: level !== SUPERADMIN_LEVEL ? 1 : 0, //managers and superadmins always notify to support
    };

    setReportData({
      ...newReportData,
    });
  };

  const sendReport = () => {
    let paramsReport = { ...reportData, thumb: screenShot };

    if (paramsReport.report_txt.trim().length < 5) {
      toast.warning(t({ id: 'report_empty' }));
      return;
    }

    const toastId = toast.loading(t({ id: 'report_processing' }));
    setAddingReport(true);
    SuperviseService.addReport(paramsReport)
      .then(() => {
        toast.update(toastId, {
          render: t({ id: 'report_created_succesfully' }),
          type: 'success',
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        });
        setScreenShot(null);
      })
      .catch((e) =>
        toast.update(toastId, {
          render: e.data.error,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        })
      )
      .finally(() => setAddingReport(false));
  };

  useEffect(() => {
    getReportableUsers();
  }, []);

  useEffect(() => {
    setDefaultReportData();
  }, [screenShot, monitorRoomData]);

  if (!screenShot) return;

  return (
    <Modal>
      <Card
        title={t({ id: 'report_to' }, { nick: monitorRoomData.nick })}
        closeModal={() => setScreenShot(null)}
        className="max-h-[90%] mx-w-[90%] overflow-y-scroll"
      >
        <div className="flex flex-col gap-1">
          <div>
            <SubtitleForms>Screenshot</SubtitleForms>
            <img src={screenShot} width="420" className="mx-auto" />
          </div>

          <div className="flex flex-col gap-3">
            <SubtitleForms>{t({ id: 'report_information' })}</SubtitleForms>

            {level === SUPERADMIN_LEVEL && (
              <Select
                label={'Asignar reporte a:'}
                labelInline={false}
                value={reportData.assignTo}
                disabled={addingReport}
                onChange={(e) => {
                  //all
                  if (Number(e.target.value) === -1)
                    setReportData({
                      ...reportData,
                      user_to_notify: USERS_TO_REPORT,
                      user_to_notify_nick: 'Todos',
                      assignTo: e.target.value,
                    });

                  //anybody
                  if (Number(e.target.value) === -2)
                    setReportData({
                      ...reportData,
                      user_to_notify: {},
                      user_to_notify_nick: 'Nadie',
                      assignTo: e.target.value,
                    });

                  //selected user
                  if (Number(e.target.value) > 0)
                    setReportData({
                      ...reportData,
                      user_to_notify: [e.target.value],
                      user_to_notify_nick:
                        e.target.options[e.target.selectedIndex].text,
                      assignTo: e.target.value,
                    });
                }}
                className="capitalize"
              >
                <option value="-1">{t({ id: 'all' })}</option>
                <option value="-2">{t({ id: 'nobody' })}</option>
                {reportableUsers.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.nick}
                    </option>
                  );
                })}
              </Select>
            )}

            <div className="flex flex-col gap-1">
              <span className="text-sm border-b">
                {t({ id: 'notify_too' })}:
              </span>
              <div className="flex flex-col gap-1">
                {level !== MANAGER_LEVEL && (
                  <CustomCheckBox
                    label={'Manager'}
                    checked={reportData.manager_notification}
                    onChange={(checked) =>
                      setReportData({
                        ...reportData,
                        manager_notification: checked
                          ? monitorRoomData.manager_id
                          : 0,
                      })
                    }
                    disabled={addingReport}
                  />
                )}
                <CustomCheckBox
                  label={'Modelo'}
                  checked={reportData.webcammer_notification}
                  onChange={(checked) => {
                    setReportData({
                      ...reportData,
                      webcammer_notification: checked ? monitorRoomData.id : 0,
                    });
                  }}
                  disabled={addingReport}
                />

                {level === SUPERADMIN_LEVEL && (
                  <CustomCheckBox
                    label={'Departamento de soporte'}
                    checked={
                      reportData.support_notification === 0 ? false : true
                    }
                    onChange={(checked) =>
                      setReportData({
                        ...reportData,
                        support_notification: checked ? 1 : 0,
                      })
                    }
                    disabled={addingReport}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col">
              <Input.TextArea
                label={'Detalles'}
                value={reportData.report_txt}
                onChange={(e) =>
                  setReportData({ ...reportData, report_txt: e.target.value })
                }
                disabled={addingReport}
              />
              <span className="text-xs italic">
                *Proporcione toda la Información que considere necesaria
              </span>
            </div>

            <Button
              disabled={addingReport}
              onClick={(e) => {
                e.preventDefault();
                sendReport();
              }}
            >
              Enviar reporte
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  );
};

export default PanelReport;
