import { Provider } from 'react-redux';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { ToastContainer } from 'react-toastify';
import Draggable from 'react-draggable';
import store from '../store/store';
import { IntlProvider } from 'react-intl';

import { MessagesProvider } from '../contexts/messages';
import { AuthProvider } from '../contexts/auth';
import { RealTimeProvider } from '../contexts/realtime';
import { UserCamProvider } from '../contexts/userCam';
import { MonitorProvider } from '../contexts/monitor';

import ProtectedRoute from '../components/ProtectedRoute';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SiteContent from '../components/SiteContent';
import DebugModal from '../components/DebugModal';
import DebugBar from '../components/DebugBar';
import BackgroundUpdater from '../components/BackgroundUpdater';
import ProtectAppRoute from '../components/ProtectAppRoute';
import Chat from '../components/RealTime/Chat';
import Call from '../components/RealTime/Call';
import CallRequest from '../components/RealTime/CallRequest';
import Monitor from '../components/Monitor/Monitor';

import en from '../locales/en.json';
import es from '../locales/es.json';

const messages = { en, es };

import '../styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import { NotificationsProvider } from '../contexts/notifications';
import { AppProvider } from '../contexts/global';
import Maintenance from '../components/Maintenance';
import { RoomsOnlineProvider } from '../contexts/roomsOnlineList';
import BrowserNotificationsStatus from '../components/BrowserNotificacionsStatus';

function MyApp({ Component, pageProps }) {
  const { locale } = useRouter();
  const router = useRouter();

  return (
    <>
      <Head>
        <title>Models on Webcam</title>
        <link rel="shortcut icon" href="/favicon.ico" />
      </Head>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <Provider store={store}>
          <Maintenance>
            <AppProvider>
              <AuthProvider>
                <NotificationsProvider>
                  <ProtectedRoute>
                    <MessagesProvider>
                      <BackgroundUpdater />
                      <RealTimeProvider>
                        <MonitorProvider>
                          <RoomsOnlineProvider>
                            <ProtectAppRoute>
                              <UserCamProvider>
                                <Header />
                                <BrowserNotificationsStatus />
                                <SiteContent>
                                  <Component {...pageProps} />
                                </SiteContent>
                                <DebugModal />
                                <Draggable>
                                  <div className="absolute top-2 left-1/2 w-2/4">
                                    <Chat />
                                  </div>
                                </Draggable>
                                <Draggable>
                                  <div className="absolute top-2 left-1/3 w-[320px]">
                                    <Call />
                                  </div>
                                </Draggable>
                                <div className="absolute top-1/2 left-1/2 w-96">
                                  <CallRequest />
                                </div>
                                <DebugBar />
                                <Footer />
                              </UserCamProvider>
                            </ProtectAppRoute>
                          </RoomsOnlineProvider>
                          {/* pages/index already has monitor */}
                          {router.asPath !== '/' && <Monitor />}
                        </MonitorProvider>
                      </RealTimeProvider>
                      <ToastContainer hideProgressBar={true} />
                    </MessagesProvider>
                  </ProtectedRoute>
                </NotificationsProvider>
              </AuthProvider>
            </AppProvider>
          </Maintenance>
        </Provider>
      </IntlProvider>
    </>
  );
}

export default MyApp;
