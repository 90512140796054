import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { toast } from 'react-toastify';

import request from '../shared/api';

import pjson from '../package.json';

const useReleaseChecker = () => {
  const localVersion = pjson.version;
  const [lastVersion, setLastVersion] = useState(null);
  const [isOldRelease, setIsOldRelease] = useState(false);

  const checkLastVersion = () => {
    request('/frontend_release/mow')
      .then((version) => setLastVersion(version || 'unknown'))
      .catch((e) =>
        toast.warn(
          `No se han podido comprobar las actualizaciones (${e.error})`
        )
      );
  };

  function compareVersions() {
    if (!lastVersion) return;
    if (lastVersion === 'unknown') return;

    let isOldVersion = false;

    const lastVersionChunks = lastVersion
      .replace(/[a-zA-Z]/g, '')
      .split('.')
      .map(Number); //get version numbers separated by dot [4.5.22] fron strings like 'v4.5.22', 'ver 4.5.22' or 'ver4.5.22'
    const localVersionChunks = localVersion.split('.').map(Number);

    for (let i = 0; i < lastVersionChunks.length; i++) {
      if (lastVersionChunks[i] > localVersionChunks[i]) {
        isOldVersion = true;
        break;
      }
    }

    setIsOldRelease(isOldVersion);
  }

  useInterval(() => checkLastVersion(), 10 * 60 * 1000); // Every 10 minutes
  useEffect(() => compareVersions(), [lastVersion]);
  useEffect(() => checkLastVersion(), []);

  return { isOldRelease, lastVersion };
};

export default useReleaseChecker;
