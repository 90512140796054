import { useCallback, useEffect } from 'react';
import { useAuth } from '../contexts/auth';
import { useRouter } from 'next/router';

import {
  SUPERADMIN_LEVEL,
  MANAGER_LEVEL,
  ADMINISTRATOR_LEVEL,
} from '../shared/constants';
import { ALLOWED_URLS } from '../shared/constants';

const ProtectAppRoute = ({ children }) => {
  const { level } = useAuth();
  const router = useRouter();

  const checkAccess = () => {
    //check MANAGER AUTHORIZED URLS
    if (
      level === MANAGER_LEVEL &&
      !ALLOWED_URLS.MANAGER.includes(router.pathname)
    )
      router.push('/');

    //check ADMINISTRATOR AUTHORIZED URLS
    if (
      level === ADMINISTRATOR_LEVEL &&
      !ALLOWED_URLS.ADMINISTRATOR.includes(router.pathname)
    )
      router.push('/');
  };

  useEffect(() => {
    if (![SUPERADMIN_LEVEL, MANAGER_LEVEL, ADMINISTRATOR_LEVEL].includes(level))
      return;
    checkAccess();
  }, [level, router.asPath]);

  return children;
};

export default ProtectAppRoute;
