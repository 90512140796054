import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import Link from 'next/link';

const VARIANT_TEAL = 'teal';
const VARIANT_RED = 'red';
const VARIANT_BLUE = 'blue';
const VARIANT_GREEN = 'green';
const VARIANT_BLACK = 'black';
const VARIANT_PINK = 'pink';
const VARIANT_TRANSPARENT = 'transparent';

const Button = ({
  children,
  minWidth = false,
  fullWidth = false,
  color = VARIANT_TEAL,
  rounded = false,
  type = '',
  href,
  truncate = false,
  className = '',
  ...props
}) => {
  const Tag = href ? 'a' : 'button';
  return (
    <ConditionalLink href={href}>
      <Tag
        className={twMerge(
          'flex-inline items-center font-bold text-center text-sm text-white rounded-sm bg-[#6d757d] p-2 max-w-full disabled:opacity-60 disabled:cursor-not-allowed hover:opacity-90',
          minWidth && 'max-w-xs',
          fullWidth && 'w-full',
          rounded && 'rounded-lg',
          truncate && 'truncate',
          getButtonStyle(color, type),
          className
        )}
        {...props}
      >
        {children}
      </Tag>
    </ConditionalLink>
  );
};

const getButtonStyle = (color, type) => {
  switch (true) {
    case color === VARIANT_TEAL && type === '':
      return 'bg-[#009696]';
    case color === VARIANT_PINK && type === '':
      return 'bg-[#f922ad]';
    case color === VARIANT_BLUE && type === '':
      return 'bg-[#227bf9]';
    case color === VARIANT_BLACK && type === '':
      return 'bg-gray-600 hover:bg-gray-500';
    case color === VARIANT_GREEN && type === '':
      return 'bg-green-600 text-white';
    case color === VARIANT_RED && type === '':
      return 'bg-red-600';
    case color === VARIANT_TEAL && type === 'outline':
      return 'bg-transparent border border-teal-500 text-teal-500 hover:bg-teal-500 hover:text-white';
    case color === VARIANT_PINK && type === 'outline':
      return 'bg-transparent border border-pink-500 text-pink-500 hover:bg-pink-500 hover:text-white';
    case color === VARIANT_BLUE && type === 'outline':
      return 'bg-transparent border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white';
    case color === VARIANT_BLACK && type === 'outline':
      return 'bg-transparent border border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white';
    case color === VARIANT_GREEN && type === 'outline':
      return 'bg-transparent border border-green-600 text-green-700 hover:bg-green-700 hover:text-white';
    case color === VARIANT_RED && type === 'outline':
      return 'bg-transparent border border-red-600 text-red-600 hover:bg-red-600 hover:text-white';
    case color === VARIANT_TRANSPARENT && type === '':
      return 'bg-transparent';
    case color === VARIANT_TRANSPARENT && type === 'outlined':
      return 'bg-transparent border border-white';
  }
};

const ConditionalLink = ({ href, children }) => {
  return href ? (
    <Link href={href} legacyBehavior>
      {children}
    </Link>
  ) : (
    children
  );
};

Button.VARIANT_TEAL = VARIANT_TEAL;
Button.VARIANT_RED = VARIANT_RED;
Button.VARIANT_BLUE = VARIANT_BLUE;
Button.VARIANT_GREEN = VARIANT_GREEN;
Button.VARIANT_BLACK = VARIANT_BLACK;
Button.VARIANT_PINK = VARIANT_PINK;
Button.VARIANT_TRANSPARENT = VARIANT_TRANSPARENT;

Button.propTypes = {
  children: PropTypes.node.isRequired,
  rounded: PropTypes.bool,
  minWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
  tagName: PropTypes.oneOf(['a', 'button', 'Link', 'NavLink']),
  type: PropTypes.oneOf(['', 'outline']),
  disabled: PropTypes.bool,
};

export default Button;
