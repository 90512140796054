import { useIntl } from 'react-intl';

import Tooltip from '../../../Tooltip';

import IconSpyUsers from '../../../../public/assets/images/icons/spy.svg';

const SpyUsersCounter = ({ spyUsers = 0 }) => {
  const { formatMessage: t } = useIntl();

  if (!spyUsers) return null;

  return (
    <Tooltip content={t({ id: 'spy_users' })}>
      <div className="flex gap-1 items-center  text-black border border-black p-1 text-xs rounded-lg">
        <IconSpyUsers />
        <span> {spyUsers}</span>
      </div>
    </Tooltip>
  );
};

export default SpyUsersCounter;
