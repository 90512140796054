import { useEffect, useRef } from 'react';

import { getRealTimeNotificationSounds } from '../shared/cookies';

import incomingcall from '../public/assets/sounds/incoming.mp3';
import outcomingcall from '../public/assets/sounds/calling.mp3';
import newUser from '../public/assets/sounds/user.mp3';
import incomingChat from '../public/assets/sounds/chat.mp3';
import soundWarning from '../public/assets/sounds/beep_warning.mp3';

const useSounds = () => {
  const SOUND_INCOMING_CALL = useRef(null);
  const SOUND_OUTCOMING_CALL = useRef(null);
  const SOUND_NEW_USER = useRef(null);
  const SOUND_INCOMING_CHAT = useRef(null);
  const SOUND_WARNING = useRef(null);

  useEffect(() => {
    SOUND_INCOMING_CALL.current = new Audio(incomingcall);
    SOUND_OUTCOMING_CALL.current = new Audio(outcomingcall);
    SOUND_NEW_USER.current = new Audio(newUser);
    SOUND_INCOMING_CHAT.current = new Audio(incomingChat);
    SOUND_WARNING.current = new Audio(soundWarning);
  }, []);

  const playSound = (sound) => {
    let soundActive = getRealTimeNotificationSounds();
    if (parseInt(soundActive) === 1) sound.play();
  };

  return {
    playIncomingCall: () => playSound(SOUND_INCOMING_CALL.current),
    playOutComingCall: () => playSound(SOUND_INCOMING_CALL.current),
    playIncomingChat: () => playSound(SOUND_INCOMING_CHAT.current),
    playNewUser: () => playSound(SOUND_NEW_USER.current),
    stopIncomingCall: () => {
      SOUND_INCOMING_CALL.current.pause();
      SOUND_INCOMING_CALL.current.currentTime = 0;
    },
    playWarning: () => playSound(SOUND_WARNING.current),
  };
};

export default useSounds;
