import { useState } from 'react';
import { useIntl } from 'react-intl';

import { exportAsImage } from '../../../helpers/utils';

import PanelReport from '../PanelReport';
import Tooltip from '../../Tooltip';

import IconReport from '../../../public/assets/images/icons/chart-box-plus-outline.svg';
import { useMonitor } from '../../../contexts/monitor';

const ButtonPanelReport = ({ containerRef = null }) => {
  const { formatMessage: t } = useIntl();
  const { setScreenShot } = useMonitor();

  const takeMonitorSnapshot = () => {
    exportAsImage(containerRef.current)
      .then((snapShot) => setScreenShot(snapShot))
      .catch((e) => toast.error(e.getMessage()));
  };

  return (
    <button onClick={takeMonitorSnapshot}>
      <Tooltip content={t({ id: 'report' })}>
        <IconReport className="w-6 h-6" />
      </Tooltip>
    </button>
  );
};

export default ButtonPanelReport;
