import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { useIntl } from 'react-intl';

const Group = ({ children, className = null, visible = true }) => {
  if (!visible) return;
  return (
    <div className={twMerge('relative flex w-full', className)}>{children}</div>
  );
};

const Text = ({
  type = 'text',
  className = null,
  error,
  label = false,
  Icon = null,
  iconClassName = '',
  fullWidth = true,
  isLoading = false,
  visible = true,
  labelInline = false,
  disabled = false,
  isRequired = false,
  ...props
}) => {
  if (!visible) return;

  return (
    <div
      className={twMerge(
        'flex',
        label && !labelInline && 'flex-col',
        labelInline && 'gap-2 items-center',
        fullWidth && 'w-full'
      )}
    >
      {label && (
        <label className="text-gray-600 text-sm">
          {isRequired && '*'}
          {label}
        </label>
      )}
      <span className="relative flex items-center w-full">
        <input
          type={props.Type ? props.Type : type}
          className={twMerge(
            'focus:border-teal-400 focus:ring-teal-200 focus:ring-4 focus:ring-opacity-50 focus:outline-none',
            'border border-[#cdd0d5] py-[6px] px-3 text-sm text-gray-600',
            error &&
              'bg-red-50 border border-red-500 focus:border-red-400 focus:ring-red-200',
            className,
            fullWidth && 'w-full',
            Icon && 'relative pl-8',
            disabled && 'bg-gray-100 text-opacity-90'
          )}
          disabled={disabled}
          {...props}
        />
        {Icon && (
          <Icon
            className={twMerge(
              'absolute left-1 text-gray-300 w-[22px] h-[22px]',
              iconClassName
            )}
          />
        )}
        {isLoading && (
          <span className="absolute right-2 text-gray-300 rounded-full border-4 border-x-gray-500 border-b-gray-800 border-t-gray-500 h-5 w-5 animate-spin " />
        )}
      </span>
    </div>
  );
};

const TextArea = ({
  className = null,
  label = null,
  children,
  error,
  disabled = false,
  hidden = false,
  ...props
}) => {
  return (
    <div className={twMerge('flex flex-col mb-1', hidden && 'hidden')}>
      {label && <label className="text-gray-600 text-sm">{label}</label>}
      <textarea
        className={twMerge(
          'focus:border-teal-400 focus:ring-teal-200 focus:ring-4 focus:ring-opacity-50 focus:outline-none',
          'border border-[#cdd0d5] leading-6 py-[6px] px-3 text-sm w-full text-gray-600',
          error &&
            'bg-red-50 border border-red-500 focus:border-red-400 focus:ring-red-200',
          disabled && 'bg-gray-200 cursor-not-allowed',
          className
        )}
        {...props}
      >
        {children}
      </textarea>
    </div>
  );
};

const File = forwardRef(
  ({ type = 'file', className = null, ...props }, ref) => (
    <input
      ref={ref}
      type={type}
      className={twMerge('form-control', className)}
      {...props}
    />
  )
);

const Button = ({ children, className, ...props }) => {
  return (
    <button
      type="button"
      className={twMerge(
        'py-1 px-3 border border-gray-300 border-l-0 enabled:hover:bg-black/50 disabled:opacity-60',
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

const GroupText = ({ children, className, ...props }) => {
  return (
    <div
      className={twMerge(
        'border border-[#cdd0d5] border-l-0 bg-gray-200 px-1 items-center flex',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

const Input = {
  Group,
  Text,
  Button,
  GroupText,
  File,
  TextArea,
};

File.displayName = 'File';

export default Input;
