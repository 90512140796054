import { useReducer } from 'react';
import { useInterval } from 'react-use';

const useDisconnection = () => {
  const initialState = {
    socketDisconnected: false,
    reconnecting: false,
    reason: null,
    reconnectionOpportunities: 10,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'socketDisconnected':
        return {
          ...state,
          socketDisconnected: true,
          reason: action.payload.reason || state.reason,
          reconnecting:
            action.payload.reconnecting || state.reconnectionOpportunities > 0,
        };
      case 'socketConnected':
        return {
          ...state,
          socketDisconnected: false,
          reason: null,
          reconnectionOpportunities: initialState.reconnectionOpportunities,
        };
      case 'reconnectionFailed':
        if (state.reconnectionOpportunities <= 1) {
          return {
            ...state,
            socketDisconnected: true,
            reconnecting: false,
            reconnectionOpportunities: 0,
          };
        }
        return {
          ...state,
          reconnectionOpportunities: state.reconnectionOpportunities - 1,
          reconnecting: true,
        };
      default:
        throw new Error(`Invalid reducer action.type (${action.type})`);
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useInterval(
    () => dispatch({ type: 'reconnectionFailed' }),
    state.reconnecting ? 1000 : null
  );

  return [state, dispatch];
};

export { useDisconnection };
