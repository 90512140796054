import { useCallback, useEffect, useState } from 'react';
//import LivelyChat from "@livelyvideo/chat-core";
import { usePrevious } from 'react-use';

import { useAuth } from '../contexts/auth';

import { MESSAGE_TYPES, ROOM_MODE, ROOM_EVENTS } from '../shared/constants';
import Logger from '../shared/logger';

const logger = new Logger('useSocketRoom');

const useSocketRoom = ({
  prepared,
  userData,
  accessToken,
  instance,
  roomName,
  paymentType,
  disconnectionDispatch,
  setRoomMessages,
  setRoomUsers,
  setRoomUserCounter,
}) => {
  const [socketIdRoom, setSocketIdRoom] = useState(null); // Only used with Socket Invertred
  const [performerSocketId, setPerformerSocketId] = useState(null); // Performer's socket id Only used with Socket Invertred
  const [socketRoom, setSocketRoom] = useState(null); // Socket Invertred
  const [freechat, setFreechat] = useState(null); // Socket Lively
  const [connected, setConnected] = useState(false); // Invertred / Lively common
  const [connecting, setConnecting] = useState(false); // Invertred / Lively common
  const { nick: userNick, logout } = useAuth();
  const prevMode = usePrevious(paymentType);
  //admin_chat
  // Socket Invertred events:
  const onWSStatus = useCallback(
    (data) => {
      logger.log('socket room event received:', data.status);

      switch (data.status) {
        case 'connected':
          // WS connected, waiting for connection_accepted
          break;
        case 'connection_accepted':
          setConnected(true);
          setConnecting(false);
          setSocketIdRoom(data.message.socket);
          setPerformerSocketId(data.message.performerSocket);
          disconnectionDispatch({ type: 'socketConnected' });
          // ToDo: Credits interval on
          break;
        case 'connection_failed':
          if (data.message.toLowerCase() === ROOM_EVENTS.ROOM_CLOSED) {
            disconnectionDispatch({
              type: 'socketDisconnected',
              payload: { reason: ROOM_EVENTS.ROOM_CLOSED, reconnecting: false },
            });

            //ToDo
            /*
          - show message on chat "Room disconnect" - see context Chat
          - wait 5 seconds to recovery in case is a connection problem from perfomer and redirect to menu
          */
          } else if (data.message.toLowerCase() === ROOM_EVENTS.USER_KICKED) {
            disconnectionDispatch({
              type: 'socketDisconnected',
              payload: {
                reason: ROOM_EVENTS.USER_KICKED,
                reconnecting: false,
              },
            });
            logout();
          }
        case 'disconnect':
          setConnected(false);
          setConnecting(true);
          // ToDo: Credits interval off
          break;
        case 'reconnect_failed':
          setConnected(false);
          setConnecting(false);
          socketRoom.close(); // ToDo: Confirm
          break;
        case 'reconnect':
          setConnected(false);
          setConnecting(true);
          break;
        case 'chat':
          logger.log('chat', data);

          /* 
            data{
              message:{
                messageType:"credits"
                msg: 1
                nick: "marco"
                roomName: "pruebas-m8w5d2d4c"
                to: 0
              }
            } 
            */

          //only credits or toy messages are added
          if (['credits', 'toy'].includes(data.message.messageType)) {
            setRoomMessages((currentMessages) => {
              return [
                ...currentMessages,
                {
                  nick: data.message.nick,
                  text: data.message.msg,
                  to: data.message.to,
                  id: 0,
                  messageType: data.message.messageType,
                },
              ];
            });
          }
          break;
        case 'admin_chat':
          logger.log('admin_chat', data);
          const { nick, msg, to } = data.message;
          if (!data.message.messageType) data.message.messageType = 'text';
          if (data.message.messageType === 'text') {
            setRoomMessages((currentMessages) => {
              return [
                ...currentMessages,
                {
                  nick: data.message.nick,
                  text: data.message.msg,
                  to: data.message.to,
                  id: data.message.from,
                  translated: data.message.translated ?? 0,
                  original_lang: data.message.original_lang ?? '',
                  translated_lang: data.message.translated_lang ?? '',
                  Original_text: data.message.Original_text ?? '',
                  messageType: 'text',
                },
              ];
            });
          } else if (data.message.messageType === 'users') {
            setRoomUserCounter(data.message.msg);
          }

          break;
        case 'admin_users':
          logger.log('admin_users', data);
          setRoomUsers(data.message);
          break;
        // no-default
      }
    },
    [disconnectionDispatch, logout, socketRoom]
  );

  const disconnect = useCallback(() => {
    logger.log('disconnect');
    if (socketRoom) socketRoom.close();
    //if (freechat) freechat.destroy();
    setSocketRoom(null);
    setSocketIdRoom(null);
    setFreechat(null);
    setConnecting(false);
    setConnected(false);
    setPerformerSocketId(null);
  }, [freechat, socketRoom]);

  const connectAsAdmin = useCallback(async () => {
    logger.log('connectAsAdmin', { instance, userData, accessToken });
    if (!instance) {
      logger.warn('empty instance on connectAsAdmin');
      return;
    }
    if (!userData) {
      logger.warn('empty userData on connectAsAdmin');
      return;
    }
    if (!accessToken) {
      logger.warn('empty accessToken on connectAsAdmin');
      return;
    }
    if (socketRoom) {
      logger.warn('socket already created, closing...');
      socketRoom.close();
    }
    const SocketRoomInvertred = (await import('../shared/socket-room')).default;

    logger.log('delaying new socket creation...');
    await new Promise((resolve) => setTimeout(resolve, 500));
    logger.log('...new socket creation delay finished');

    setSocketRoom(
      new SocketRoomInvertred({
        instance,
        userData,
        accessToken,
        paymentType,
      })
    );
  }, [instance, userData, accessToken, socketRoom, paymentType]);

  /*const connectFreeChat = useCallback(() => {
    logger.log("connectFreeChat");

    if (freechat) {
      logger.warn("freechat already created, closing...");
      freechat.destroy();
    }

    const livelyUrl = instance.replace(/(^\w+:|^)\/\//, "").slice(0, -1);

    const chatOptions = {
      user: userNick,
      room: roomName,
      host: livelyUrl,
      wshost: livelyUrl,
    };

    const authOptions = {
      bootstrap: {
        token: accessToken,
      },
    };

    logger.log("connectFreeChat values", {
      livelyUrl,
      chatOptions,
      authOptions,
    });

    //setFreechat(new LivelyChat(chatOptions, authOptions));
  }, [accessToken, freechat, instance, roomName, userNick]);*/

  const connectToRoom = useCallback(() => {
    logger.log('connectToRoom', { connected, connecting });
    if (connected) {
      logger.warn('connectToRoom already connected');
      return;
    }
    if (connecting) {
      logger.warn('connectToRoom already connecting');
      return;
    }

    connectAsAdmin();
    //}, [connectFreeChat, connectAsAdmin, connected, connecting, mode]);
  }, [connectAsAdmin, connected, connecting, paymentType]);

  /* useEffect(() => {
    if (paymentType !== 25010) return;
    logger.log("disconnecting because enters as moderator");
    connectToRoom();
  }, [paymentType, prevMode, disconnect, connected]);*/

  useEffect(() => {
    if (!prepared) return;
    if (connected || connecting) return;

    setConnecting(true);
    connectToRoom();
  }, [prepared, connected, connecting, setConnecting, connectToRoom]);

  useEffect(() => {
    if (!socketRoom) return;
    logger.log('setting ws events...');
    socketRoom.on('ws_status_room', onWSStatus);

    return () => {
      logger.log('removing ws events...');
      socketRoom.off('ws_status_room', onWSStatus);
      logger.log('closing ws...');
      socketRoom.close();
    };
  }, [onWSStatus, socketRoom]);

  /*const onFreechatReady = () => {
    logger.log("onFreechatReady");
    setConnected(true);
    setConnecting(false);

    // if (_mode === 'freechat') {
    //   var notiContent = {
    //     nick: userData.userNick,
    //     user: parseInt(userData.profile_id),
    //     event: 'connected',
    //     livelyApiUrl: _roomData.livelyApiUrl,
    //     accessToken: _accessToken,
    //   };

    //   _self._notifyFreeChatUser(
    //     _roomData.roomName,
    //     notiContent,
    //     _stagingLively
    //   );
    // }
  };*/

  /*const onFreeChatDisconnect = () => {
    logger.log("onFreeChatDisconnect");
  };*/

  /*const onFreechatClose = () => {
    logger.log("onFreechatClose");
  };*/

  /*const onFreechatError = (event) => {
    logger.warn("onFreechatError", event);
  };*/

  /* const onFreechatNotification = useCallback(
    ({ notification }) => {
      logger.log("onFreechatNotification", notification);
      if (notification.event === "forbidden") {
        logger.log("onFreechatNotification forbidden");
        // deleteFreeToken is a redundant request performed
        // twice to ensure token deletion. 404 is also OK.
        RoomsService.deleteFreeToken(accessToken);
      }
    },
    [accessToken]
  );*/

  /* const onFreechatChat = (event) => {
    logger.log("onFreechatChat", event);
  };*/

  /* useEffect(() => {
    if (!freechat) return;
    logger.log("setting lively ws events...");
    freechat.on("ready", onFreechatReady);
    freechat.on("disconnect", onFreeChatDisconnect);
    freechat.on("close", onFreechatClose);
    freechat.on("error", onFreechatError);
    freechat.on("notification", onFreechatNotification);
    freechat.on("chat", onFreechatChat);

    return () => {
      logger.log("removing lively ws events...");
      freechat.off("ready", onFreechatReady);
      freechat.off("disconnect", onFreeChatDisconnect);
      freechat.off("close", onFreechatClose);
      freechat.off("error", onFreechatError);
      freechat.off("notification", onFreechatNotification);
      freechat.off("chat", onFreechatChat);
      logger.log("closing lively ws...");
      freechat.destroy();
    };
  }, [freechat, onFreechatNotification]);*/

  return {
    socketIdRoom,
    socketRoom,
    connected,
    connecting,
    disconnect,
    performerSocketId,
  };
};

export { useSocketRoom };
