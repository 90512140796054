import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { getRealTimeHost } from '../shared/cookies';

import { openModal } from '../store/slices/modals';

import IconArrowUp from '../public/assets/images/icons/arrow-up.svg';
import IconArrowDown from '../public/assets/images/icons/arrow-down.svg';
import IconArrowExpand from '../public/assets/images/icons/arrow-expand.svg';

const DebugBar = () => {
  const dispatch = useDispatch();
  const { apiEnv, devbugBarPosition } = useSelector((state) => state.debug);
  const [apiEnvironment, setAPIEnvironment] = useState(apiEnv);

  const [position, setPosition] = useState(devbugBarPosition);
  const [socket, setSocket] = useState(
    getRealTimeHost() === 'PROD'
      ? process.env.NEXT_PUBLIC_REALTIME_HOST
      : getRealTimeHost()
  );

  const changePosition = () => setPosition(position === 'up' ? 'down' : 'up');

  const expand = () => dispatch(openModal('debug'));

  useEffect(() => {
    setAPIEnvironment(apiEnv);

    if (apiEnv === 'prod' && socket === process.env.NEXT_PUBLIC_REALTIME_HOST)
      return () => {};
  }, [apiEnv, socket]);

  return (
    <>
      {(apiEnvironment !== 'prod' ||
        socket !== process.env.NEXT_PUBLIC_REALTIME_HOST) && (
        <div
          className={classNames(
            `z-10 shadow flex items-center gap-0.5 bg-white rounded-full fixed left-1/2 -translate-x-1/2 p-1 text-black text-xs `,
            position === 'up' && 'top-2',
            position === 'down' && 'bottom-2'
          )}
        >
          <Button onClick={changePosition}>
            {position === 'up' ? <IconArrowDown /> : <IconArrowUp />}
          </Button>
          <Button onClick={expand}>
            <IconArrowExpand />
          </Button>

          {apiEnvironment === 'dev' && <Item>DEVAPI</Item>}
          {apiEnvironment === 'local' && <Item>LOCAL (Docker)</Item>}
          {socket !== process.env.NEXT_PUBLIC_REALTIME_HOST && (
            <Item>STAGING WS</Item>
          )}
        </div>
      )}
    </>
  );
};

const Button = ({ children, ...props }) => {
  return (
    <button
      className="rounded-full bg-slate-300 hover:bg-slate-400 p-1.5 transition-all"
      {...props}
    >
      {children}
    </button>
  );
};

const Item = ({ children }) => {
  return (
    <div className="rounded-full bg-red-500 py-1 px-2 text-white font-bold">
      {children}
    </div>
  );
};

export default DebugBar;
