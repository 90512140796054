import { useIntl } from 'react-intl';

import Tooltip from '../../Tooltip';

import IconNext from '../../../public/assets/images/icons/skip-next.svg';
import IconPrev from '../../../public/assets/images/icons/skip-previous.svg';
import { useMonitor } from '../../../contexts/monitor';

const ButtonsChangeRoom = () => {
  const { formatMessage: t } = useIntl();
  const { nextPerformer, prevPerformer, displayMonitor } = useMonitor();

  return (
    <>
      {prevPerformer && (
        <Tooltip content={t({ id: 'previous' })}>
          <IconPrev
            className="w-5 h-5 bg-gray-300 rounded-full p-1 text-black font-bold hover:bg-gray-800 hover:text-white"
            onClick={() => {
              displayMonitor(prevPerformer);
            }}
          />
        </Tooltip>
      )}
      {nextPerformer && (
        <Tooltip content={t({ id: 'next' })}>
          <IconNext
            className="w-5 h-5 bg-gray-300 rounded-full p-1 text-black font-bold hover:bg-gray-800 hover:text-white"
            onClick={() => {
              displayMonitor(nextPerformer);
            }}
          />
        </Tooltip>
      )}
    </>
  );
};

export default ButtonsChangeRoom;
