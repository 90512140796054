import { useEffect, useRef } from 'react';

const OutsideClickAlerter = ({ children, onClickOutside = () => {} }) => {
  const wrapperRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  /**
   * Alert if clicked on outside of element
   */
  const handleClick = (event) => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      onClickOutside();
    }
  };

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClickAlerter;
