import request from './api';

function sendResetPasswordLink(nick) {
  return request({
    url: `/reset_wc_pwd`,
    data: {
      user: nick,
    },
    method: 'POST',
  });
}

function checkNick(nick) {
  return request({
    url: `/admin/performer/checknick`,
    data: {
      nick,
    },
    method: 'POST',
  });
}

function addRoomData(roomData) {
  return request({
    url: `/performer/add`,
    data: roomData,
    method: 'POST',
  });
}

function updateRoomData(roomData) {
  return request({
    url: `/performer`,
    data: roomData,
    method: 'PUT',
  });
}

function addRoomPerformer(roomId, roomPerformerData) {
  var bodyFormData = new FormData();
  bodyFormData.append('idwebcammer', roomId);
  bodyFormData.append('name', roomPerformerData.name);
  bodyFormData.append('dni_front', roomPerformerData.imagesDNI.front);
  bodyFormData.append('dni_back', roomPerformerData.imagesDNI.back);
  bodyFormData.append('validation_img', roomPerformerData.imagesDNI.performer);
  bodyFormData.append(
    'dni',
    typeof roomPerformerData.imagesDNI.dni === 'undefined'
      ? roomPerformerData.dni
      : roomPerformerData.imagesDNI.dni
  );
  bodyFormData.append('birthday', roomPerformerData.birthdate);
  bodyFormData.append('document_country', roomPerformerData.country);

  return request({
    url: `/photos/dni`,
    data: bodyFormData,
    headers: {
      'content-type': 'multipart/form-data',
    },
    method: 'POST',
  });
}

function removeRoomPerformer(roomId, documentNumber) {
  return request({
    url: `/photos/dni/${documentNumber}?idwebcammer=${roomId}`,
    method: 'DELETE',
  });
}

function editRoomPerformer({
  id,
  documentNumber,
  birthdate,
  documentCountry,
  name,
}) {
  return request({
    url: `/photos/dni?id=${id}&document_number=${documentNumber}&birth_date=${birthdate}&document_country=${documentCountry}&name=${name}`,
    method: 'PUT',
  });
}

function getPerformer(id) {
  return request({
    url: `/performer?id=${id}`,
    data: {},
    method: 'GET',
  });
}

function getBanHistory(idwebcammer) {
  return request({
    url: `/performer/ban/history?idwebcammer=${idwebcammer}`,
    method: 'GET',
  });
}

function updateBanData({
  idwebcammer = -1,
  action = null,
  num_days = 0,
  notes = '',
  message = '',
  tag_id = 8,
}) {
  const params = new URLSearchParams({
    idwebcammer,
    action,
    num_days,
    notes,
    message,
  });

  if (action === 'ban') params.set('tag_id', tag_id);

  return request({
    url: `/performer/ban?${params.toString()}`,
    method: 'PUT',
  });
}

function getRatesList(idwebcammer) {
  return request({
    url: `/performer/rates/list/${idwebcammer}`,
    method: 'GET',
  });
}

function getCurrentRate(idwebcammer) {
  return request({
    url: `/performer/rates/currentrate/${idwebcammer}`,
    method: 'GET',
  });
}

function AddRate({
  idwebcammer,
  start,
  rate_a,
  rate_b,
  rate_link,
  min_hours,
  min_generated,
  rate_extra,
  media_min,
  remarks,
  active_remarks,
  rate_private,
  rate_vip,
  rate_tips,
  pack1,
  pack2,
  pack3,
  pack4,
  pack5,
  pack6,
  pack12,
}) {
  return request({
    url: '/performer/rates/add',
    data: {
      idwebcammer,
      start,
      rate_a,
      rate_b,
      rate_link,
      min_hours,
      min_generated,
      rate_extra,
      media_min,
      remarks,
      active_remarks,
      rate_private,
      rate_vip,
      rate_tips,
      pack1,
      pack2,
      pack3,
      pack4,
      pack5,
      pack6,
      pack12,
    },
    method: 'POST',
  });
}

function validateRoom(idroom, validation_result, reason) {
  const params = new URLSearchParams({
    idwebcammer: idroom,
    validation_result,
    reason,
  });

  return request({
    url: `/admin/validate/performer?${params.toString()}`,
    method: 'PUT',
  });
}

function checkRoomCollisions(idroom) {
  return request({
    url: `/admin/dni/collisions?idwebcammer=${idroom}`,
    method: 'GET',
  });
}

function getBanTags() {
  return request({
    url: '/performer/ban/tags',
  });
}

function updateRate(data) {
  const params = new URLSearchParams(data);
  return request({
    url: `/performer/rates/rate?${params.toString()}`,
    method: 'PUT',
  });
}

function sendWelcomeMessage(data) {
  return request({
    url: '/admin/performer/welcome',
    data,
    method: 'POST',
  });
}

function regenerateLivelyKey(performer_id, livelyStaging = 1) {
  return request({
    url: '/performer/performerkey',
    data: {
      performer_id,
      regenerate: 1,
      livelystaging: livelyStaging ? 1 : 0,
    },
    method: 'POST',
  });
}

function getRealTimeData(performer_id) {
  return request({
    url: `https://devapi.modelsonwebcam.com/performer/realtime/${performer_id}`,
    method: 'GET',
  });
}

function reloadCacheRoom(performer_id) {
  return request({
    url: '/performer/reloadcacheroom',
    data: { performer_id },
    method: 'PUT',
  });
}

const WebcammerService = {
  sendResetPasswordLink,
  checkNick,
  addRoomData,
  updateRoomData,
  addRoomPerformer,
  getPerformer,
  removeRoomPerformer,
  editRoomPerformer,
  getBanHistory,
  updateBanData,
  getRatesList,
  getCurrentRate,
  AddRate,
  validateRoom,
  checkRoomCollisions,
  getBanTags,
  updateRate,
  sendWelcomeMessage,
  regenerateLivelyKey,
  getRealTimeData,
  reloadCacheRoom,
};

export default WebcammerService;
