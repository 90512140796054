import classNames from 'classnames';
import { useAuth } from '../../contexts/auth';

const TextMessage = ({ nick, text }) => {
  const { userName: authenticatedUserNick } = useAuth();
  const isMessageFromUser = authenticatedUserNick === nick;

  return (
    <div
      className={classNames(
        'relative mx-[10px] max-w-[90%]',
        'before:content[""] before:w-0 before:h-0 before:block before:absolute before:border-solid',
        'before:top-0',
        isMessageFromUser && [
          'place-self-end',
          'before:border-t-0 before:border-r-0 before:border-b-[15px] before:border-l-[20px]',
          'before:border-t-[#ffffff] before:border-r-[#ffffff] before:border-b-transparent before:border-l-[#ffffff]',
          'before:-right-[15px]',
        ],
        !isMessageFromUser && [
          'place-self-start',
          'before:border-t-0 before:border-r-[20px] before:border-b-[15px] before:border-l-0',
          'before:border-t-transparent before:border-r-[#eef2f4] before:border-b-transparent before:border-l-transparent',
          'before:-left-[15px]',
        ]
      )}
    >
      <div
        className={classNames(
          `p-2 rounded-md w-fit mb-2 text-[#757779]`,
          isMessageFromUser
            ? 'bg-white rounded-tr-none'
            : 'bg-[#eef2f4] rounded-tl-none'
        )}
      >
        <div className="text-sm font-bold w-full">{nick}</div>
        <div className="text-xs">{text}</div>
      </div>
    </div>
  );
};

export { TextMessage };
