import { useEffect, useState, useRef, memo } from 'react';
import dynamic from 'next/dynamic';
import Draggable from 'react-draggable';
import { twMerge } from 'tailwind-merge';
import { useIntl } from 'react-intl';

import WebcammerService from '../../shared/webcamer-service';
import Logger from '../../shared/logger';
import { MANAGER_LEVEL } from '../../shared/constants';

import { useAuth } from '../../contexts/auth';
import { useMonitor } from '../../contexts/monitor';
import { useRealtime } from '../../contexts/realtime';

import RoomInfoOverlay from './RoomInfoOverlay';
import RoomUsers from './Users';
import RoomChat from './RoomChat';
import MonitorHeader from './MonitorHeader';
import MonitorFooter from './MonitorFooter';
import ModalAlert from '../ModalAlert';

import UserCounter from './UserCounter';
import RoomMode from '../Home/ListPerformersOnline/Room/RoomMode';
import SoundVolumeControl from './Controls/SoundVolumeControl';
import StreamProfile from './Controls/StreamProfile';
import ButtonShowDevices from './Controls/ButtonShowDevices';
import ButtonFullScreen from './Controls/ButtonFullScreen';
import PanelReport from './PanelReport';

const logger = new Logger('Monitor');
const Player = dynamic(() => import('../Player'));
const Monitor = () => {
  const { formatMessage: t } = useIntl();
  const [videoLoading, setVideoLoading] = useState(true);
  const [roomOffline, setRoomOffline] = useState(false);
  const [showInfoOverlay, setShowInfoOverlay] = useState(false);
  const {
    accessToken,
    prepareRoom,
    roomData,
    modelId,
    setRoomUserCounter,
    monitorFloating,
    kickRoom,
    setKickRoom,
    forceCloseRoom,
    setRoomData,
    fullscreen,
    volume,
  } = useMonitor();
  const { setRemoteUserProperty, checkUsersTimeInRoom } = useRealtime();
  const monitorRef = useRef();
  const playerRef = useRef();
  const { checkToken, level } = useAuth();

  useEffect(() => {
    if (!roomData) return;
    logger.log('roomData', roomData);
    logger.log('start prepareRooom');

    if (!roomData.socket) {
      logger.log('Getting real time data');
      getRealTimeData();
      return;
    }

    (async () => {
      await prepareRoom({
        modelId: roomData.id,
        instance: roomData.instance,
        roomName: roomData.roomName,
        manifest: roomData.manifest,
        roomMode: roomData.roomMode,
        paymentType: 20010,
      });
      setRoomOffline(false);
      setRoomUserCounter(roomData.users);
      logger.log('prepareRoom finished');
      setRemoteUserProperty('currentRoom', roomData.id);
      setRemoteUserProperty('currentRoomNick', roomData.nick);

      const utcDate = new Date(new Date().toUTCString());
      setRemoteUserProperty('monitoringTimestamp', utcDate.getTime());
      checkUsersTimeInRoom();
    })();
  }, [roomData]);

  useEffect(() => {
    setShowInfoOverlay(false);
    checkToken();
  }, [modelId]);

  const getRealTimeData = () => {
    WebcammerService.getRealTimeData(roomData.id)
      .then((data) => {
        setRoomData({ ...roomData, ...data });
      })
      .catch((e) => {
        logger.error(
          'Ha ocurrido un error al intentar obtener real time data',
          e
        );
      });
  };

  const onPlay = () => {
    setVideoLoading(false);
  };

  const onError = (e) => console.log('onError', e);
  const onDisconnect = ({ reason, roomMode }) => {
    console.warn('onDisconnect', { reason, roomMode });

    if (reason === 'offline') setRoomOffline(true);
  };

  return (
    roomData &&
    modelId && (
      <>
        <Draggable
          disabled={!monitorFloating}
          position={!monitorFloating ? { x: 0, y: 0 } : null}
        >
          <div
            className={twMerge(
              monitorFloating &&
                'md:fixed md:bottom-5 md:bg-white md:right-10 md:shadow-black md:shadow-2xl md:z-50 w-full md:w-1/2 md:cursor-move'
            )}
          >
            <div ref={monitorRef} className="w-full bg-gray-100 rounded-lg">
              <MonitorHeader
                roomOffline={roomOffline}
                floating={monitorFloating}
                onClickInfo={() => {
                  setShowInfoOverlay(!showInfoOverlay);
                }}
              />
              <div className="grid grid-cols-1 md:grid-cols-6 aspect-[10/1] h-auto gap-1">
                <div
                  className="bg-black aspect-video relative md:col-start-1 md:col-end-4"
                  ref={playerRef}
                >
                  {showInfoOverlay && <RoomInfoOverlay roomData={roomData} />}
                  {fullscreen && (
                    <>
                      <RoomChat />
                      <div className="absolute bottom-2 left-1/2 z-50">
                        <div className="flex gap-2">
                          <RoomMode
                            performer={roomData}
                            roomOffline={roomOffline}
                            useShortText={false}
                          />
                          <UserCounter performer={roomData} />
                        </div>
                      </div>
                    </>
                  )}
                  <Player
                    manifest={roomData.manifest}
                    accessToken={accessToken}
                    instanceUrl={roomData.instance}
                    livelyApiUrl="https://invertred.generflow.com/"
                    useWebRTC={true}
                    volume={volume}
                    modelId={roomData.id}
                    onError={onError}
                    onPlay={onPlay}
                    onDisconnect={onDisconnect}
                  />
                  {!roomOffline && (
                    <div className="absolute top-0 flex m-2">
                      <SoundVolumeControl />
                    </div>
                  )}

                  <div className="absolute top-0 right-0 flex m-2">
                    <ButtonShowDevices roomData={roomData} />
                  </div>
                  <StreamProfile />
                  {!roomOffline && (
                    <div className="absolute bottom-0 right-0">
                      <ButtonFullScreen containerRef={playerRef} />
                    </div>
                  )}
                </div>

                {level !== MANAGER_LEVEL && (
                  <div className="overflow-auto h-48 md:h-auto md:col-start-4 md:col-end-6 bg-black/5">
                    <RoomChat />
                  </div>
                )}

                {level !== MANAGER_LEVEL && (
                  <div className="bg-gray-800 px-2">
                    {!roomOffline && <RoomUsers />}
                  </div>
                )}
              </div>
              <MonitorFooter
                performer={roomData}
                roomOffline={roomOffline}
                containerRef={monitorRef}
              />
            </div>
          </div>
        </Draggable>

        <PanelReport />

        {kickRoom && (
          <ModalAlert
            title={`${t({ id: 'close_room' })}`}
            labelSuccess={t({ id: 'close' })}
            description={t(
              {
                id: 'close_confirmation',
              },
              { nick: kickRoom.nick }
            )}
            handleCancel={() => {
              setKickRoom(null);
            }}
            handleSuccess={() => {
              forceCloseRoom(kickRoom.id);
            }}
          />
        )}
      </>
    )
  );
};

export default memo(Monitor);
