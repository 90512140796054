import { useIntl } from 'react-intl';

import { useAuth } from '../../../contexts/auth';
import { useMonitor } from '../../../contexts/monitor';

import { SUPERADMIN_LEVEL } from '../../../shared/constants';

import Tooltip from '../../Tooltip';

import IconCloseRoom from '../../../public/assets/images/icons/skull.svg';

const ButtonCloseRoom = ({ performer = null }) => {
  const { formatMessage: t } = useIntl();
  const { setKickRoom } = useMonitor();
  const { level } = useAuth();

  if (!performer) return null;
  if (level !== SUPERADMIN_LEVEL) return null;

  return (
    <Tooltip content={t({ id: 'close_room' })}>
      <button onClick={() => setKickRoom(performer)} className="block">
        <IconCloseRoom className="text-black w-6 h-6" />
      </button>
    </Tooltip>
  );
};

export default ButtonCloseRoom;
