import { useIntl } from 'react-intl';

import { useAuth } from '../../../contexts/auth';

import MonitorService from '../../../shared/monitor-service';
import { SUPERADMIN_LEVEL } from '../../../shared/constants';

import Tooltip from '../../Tooltip';

import IconAlarm from '../../../public/assets/images/icons/alarm.svg';

const ButtonAlarm = ({ performer = null }) => {
  const { formatMessage: t } = useIntl();
  const { level } = useAuth();

  const alarm = async (id) => {
    const [res, error] = await MonitorService.soundAlarm(id);
  };

  if (!performer) return null;
  if (level !== SUPERADMIN_LEVEL) return null;

  return (
    <Tooltip content={t({ id: 'active_alarm' })}>
      <button onClick={() => alarm(performer.id)} className="block">
        <IconAlarm className="text-red-600 w-6 h-6" />
      </button>
    </Tooltip>
  );
};

export default ButtonAlarm;
