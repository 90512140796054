import { useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import SuperviseService from '../shared/supervise-service';

import Card from './Card';
import Input from './atoms/Input';
import Select from './atoms/Select';
import Button from './atoms/Button';

const BanUser = ({ userData = null, onClose = () => {} }) => {
  const { formatMessage: t } = useIntl();
  const [userNick, setUserNick] = useState(userData?.nick ? userData.nick : '');
  const [banReason, setBanReason] = useState(t({ id: 'ban_user_reason_1' }));
  const [banningUser, setBanningUser] = useState(false);

  const banUser = () => {
    const toastId = toast.loading(t({ id: 'banning_user' }));
    setBanningUser(true);
    SuperviseService.banUser({
      usernick: userNick,
      reason: banReason,
      user_ip: userData?.ip ? userData.ip : '0.0.0.0',
      ban: 1,
    })
      .then(() => {
        toast.update(toastId, {
          render: t({ id: 'user_banned_succesfully' }),
          type: 'success',
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        });
        onClose();
      })
      .catch((e) =>
        toast.update(toastId, {
          render: e.data.error,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        })
      )
      .finally(() => setBanningUser(false));
  };

  return (
    <Card title={t({ id: 'ban_user' })} closeModal={onClose}>
      <div className="flex flex-col gap-3">
        <Input.Text
          label="Nick"
          value={userNick}
          disabled={userData !== null || banningUser}
          onChange={(e) => setUserNick(e.target.value)}
        />
        <Select
          label={t({ id: 'reason_to_ban_user' })}
          value={banReason}
          labelInline={false}
          disabled={banningUser}
          onChange={(e) => setBanReason(e.target.value)}
        >
          <option value={t({ id: 'ban_user_reason_1' })}>
            {t({ id: 'ban_user_reason_1' })}
          </option>
          <option value={t({ id: 'ban_user_reason_2' })}>
            {t({ id: 'ban_user_reason_2' })}
          </option>
          <option value={t({ id: 'ban_user_reason_other' })}>
            {t({ id: 'ban_user_reason_other' })}
          </option>
        </Select>
        <Button
          disabled={banningUser}
          onClick={(e) => {
            e.preventDefault();
            banUser();
          }}
        >
          {t({ id: 'ban' })}
        </Button>
      </div>
    </Card>
  );
};

export default BanUser;
