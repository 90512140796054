import { memo } from 'react';
import Link from 'next/link';
import RoomMode from '../Home/ListPerformersOnline/Room/RoomMode';

import { useMonitor } from '../../contexts/monitor';

import { getOs } from '../../helpers/utils';

import UserCounter from './UserCounter';

import IconToy from '../../public/assets/images/icons/toy.svg';
import IconNew from '../../public/assets/images/icons/new.svg';
import IconPhone from '../../public/assets/images/icons/phone.svg';
import IconEarth from '../../public/assets/images/icons/earth.svg';
import IconInfo from '../../public/assets/images/icons/info.svg';

const MonitorHeader = ({
  roomOffline,
  floating = false,
  onClickInfo = () => {},
}) => {
  const { roomData } = useMonitor();
  if (!roomData) return null;

  return (
    <div className="grid grid-cols-6 border border-gray-300 p-1 items-center">
      <div className="flex col-start-1 col-end-4 gap-1">
        <div className="font-semibold  text-blue-500 text-xs cursor-pointer">
          <IconInfo
            className="h-6 w-6"
            onClick={() => {
              onClickInfo();
            }}
          />
        </div>
        <div className="font-semibold">
          <Link href={`/performers/${roomData.id}`} legacyBehavior>
            {roomData.nick}
          </Link>
        </div>
        <div className="flex text-xl">
          {Number(roomData.new) === 1 && <IconNew />}
          {Number(roomData.device) === 1 && <IconPhone />}
          {Number(roomData.toy) === 1 && <IconToy className="text-pink-500" />}
          {roomData.app === 1 && (
            <span
              data-tip="Usando App"
              className="ml-1 flex items-center justify-center w-5 h-5 border border-teal-600 rounded-full bg-pink-600 text-[9px] font-bold text-white"
            >
              app
            </span>
          )}
        </div>
        <div className="grow"></div>

        <div className="hidden md:flex">
          <IconEarth className="pt-1 h-5 w-5" />
          <span className="p-1 text-xs">
            {roomData.broadcastCity} - {roomData.broadcastCountry}
          </span>
        </div>
        <div className="hidden md:inline-block pt-[2px]">
          {getOs(roomData.osVersion)}
        </div>
      </div>
      <div className="flex col-start-6 justify-end items-center">
        <RoomMode
          performer={roomData}
          roomOffline={roomOffline}
          useShortText={floating}
        />
        {!roomOffline && (
          <>
            <div className="grow"></div>
            <UserCounter performer={roomData} />
          </>
        )}
      </div>
    </div>
  );
};
export default memo(MonitorHeader);
