import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal from './Modal';

import { setApiEnv } from '../store/slices/debug';
import { closeModals } from '../store/slices/modals';

import { setCookiesDebug, getRealTimeHost } from '../shared/cookies';

import Card from './Card';
import Select from '../components/atoms/Select';
import Button from '../components/atoms/Button';

const DebugModal = () => {
  const dispatch = useDispatch();
  const { apiEnv } = useSelector((state) => state.debug);
  const { debug: modalDebugShowed } = useSelector((state) => state.modals);
  const [apiEnvironment, setAPIEnvironment] = useState(apiEnv);
  const [socket, setSocket] = useState(
    getRealTimeHost() === 'PROD'
      ? process.env.NEXT_PUBLIC_REALTIME_HOST
      : getRealTimeHost()
  );

  if (!modalDebugShowed) return null;

  const submit = () => {
    dispatch(setApiEnv(apiEnvironment));
    setCookiesDebug({ apiEnv: apiEnvironment, realTimeHost: socket });
    dispatch(closeModals());
  };

  return (
    <Modal>
      <div className="w-full sm:w-4/5 md:w-3/5 lg:w-2/5">
        <Card
          title="Debug menú"
          closeButton={true}
          onClose={() => dispatch(closeModals())}
          variant={Card.VARIANT_DEVBUG}
        >
          <div className="space-y-2">
            <Select
              label="API"
              labelInline={false}
              value={apiEnvironment}
              onChange={(e) => {
                setAPIEnvironment(e.target.value);
              }}
            >
              <option value="prod">API</option>
              <option value="dev">DEVAPI</option>
              <option value="local">LOCAL (docker)</option>
            </Select>
            <Select
              label="Socket"
              value={socket}
              labelInline={false}
              onChange={(e) => {
                setSocket(e.target.value);
              }}
            >
              <option value="https://realtime.modelsonwebcam.com">
                realtime.modelsonwebcam.com
              </option>
              <option value="https://stagingrealtime.modelsonwebcam.com">
                stagingrealtime.modelsonwebcam.com
              </option>
              <option value="localhost:8080">
                LOCAL (docker) http://localhost:8080
              </option>
            </Select>
            <Button fullWidth variant={Button.VARIANT_TEAL} onClick={submit}>
              Guardar y cerrar
            </Button>
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default DebugModal;
