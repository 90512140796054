import { twMerge } from 'tailwind-merge';

import { getOsIcon, getRoomTypeNameById } from '../../helpers/utils';

import IconInfo from '../../public/assets/images/icons/info.svg';
import IconServer from '../../public/assets/images/icons/server.svg';
import IconUser from '../../public/assets/images/icons/account_details.svg';
import IconUsers from '../../public/assets/images/icons/account-multiple.svg';
import IconCompany from '../../public/assets/images/icons/company.svg';
import IconManifest from '../../public/assets/images/icons/text-box-outline.svg';
import IconImage from '../../public/assets/images/icons/image.svg';
import IconJavascript from '../../public/assets/images/icons/javascript.svg';
import IconClock from '../../public/assets/images/icons/clock.svg';
import IconGender from '../../public/assets/images/icons/gender-transgender.svg';

const RoomInfoOverlay = ({ roomData = null }) => {
  if (!roomData) return null;

  return (
    <div
      className={twMerge(
        'absolute z-50 bg-gray-600/80 flex flex-col border border-gray-400 rounded-lg overflow-scroll text-gray-800 w-full h-full'
      )}
    >
      <div className="bg-black/60 p-2 text-white flex items-center">Info </div>
      <div className="flex flex-col gap-2 justify-start p-2">
        <InfoItem label="ID" value={roomData.id} />
        <InfoItem
          label="Connected at"
          value={roomData.connected_at}
          icon={IconClock}
        />
        <InfoItem
          label="RoomName"
          value={roomData.roomName}
          icon={IconManifest}
        />
        <InfoItem
          label="Instance"
          value={roomData.instance}
          icon={IconServer}
        />
        <InfoItem
          label="Lively API"
          value={roomData.livelyApiUrl}
          icon={IconServer}
        />
        <InfoItem
          label="Manifest"
          value={roomData.manifest}
          icon={IconManifest}
        />
      </div>
      <div className="bg-black/60 p-2 text-white flex items-center">Users</div>
      <div className="flex flex-col gap-2 justify-start p-2">
        <InfoItem label="Free" value={roomData.freeusers} icon={IconUsers} />
        <InfoItem label="Private" value={roomData.users} icon={IconUsers} />
      </div>
      <div className="bg-black/60 p-2 text-white flex items-center">
        Sistema
      </div>
      <div className="flex flex-col gap-2 justify-start p-2">
        <InfoItem
          label="Origin"
          value={roomData.origin}
          icon={IconJavascript}
        />
        <InfoItem
          label="OS"
          value={roomData.osVersion}
          icon={getOsIcon(roomData.osVersion)}
        />
      </div>
      <div className="bg-black/60 p-2 text-white flex items-center">Perfil</div>
      <div className="flex flex-col gap-2 justify-start p-2">
        <InfoItem
          label="Tipo"
          value={`${getRoomTypeNameById(roomData.room_type)} (${
            roomData.room_type
          })`}
          icon={IconGender}
        />
        <InfoItem
          label="Profile image"
          value={roomData.profile_photo}
          icon={IconImage}
        />
        <InfoItem
          label="Estudio"
          value={`${roomData.study_name} (${roomData.study_id})`}
          icon={IconCompany}
        />
        <InfoItem
          label="Manager"
          value={`${roomData.manager_name} (${roomData.manager_id})`}
          icon={IconUser}
        />
      </div>
    </div>
  );
};

const InfoItem = ({ label, value, icon: Icon = IconInfo }) => {
  return (
    <div className="flex items-center gap-1 text-xs">
      <Icon className="h-4 w-4 text-white" />
      <span className="font-semibold text-gray-200">{label}:</span>
      <span className="pl-1 text-white">{value}</span>
    </div>
  );
};

export default RoomInfoOverlay;
