import Cookies from 'js-cookie';

import { HOME_GRID_MODES } from './constants';

// Cookie names collection, can be used here or outside this file,
// for instance in a getServerSideProps method:
export const NAMES = {
  TOKEN: 'token',
  NEXT_LOCALE: 'NEXT_LOCALE',
  API_ENV: 'api_env',
  READ_NEWS: 'read_news',
  REALTIME_HOST: 'mow_realtime_host',
  PLAYER_VOLUME: 'volume',
  HOME_GRID_MODE: 'home_grid_mode',
  REAL_TIME_SOUNDS: 'rt_sounds',
};

// Default cookie setter, with the default expiry value:
const setCookie = (name, value, daysToExpire = 7) => {
  return Cookies.set(name, value, {
    expires: daysToExpire !== null ? daysToExpire : null,
  });
};

export const getCookieToken = () => Cookies.get(NAMES.TOKEN);
export const setCookieToken = (token) => setCookie(NAMES.TOKEN, token, 90);
export const unsetCookieToken = () => setCookie(NAMES.TOKEN, '', -1);

export const setLanguage = (language) =>
  setCookie(NAMES.NEXT_LOCALE, language, 365);
export const unsetLanguage = () => setCookie(NAMES.NEXT_LOCALE, '', -1);

//debug settings cookies
export const setCookiesDebug = ({ apiEnv, realTimeHost }) => {
  setCookie(NAMES.API_ENV, apiEnv, 365);
  setCookie(NAMES.REALTIME_HOST, realTimeHost, 365);
};
export const getCookiesDebug = () => {
  return {
    apiEnv: !Cookies.get(NAMES.API_ENV) ? 'prod' : Cookies.get(NAMES.API_ENV),
    realTimeHost: !!Cookies.get(NAMES.REALTIME_HOST)
      ? false
      : Cookies.get(NAMES.REALTIME_HOST),
  };
};

//Messages type news read
export const setNewsRead = (readNewsList) => {
  return setCookie(NAMES.READ_NEWS, readNewsList.join('-'), 365);
};

export const getNewsRead = () =>
  !Cookies.get(NAMES.READ_NEWS)
    ? []
    : Cookies.get(NAMES.READ_NEWS).split('-').map(Number); //cast every item to number
// Realtime host
export const setRealTimeHost = (env) => {
  setCookie(NAMES.REALTIME_HOST, env);
  debugger;
};
export const getRealTimeHost = () => Cookies.get(NAMES.REALTIME_HOST) ?? 'PROD';
export const removeRealTimeHost = () => Cookies.remove(NAMES.REALTIME_HOST);

export const getPlayerVolume = () => Cookies.get(NAMES.PLAYER_VOLUME) ?? 1;
export const setPlayerVolume = (what) => setCookie(NAMES.PLAYER_VOLUME, what);

export const getHomeGridMode = () =>
  Cookies.get(NAMES.HOME_GRID_MODE) ?? HOME_GRID_MODES.GRID;
export const setHomeGridMode = (what) =>
  setCookie(NAMES.HOME_GRID_MODE, what, 365);

export const getRealTimeNotificationSounds = () =>
  Cookies.get(NAMES.REAL_TIME_SOUNDS) ?? 0;
export const setRealTimeNotificationSounds = (what) =>
  setCookie(NAMES.REAL_TIME_SOUNDS, what, 365);
