import PropTypes from 'prop-types';
import classNames from 'classnames';

const Alert = ({ children, variant = 'secondary', className, ...props }) => {
  return (
    <div
      className={classNames(
        'py-2 px-3 text-sm',
        variant === 'danger' && 'bg-red-100 border border-red-200 text-red-800',
        variant === 'warning' &&
          'bg-yellow-100 border border-yellow-200 text-gray-700',
        variant === 'success' &&
          'bg-green-100 border border-green-200 text-green-800',
        variant === 'info' &&
          'bg-blue-100 border border-blue-200 text-blue-800',
        variant === 'secondary' &&
          'bg-gray-100 border border-gray-200 text-gray-800',
        className
      )}
      role="alert"
      {...props}
    >
      {children}
    </div>
  );
};

Alert.propTypes = {
  variant: PropTypes.oneOf([
    'danger',
    'success',
    'warning',
    'info',
    'secondary',
  ]),
};

export default Alert;
