import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarIsOpen: false,
  sidebarDesktopIsOpen: true,
  loading: false,
};

export const uiSlice = createSlice({
  name: 'uiReducer',
  initialState,
  reducers: {
    setSidebarOpen: (state, action) => {
      state.sidebarIsOpen = action.payload;
    },
    setSidebarDesktopOpen: (state, action) => {
      state.sidebarDesktopIsOpen = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setSidebarOpen, setLoading, setSidebarDesktopOpen } =
  uiSlice.actions;

export default uiSlice.reducer;
