import { useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useMonitor } from '../../contexts/monitor';
import { twMerge } from 'tailwind-merge';
import Tooltip from '../Tooltip';

import SuperviseService from '../../shared/supervise-service';
import {
  REGULAR_PRICES_USERS_IDENTIFIERS,
  SPY_USERS_IDENTIFIERS,
  SUPERADMIN_LEVEL,
} from '../../shared/constants';

import { useAuth } from '../../contexts/auth';

import ModalAlert from '../ModalAlert';
import Modal from '../Modal';
import BanUser from '../BanUser';

import IconPhone from '../../public/assets/images/icons/phone.svg';
import IconActiveCall from '../../public/assets/images/icons/phone-in-talk.svg';
import IconExternal from '../../public/assets/images/icons/open-in-new.svg';
import IconCreditCard from '../../public/assets/images/icons/credit-card.svg';
import IconOle from '../../public/assets/images/icons/account.svg';
import IconVip from '../../public/assets/images/icons/vip.svg';
import IconWebcam from '../../public/assets/images/icons/webcam.svg';
import IconAdmin from '../../public/assets/images/icons/account-eye.svg';
import IconMod from '../../public/assets/images/icons/account-star.svg';
import IconMore from '../../public/assets/images/icons/dots_vertical.svg';
import IconCloseRoom from '../../public/assets/images/icons/skull.svg';
import IconBan from '../../public/assets/images/icons/account-off.svg';
import IconMuted from '../../public/assets/images/icons/mute.svg';
import IconUnmuted from '../../public/assets/images/icons/unmute.svg';

const RoomUsers = () => {
  const { roomUsers, modelId } = useMonitor();

  return (
    <div>
      {roomUsers &&
        roomUsers.map((user, index) => {
          return <User key={index} user={user} performer_id={modelId} />;
        })}
    </div>
  );
};
export default RoomUsers;

const User = ({ user, performer_id }) => {
  const { level } = useAuth();
  const { tipo, nick, device, muted } = user;
  const [showDetail, setShowDetail] = useState(false);
  const [kickUser, setKickUser] = useState(null);
  const [banUser, setBanUser] = useState(null);
  const intl = useIntl();
  const paymentType = Number(tipo);
  const getIcon = (paymentType) => {
    if (paymentType >= 20000 && paymentType < 30000) {
      return <IconAdmin />;
    } else if (paymentType === -30) {
      return <IconActiveCall />;
    } else if (paymentType === 2) {
      return <IconCreditCard />;
    } else if (paymentType >= 300 && paymentType < 400) {
      return <IconOle />;
    } else if (paymentType >= 25000 && paymentType < 30000) {
      return <IconMod />;
    } else if (paymentType === 20) {
      return <IconExternal />;
    }
  };
  const isUserWithRegularPrice = (paymentType) => {
    if (REGULAR_PRICES_USERS_IDENTIFIERS.includes(parseInt(paymentType)))
      return true;
    return false;
  };

  const isEndUser = (paymentType) => {
    if (parseInt(paymentType) >= 299 && parseInt(paymentType) <= 500)
      return true;
    return false;
  };

  const canKickUser = (tipo) => {
    if (tipo < 3000) return true;
    return false;
  };
  const canBanUser = (tipo) => {
    if (tipo >= 300 && tipo <= 500) return true;
    return false;
  };
  const getColor = (paymentType) => {
    if (parseInt(paymentType) >= 20000 && parseInt(paymentType) < 30000) {
      return 'bg-fuchsia-800';
    } else if (parseInt(paymentType) === -30) {
      return 'bg-blue-500';
    } else if (parseInt(paymentType) === 2) {
      return 'bg-blue-500';
    } else if (parseInt(paymentType) >= 300 && parseInt(paymentType) < 350) {
      return 'bg-orange-500';
    } else if (parseInt(paymentType) >= 350 && parseInt(paymentType) < 500) {
      return 'bg-yellow-500';
    }
    return 'bg-gray-100';
  };

  const kick = (performer_id, socket_id) => {
    SuperviseService.kickUser(performer_id, socket_id)
      .then((msg) => {
        toast.success(intl.formatMessage({ id: 'user_kicked' }));
      })
      .catch((e) => {
        console.error(e);
        toast.error(intl.formatMessage({ id: 'error_kicking_user' }));
      })
      .finally(() => {
        setKickUser(null);
      });
  };

  const ban = (nick) => {
    SuperviseService.banUser(nick)
      .then((msg) => {
        toast.success(intl.formatMessage({ id: 'banned_user' }));
      })
      .catch((e) => {
        console.error(e);
        toast.error(intl.formatMessage({ id: 'error_banning_user' }));
      })
      .finally(() => {
        setBanUser(null);
      });
  };

  if (SPY_USERS_IDENTIFIERS.includes(paymentType)) return null;
  return (
    <div className="flex pt-2 last:pb-2">
      <div
        className={twMerge(
          'flex text-sm gap-1rounded-l-lg p-1 hover:bg-slate-400 cursor-pointer grow items-center w-[95%]',
          paymentType === 30000 && 'bg-gray-100 text-gray-900  font-semibold',
          paymentType <= 20000 && 'bg-gray-500 text-gray-200',
          paymentType >= 20000 &&
            paymentType < 30000 &&
            'bg-slate-500 text-gray-200',
          !isUserWithRegularPrice(paymentType) &&
            isEndUser(paymentType) &&
            'border border-orange-500'
        )}
      >
        <span className="text-xl">{getIcon(paymentType)}</span>
        {/* {isEndUser(paymentType) &&
          (muted && !!muted === true ? (
            <span className="text-xl">
              <IconMuted />
            </span>
          ) : (
            <span className="text-xl">
              <IconUnmuted />
            </span>
          ))} */}

        <span className="pl-1 truncate mr-1">{nick}</span>

        {paymentType >= 350 && paymentType < 500 && (
          <span className="text-xl">
            <IconVip className="text-yellow-500 h-5 w-5" />
          </span>
        )}
        {device === 1 && (
          <span className="text-xl">
            <IconPhone />
          </span>
        )}
        {user.cam && (
          <span className="text-xl">
            <IconWebcam />
          </span>
        )}
        {paymentType < 20000 && (
          <>
            {muted === 0 && (
              <span className="text-xl">
                <IconMuted />
              </span>
            )}
            {muted === 1 && (
              <span className="text-xl">
                <IconUnmuted />
              </span>
            )}
          </>
        )}
        <div className="grow"></div>

        {level === SUPERADMIN_LEVEL && (
          <div>
            <div>
              <IconMore
                className="hover:text-gray-900 cursor-pointer"
                onClick={(e) => {
                  setShowDetail(!showDetail);
                }}
              />
            </div>
            {showDetail && (
              <div
                className="absolute bg-gray-800/80 text-white right-9 p-2 w-32 rounded-md cursor-pointer"
                onClick={(e) => {
                  setShowDetail(!showDetail);
                }}
              >
                <Detail label="IP" value={user.ip} />
                <Detail label="Browser" value={user.browser} />
                <Detail label="OS" value={user.ops} />
                <Detail label="ID user" value={user.id_user} />
                <Detail label="ID clave" value={user.id_clave} />
                <Detail label="Tipo" value={user.tipo} />
                <Detail label="AFNO" value={user.login} />
                {canKickUser(user.tipo) && (
                  <div className="flex text-xl p-1 bg-black rounded-full mt-2">
                    <Tooltip
                      setIsOpen={false}
                      content={intl.formatMessage({ id: 'kick_title_user' })}
                    >
                      <IconCloseRoom
                        className=" bg-black rounded-full cursor-pointer"
                        onClick={(e) => {
                          setKickUser(user);
                        }}
                      />
                    </Tooltip>
                    {canBanUser(user.tipo) && (
                      <Tooltip
                        setIsOpen={false}
                        content={intl.formatMessage({ id: 'ban_user' })}
                      >
                        <IconBan
                          className="ml-1 bg-black rounded-full cursor-pointer"
                          onClick={(e) => {
                            setBanUser(user);
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <Tooltip setIsOpen={false} />
      </div>

      <div className={twMerge('w-1', getColor(paymentType))}></div>
      {kickUser && (
        <ModalAlert
          title={intl.formatMessage({ id: 'kick_title_user' })}
          labelSuccess={intl.formatMessage({ id: 'close' })}
          description={intl.formatMessage(
            {
              id: 'kick_confirmation',
            },
            { nick: user.nick }
          )}
          handleCancel={() => {
            setKickUser(null);
          }}
          handleSuccess={() => {
            kick(performer_id, user.socket);
          }}
        ></ModalAlert>
      )}
      {banUser && (
        <Modal>
          <BanUser
            userData={banUser}
            onClose={() => {
              setBanUser(null);
            }}
          />
        </Modal>
        /*  <ModalAlert
          title={intl.formatMessage({ id: "ban_title_user" })}
          labelSuccess={intl.formatMessage({ id: "close" })}
          description={intl.formatMessage(
            {
              id: "ban_confirmation",
            },
            { nick: banUser }
          )}
          handleCancel={() => {
            setBanUser(null);
          }}
          handleSuccess={() => {
            ban(banUser);
          }}
        ></ModalAlert> */
      )}
    </div>
  );
};

const Detail = ({ label, value }) => {
  return (
    <>
      {value && (
        <div className="text-xs pt-1 pr-4 truncate">
          <span className="font-semibold text-gray-400">{label}</span>:{' '}
          <span>{value}</span>
        </div>
      )}
    </>
  );
};
