import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useRealtime } from './realtime';

import Logger from '../shared/logger';
import { isIOS } from '../shared/environment';
import { getTurnHost } from '../shared/cookies';
import { CALL_STATUS, ROOM_EVENTS } from '../shared/constants';
import { useIntl } from 'react-intl';
const logger = new Logger('UserCamContext');
const UserCam = createContext({});

export const UserCamProvider = ({ children }) => {
  const [turnUser, setTurnUser] = useState(null);
  const [turnPassword, setTurnPassword] = useState(null);
  const [camActive, setCamActive] = useState(false);
  const [remoteStream, setRemoteStream] = useState(null);
  const [readyForCall, setReadyForCall] = useState(false);
  const [readyForAnswer, setReadyForAnswer] = useState(false);
  const localStream = useRef();
  const peerConnection = useRef();
  const { formatMessage } = useIntl();
  const turnHost = process.env.NEXT_PUBLIC_TURN_HOST;
  const iceServers = [
    { urls: 'stun:stun.l.google.com:19302' },
    { urls: 'stun:stun1.l.google.com:19302' },
    { urls: 'stun:stun2.l.google.com:19302' },
    { urls: 'stun:stun3.l.google.com:19302' },
    { urls: 'stun:stun4.l.google.com:19302' },
    {
      urls: `turn:${turnHost}?transport=tcp`,
      username: turnUser,
      credential: turnPassword,
    },
    {
      urls: `turn:${turnHost}?transport=udp`,
      username: turnUser,
      credential: turnPassword,
    },
  ];
  const {
    socket,
    setCalling,
    socketId,
    setCallingId,
    callingId,
    setActiveCall,
    callAccepted,
    setCallStatus,
    setRemoteUserProperty,
    callReceiverId,
    orderCallStart,
    setCallAccepted,
  } = useRealtime();

  useEffect(() => {
    if (!callAccepted) return;
    enableCam();
  }, [callAccepted]);

  useEffect(() => {
    return () => {
      logger.log('Disabling cam on unmount');
      disableCam();
    };
  }, []);

  const streamAudio = true;

  const gotIceCandidate = (event) => {
    logger.log('gotIceCandidate', {
      remoteId: peerConnection.current.remoteId,
      event,
    });
    if (event.candidate === null) return;
    socket.sendwebrtcSignal({
      signal: {
        ice: event.candidate,
      },
      remoteId: peerConnection.current.remoteId,
    });
  };

  const gotRemoteStream = (event) => {
    logger.log('gotRemoteStream', event);
  };

  const errorHandler = (error) => {
    logger.warn('errorHandler', error);
  };

  const createdDescription = (description) => {
    logger.log('createdDescription', description);
    peerConnection.current
      .setLocalDescription(description)
      .then(() => {
        socket.sendwebrtcSignal({
          signal: {
            sdp: peerConnection.current.localDescription,
          },
          remoteId: peerConnection.current.remoteId,
        });
      })
      .catch(errorHandler);
  };

  const setPeerConnection = () => {
    logger.log(`setPeerConnection`);
    peerConnection.current = new RTCPeerConnection({
      iceServers,
    });
    peerConnection.current.onicecandidate = gotIceCandidate;
    peerConnection.current.ontrack = gotRemoteStream;
    peerConnection.current.oniceconnectionstatechange = function (evt) {
      logger.log('oniceconnectionstatechange', evt);
    };
    peerConnection.current.onaddstream = function (evt) {
      logger.log('adding remote stream', evt.stream);

      setRemoteStream(evt.stream);
    };
  };

  const initiateCall = (remoteId) => {
    logger.log('initiateCall', { remoteId, readyForAnswer, readyForCall });
    peerConnection.current.remoteId = remoteId;
    if (!localStream.current) return;
    for (const track of localStream.current.getTracks()) {
      logger.log('adding local stream', track);
      peerConnection.current.addTrack(track, localStream.current);
    }
    if (readyForCall) {
      //Start call
      peerConnection.current
        .createOffer()
        .then(createdDescription)
        .catch(errorHandler);
    }
  };

  const onWSStatus = (data) => {
    switch (data.status) {
      case ROOM_EVENTS.TURN_CREDENTIALS:
        logger.log('socket event received:', ROOM_EVENTS.TURN_CREDENTIALS);
        setTurnUser(data.message.user);
        setTurnPassword(data.message.password);
        setPeerConnection();
        break;

      case ROOM_EVENTS.WEBRTC_SIGNAL:
        logger.log(`socket event received: ${ROOM_EVENTS.WEBRTC_SIGNAL}`, data);
        const signal = data.message;
        if (signal.uuid === signal.socketid) return;

        logger.log(`uuid: ${signal.socketid} - signal.uuid: ${signal.uuid}`);
        if (signal.sdp) {
          logger.log(`SDP: ${signal.sdp.type}`);
          peerConnection.current
            .setRemoteDescription(new RTCSessionDescription(signal.sdp))
            .then(() => {
              logger.log('setRemoteData() sdp');
              if (signal.sdp.type === 'offer') {
                logger.log('createAnswer');
                peerConnection.current
                  .createAnswer()
                  .then(createdDescription)
                  .catch(errorHandler);
              }
            })
            .catch(errorHandler);
        } else if (signal.ice) {
          logger.log('setRemoteData() ice', signal);
          peerConnection.current
            .addIceCandidate(new RTCIceCandidate(signal.ice))
            .catch(errorHandler);
        } else if (signal.call) {
          logger.log('initiateCall inside signal', signal);
          initiateCall(signal.uuid);
        } else {
          logger.log(`unhandled`, signal);
        }
        break;
      case ROOM_EVENTS.CALL_REQUEST:
        logger.log(`socket event received: ${ROOM_EVENTS.CALL_REQUEST}`, data);
        setCalling(data.message.nick);
        setCallingId(data.message.from);
        setCallStatus(CALL_STATUS.PREPARING_CALL);
        break;
      case ROOM_EVENTS.CALL_REJECTED:
        logger.log(`socket event received: ${ROOM_EVENTS.CALL_REJECTED}`, data);
        toast.error(formatMessage({ id: 'call_reject' }), {
          autoClose: true,
        });
        disableCam();
        setActiveCall(false);
        setCalling(false);
        setCallingId(null);
        false;

        break;
      case ROOM_EVENTS.CALL_CANCELLED:
        logger.log(
          `socket event received: ${ROOM_EVENTS.CALL_CANCELLED}`,
          data
        );
        disableCam();
        setActiveCall(false);
        setCalling(false);
        setCallingId(null);
        setCallAccepted(false);
        break;
      case ROOM_EVENTS.CALL_ACCEPTED:
        logger.log(`socket event received: ${ROOM_EVENTS.CALL_ACCEPTED}`, data);
        setCallStatus(CALL_STATUS.ESTABLISING);
        //useEffect that starts call
        setReadyForCall(true);
        break;

      // no-default
    }
  };

  useEffect(() => {
    if (!readyForCall) return;
    logger.log('Starting call useEffect proceed initiateCall', {
      callReceiverId,
      readyForCall,
    });
    initiateCall(callReceiverId);
  }, [callReceiverId, readyForCall]);

  useEffect(() => {
    if (!readyForAnswer) return;
    logger.log('Answer call useEffect proceed to initiateCall', {
      callingId,
      readyForAnswer,
    });
    initiateCall(callingId);
  }, [callingId, readyForAnswer]);

  const enableCam = () => {
    logger.log('enableCam');
    let constraints = {
      video: true,
      audio: streamAudio,
    };
    if (!isIOS()) {
      constraints = {
        video: {
          width: 320,
          height: 240,
        },
        audio: streamAudio,
      };
    }
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        logger.log('getUserMedia', { socketId, callingId, constraints });
        localStream.current = stream;

        socket.emitToServer('setUserCam', {
          cam: true,
          audio: streamAudio,
          call: false,
        });
        // setTimeout(() => {

        setCamActive(true);
        if (callingId) {
          setReadyForAnswer(true);
          orderCallStart(callingId);
        }
        // }, 2000);
      })
      .catch((e) => {
        logger.error('getUserMedia', e);
        localStream.current = null;
        setCamActive(false);
      });
  };

  const disableCam = () => {
    logger.log('disableCam');
    if (socket) {
      socket.emitToServer('setUserCam', {
        cam: false,
        audio: streamAudio,
        call: null,
      });
    }
    if (localStream.current) {
      localStream.current.getVideoTracks()[0].stop();
      if (streamAudio && localStream.current.getAudioTracks().length > 0) {
        localStream.current.getAudioTracks()[0].stop();
      }
    }
    localStream.current = null;
    setReadyForAnswer(false);
    setCamActive(false);
    setCallStatus(null);
    setReadyForCall(false);
    setRemoteUserProperty('call', null);
  };

  useEffect(() => {
    if (!socket) return;
    socket.on('ws_status', onWSStatus);
    return () => {
      socket.off('ws_status', onWSStatus);
    };
  }, [socket]);

  return (
    <UserCam.Provider
      value={{
        localStream,
        remoteStream,
        enableCam,
        disableCam,
        camActive,
        initiateCall,
      }}
    >
      {children}
    </UserCam.Provider>
  );
};

export const useUserCam = () => useContext(UserCam);
