import { useEffect, useState } from 'react';
import { useMonitor } from '../../contexts/monitor';
import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import Tooltip from '../Tooltip';

import IconTranslate from '../../public/assets/images/icons/world.svg';

const RoomChat = () => {
  const { roomMessages, fullscreen } = useMonitor();

  return (
    <div
      className={twMerge(
        'flex flex-col-reverse overflow-auto h-full',
        fullscreen &&
          'bg-transparent xs:hidden w-1/3 rounded-lg absolute z-50 h-5/6 bottom-12 right-2'
      )}
    >
      {roomMessages &&
        [...roomMessages].reverse().map((message, index) => {
          if (message.messageType === 'text') {
            return (
              <RoomTextMessage
                key={`${
                  message.messageType
                }_${new Date().getTime()}${new Date().getMilliseconds()}${index}`}
                message={message}
                messageWithOpacity={fullscreen}
              />
            );
          } else {
            return (
              <RoomTipMessage
                key={index}
                nick={message.nick}
                text={message.text}
                type={message.messageType}
              />
            );
          }
        })}
    </div>
  );
};
const RoomTextMessage = ({ message, messageWithOpacity = false }) => {
  const { formatMessage: t } = useIntl();
  const { nick, to, text, translated } = message;
  const { roomUsers, roomData } = useMonitor();
  const [personalMessage, setPersonalMessage] = useState(null);
  const [showTranslated, setShowTranslated] = useState(true);

  const isMessageFromPerformer = roomData.nick === nick;

  useEffect(() => {
    if (!to || to === -1 || to === 0) {
      setPersonalMessage(null);
      return;
    }
    if (!roomUsers) return;
    const us = roomUsers.find((user) => user.id === to);
    if (us) setPersonalMessage(us.nick);
  }, [to, roomUsers]);

  return (
    <div
      className={twMerge(
        'relative mx-[10px] max-w-[90%]',
        'before:content[""] before:w-0 before:h-0 before:block before:absolute before:border-solid',
        'before:top-0',
        isMessageFromPerformer && ['place-self-start'],
        !isMessageFromPerformer && ['place-self-end']
      )}
    >
      <div
        className={twMerge(
          `p-2 rounded-md w-fit mb-2 text-[#757779]`,
          isMessageFromPerformer && 'bg-white rounded-tr-none',
          !isMessageFromPerformer && 'bg-[#eef2f4] rounded-tl-none',
          messageWithOpacity &&
            isMessageFromPerformer &&
            'bg-slate-300/80 text-black  border border-black text-[10px]',
          messageWithOpacity &&
            !isMessageFromPerformer &&
            'bg-slate-800/80 text-white border border-white text-lg'
        )}
      >
        <div className="text-sm w-full">
          <span className="font-bold">{nick}</span>
          {personalMessage && (
            <span>
              {' '}
              a <span className="underline">{personalMessage}</span>
            </span>
          )}
        </div>
        <div className="text-xs flex items-center gap-2">
          <div>
            {showTranslated
              ? `${text}`
              : `(${message.original_lang}) ${message.Original_text}`}
          </div>

          <button
            onClick={() => {
              setShowTranslated((current) => !current);
            }}
            className={twMerge(translated === 0 && 'hidden')}
          >
            <Tooltip
              content={
                showTranslated
                  ? t({ id: 'chat_show_original_message' })
                  : t({ id: 'chat_show_message_translation' })
              }
            >
              <IconTranslate className="w-4 h-4 cursor-pointer text-violet-700" />
            </Tooltip>
          </button>
        </div>
      </div>
    </div>
  );
};

const RoomTipMessage = ({ nick, text, type }) => {
  const tipType = type === 'credits' ? 'propina' : 'vibración';
  return (
    <div
      className={classNames(
        'relative',
        'mx-[10px]',
        'before:content[""] before:w-0 before:h-0 before:block before:absolute before:border-solid',
        'before:top-0'
      )}
    >
      <div className="p-2 mb-2 text-gray-800 bg-teal-100">
        <div className="text-xs px-1">
          <span className="text-sm font-bold">{nick}</span> ha enviado una{' '}
          <span className="font-bold">{tipType}</span> de{' '}
          <span className="font-bold">{text} créditos</span>
        </div>
      </div>
    </div>
  );
};
export default RoomChat;
