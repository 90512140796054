import request from './api';

function deleteGallery(idGallery, idwebcammer, reason = null) {
  return request({
    url: `/photos/gallery/${idGallery}`,
    method: 'DELETE',
    params: {
      idwebcammer,
      reason,
    },
  });
}

function editGallery({
  idGallery = 0,
  name = '',
  free = 1,
  cost = 0,
  idwebcammer = 0,
}) {
  return request({
    url: `/photos/gallery?idwebcammer=${idwebcammer}&gallery_id=${idGallery}&galleryData[name]=${name}&galleryData[free]=${free}&galleryData[cost]=${cost}`,
    method: 'PUT',
    params: {},
  });
}

function getGalleries(idWebcammer) {
  return request({
    url: `/photos/galleries`,
    method: 'GET',
    params: {
      idwebcammer: idWebcammer,
    },
  });
}

function getThumbsGallery(idGallery) {
  return request({
    url: `/photos/gallery/thumbs`,
    method: 'GET',
    params: {
      gallery_id: idGallery,
    },
  });
}

function deleteThumb(thumbId) {
  return request({
    url: `/photos/${thumbId}`,
    method: 'DELETE',
    params: {},
  });
}

function setProfilePhoto(thumbId, idwebcammer) {
  return request({
    url: `/photos/profile`,
    method: 'PUT',
    params: {
      thumb_id: thumbId,
      idwebcammer,
    },
  });
}

function getProfilePhoto(idwebcammer) {
  return request({
    url: `/photos/profile`,
    method: 'GET',
    params: {
      idwebcammer: idwebcammer,
    },
  });
}

function getMainGallery(idwebcammer) {
  return request({
    url: `/photos/gallery/main`,
    method: 'GET',
    params: {
      idwebcammer: idwebcammer,
    },
  });
}

function getGalleryData(idgallery, idwebcammer) {
  return request({
    url: `/photos/gallery`,
    method: 'GET',
    params: {
      idgallery,
      idwebcammer,
    },
  });
}

function addGallery({ name = '', cost = 0, free = 1, idwebcammer = 0 }) {
  return request({
    url: `/photos/gallery`,
    method: 'POST',
    params: {
      idwebcammer,
      name,
      free,
      cost,
    },
  });
}

function addThumbToGallery({
  idwebcammer = 0,
  gallery_id = 0,
  photo = '',
  num_imgs = 0,
  current_img = 0,
  onUploadProgress = null,
}) {
  var bodyFormData = new FormData();
  bodyFormData.append('idwebcammer', idwebcammer);
  bodyFormData.append('gallery_id', gallery_id);
  bodyFormData.append('photo', photo);
  bodyFormData.append('num_imgs', num_imgs);
  bodyFormData.append('current_img', current_img);

  return request({
    url: `/photos`,
    method: 'POST',
    data: bodyFormData,
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: onUploadProgress,
  });
}

function validateThumb(thumb_id, isValid, allow_profile, reason = null) {
  return request({
    url: `/photos/validate`,
    method: 'PUT',
    params: {
      thumb_id,
      status: isValid ? 1 : 2,
      allow_profile: allow_profile === true ? 1 : 0,
      reason,
    },
  });
}

function validateGallery(gallery_id) {
  return request({
    url: `/photos/gallery/validate`,
    method: 'PUT',
    params: {
      gallery_id,
    },
  });
}

function allowThumbAsProfilePhoto(thumb_id, allow = 0) {
  return request({
    url: `/photos/allowasprofile`,
    method: 'PUT',
    params: {
      thumb_id,
      allow,
    },
  });
}

function rotateThumb(idwebcammer, src) {
  return request({
    url: `/photos/rotate`,
    method: 'POST',
    params: {
      idwebcammer,
      src,
      degrees: 90,
    },
  });
}

function doMosaic(idwebcammer, gallery_id) {
  return request({
    url: `/photos/mosaic`,
    method: 'POST',
    params: {
      idwebcammer,
      gallery_id,
    },
  });
}

function uploadUserAvatar({ avatar = '', onUploadProgress = null }) {
  var bodyFormData = new FormData();
  bodyFormData.append('avatar', avatar);

  return request({
    url: `/avatar`,
    method: 'POST',
    data: bodyFormData,
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: onUploadProgress,
  });
}

function getUserAvatar() {
  return request({
    url: `/avatar`,
    method: 'GET',
  });
}

const PhotosService = {
  getGalleries,
  editGallery,
  addGallery,
  getThumbsGallery,
  getProfilePhoto,
  setProfilePhoto,
  deleteThumb,
  deleteGallery,
  getGalleryData,
  getMainGallery,
  addThumbToGallery,
  validateThumb,
  validateGallery,
  allowThumbAsProfilePhoto,
  rotateThumb,
  doMosaic,
  uploadUserAvatar,
  getUserAvatar,
};

export default PhotosService;
