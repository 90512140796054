import { useDispatch, useSelector } from 'react-redux';

import { setSidebarOpen, setSidebarDesktopOpen } from '../store/slices/ui';

const useSideBar = () => {
  const dispatch = useDispatch();

  const { sidebarIsOpen, sidebarDesktopIsOpen } = useSelector(
    (state) => state.ui
  );

  const togleMenu = (device = 'mobile') => {
    if (device === 'mobile') dispatch(setSidebarOpen(!sidebarIsOpen));
    if (device !== 'mobile')
      dispatch(setSidebarDesktopOpen(!sidebarDesktopIsOpen));
  };

  const getSidebarClassnames = () => {
    if (!sidebarIsOpen && !sidebarDesktopIsOpen) return 'hidden';
    if (!sidebarIsOpen && sidebarDesktopIsOpen) return 'hidden sm:block';
    if (sidebarIsOpen && !sidebarDesktopIsOpen) return 'absolute sm:hidden';
    if (sidebarIsOpen && sidebarDesktopIsOpen) return 'absolute sm:block';
  };

  return {
    togleMenu,
    getSidebarClassnames,
    sidebarDesktopIsOpen,
    sidebarIsOpen,
  };
};

export default useSideBar;
