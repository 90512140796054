import { createContext, useContext, useEffect, useState } from 'react';
import { useSocket } from '../hooks/useSocket';

import Logger from '../shared/logger';
import { getRealTimeHost } from '../shared/cookies';
import {
  MESSAGE_TYPES,
  CALL_STATUS,
  APP_TIMEZONE,
  USER_WARNING_SECONDS_IN_ROOM,
} from '../shared/constants';

import { useDisconnection } from '../hooks/useDisconnection';
import useDateTime from '../hooks/useDateTime';

import { useAuth } from './auth';
import { useNotifications } from './notifications';

const logger = new Logger('RealTimeContext');
const RealTimeContext = createContext({});

export const RealTimeProvider = ({ children }) => {
  const dateTime = useDateTime();
  const [preparedForRealtime, setPreparedForRealtime] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); //selected user
  const [disconnectionState, disconnectionDispatch] = useDisconnection();
  const [displayUsers, setDisplayUsers] = useState(true); //display users component
  const [displayChats, setDisplayChats] = useState(false); //display chats component
  const [chats, setChats] = useState([]); //active chats
  const [messages, setMessages] = useState([]); //message chats
  const [activeCall, setActiveCall] = useState(false); //active call TBR

  const [calling, setCalling] = useState(false); //show/hide CallRequest component
  const [callingId, setCallingId] = useState(false); //remote caller id
  const [callAccepted, setCallAccepted] = useState(false); //call accepted, for start receiver cam
  const [callReceiverId, setCallReceiverId] = useState(null);
  const [callStatus, setCallStatus] = useState(false); //remote caller id
  const [remoteNick, setRemoteNick] = useState(null);
  const [usersRoomMonitoringWarn, setUsersRoomMonitoringWarn] = useState([]);

  const { userId, userName: nick, token, userAvatar, level } = useAuth();
  const { browserNotification } = useNotifications();

  const [userCounter, setUserCounter] = useState(null); //user counter for online room list and monitoring
  const [rMode, setRMode] = useState(null); //room mode for online room list and monitoring
  const [roomPaused, setRoomPaused] = useState(null); //room paused for online room list and monitoring
  const {
    socket,
    socketId,
    connected: socketConnected,
    connecting: socketConnecting,
    disconnect: socketDisconnect,
    roster,
    affectedUser,
  } = useSocket({
    prepared: preparedForRealtime,
    userData: { nick, userId, token, userAvatar, level },
    instance:
      getRealTimeHost() === 'PROD'
        ? process.env.NEXT_PUBLIC_REALTIME_HOST
        : getRealTimeHost(),
    roomName: 'lobby',
    disconnectionDispatch,
    setMessages,
    browserNotification,
    setUserCounter,
    setRMode,
    setRoomPaused,
  });

  //this cancels a call request
  useEffect(() => {
    if (!selectedUser) return;
    if (!affectedUser) return;
    if (affectedUser.action === 'add') return;
    if (affectedUser.who.id === selectedUser) {
      setSelectedUser(null);
    }
  }, [affectedUser, selectedUser]);

  //detect user leaving
  useEffect(() => {
    if (!roster) return;
    logger.log('roster changed', roster);
    if (roster.action === 'remove') {
      if (roster.who.id === selectedUser) setSelectedUser(null);
      if (roster.who.id === callingId || roster.who.id === callReceiverId) {
        hangCall(roster.who.id);
        setCallStatus(CALL_STATUS.CALL_ENDED);
        return;
      }

      setCallStatus(null);
    }
  }, [roster]);

  useEffect(() => {
    if (!nick) return;
    setPreparedForRealtime(true);
    return () => {
      setPreparedForRealtime(false);
      socketDisconnect();
    };
  }, [nick, setPreparedForRealtime]);

  useEffect(() => {
    if (!roster) return;
    if (!roster.users) return;
    checkUsersTimeInRoom();
  }, [roster.users]);

  const sendMessage = (text, tabId) => {
    logger.log('sendMessage', { tabId, nick, text, selectedUser, userId });
    socket.sendChat({
      nick: nick,
      msg: text,
      userId: userId,
      tabId: tabId,
      messageType: MESSAGE_TYPES.TEXT,
      to: selectedUser,
    });
  };

  const startCall = (to) => {
    if (callStatus === CALL_STATUS.PREPARING_CALL) return;
    logger.log('startCall', { to });
    socket.emitToServer('startCall', {
      cam: true,
      mic: true,
      to: to,
      nick: nick,
    });
    setCallStatus(CALL_STATUS.CALLING);
    setActiveCall(true);
  };

  const rejectCall = (callingId) => {
    socket.emitToServer('rejectCall', { callingId: callingId });
    setCalling(false);
    setCallingId(null);
  };

  const acceptCall = (callingId) => {
    logger.log('acceptCall', callingId);
    setCallAccepted(true);
    setCalling(false);
    setCallStatus(CALL_STATUS.ESTABLISING);
  };

  const orderCallStart = (callingId) => {
    logger.log('orderCallStart', callingId);
    socket.emitToServer('acceptCall', { callingId: callingId });
  };

  const hangCall = (remoteId) => {
    logger.log('hangCall', remoteId ? remoteId : callingId);
    setActiveCall(false);
    setCalling(false); //used for display RequestCall component
    setCallStatus(null);
    setCallingId(null);
    socket.emitToServer('cancelCall', {
      callingId: remoteId ? remoteId : callingId,
    });
  };

  const setUserCallStatusForRemote = (status) => {
    logger.log('setUserCallStatus', status);
    if (!socket) return;
    socket.emitToServer('userCallStatus', { status });
  };

  const setRemoteUserProperty = (property, value) => {
    logger.log('setRemoteUserProperty', { property, value });
    if (!socket) return;
    socket.emitToServer('setUserProperty', { property, value });
  };

  const timeInRoom = (milliseconds) => {
    const utcDate = new Date(new Date().toUTCString());
    const totalSeconds = parseInt((utcDate.getTime() - milliseconds) / 1000);
    const humanReadableFormat = dateTime.secondsToHumanReadable(totalSeconds);
    return { totalSeconds, humanReadableFormat };
  };

  const checkUsersTimeInRoom = () => {
    if (!roster.users) return false;
    let usersList = [];

    roster.users.forEach((user) => {
      if (user.monitoringTimestamp <= 0) return;
      const totalTimeInRoom = timeInRoom(user.monitoringTimestamp);
      if (
        totalTimeInRoom?.totalSeconds &&
        totalTimeInRoom.totalSeconds > USER_WARNING_SECONDS_IN_ROOM
      )
        usersList.push(user.id);
    });

    setUsersRoomMonitoringWarn(usersList);
  };

  return (
    <RealTimeContext.Provider
      value={{
        socketConnected,
        socketConnecting,
        roster,
        socketId,
        selectedUser,
        setSelectedUser,
        displayUsers,
        setDisplayUsers,
        chats,
        setChats,
        displayChats,
        setDisplayChats,
        messages,
        setMessages,
        sendMessage,
        socket,
        activeCall,
        setActiveCall,
        startCall,
        calling,
        setCalling,
        rejectCall,
        acceptCall,
        hangCall,
        callingId,
        setCallingId,
        callAccepted,
        setCallAccepted,
        callStatus,
        setCallStatus,
        callReceiverId,
        setCallReceiverId,
        remoteNick,
        setRemoteNick,
        setUserCallStatusForRemote,
        orderCallStart,
        setRemoteUserProperty,
        userCounter,
        setUserCounter,
        rMode,
        setRMode,
        timeInRoom,
        usersRoomMonitoringWarn,
        checkUsersTimeInRoom,
        roomPaused,
        setRoomPaused,
      }}
    >
      {children}
    </RealTimeContext.Provider>
  );
};

export const useRealtime = () => useContext(RealTimeContext);
