import { configureStore } from '@reduxjs/toolkit';

import authReducer from './slices/auth';
import debugReducer from './slices/debug';
import modalsReducer from './slices/modals';
import uiReducer from './slices/ui';
import messagesReducer from './slices/messages';

const store = configureStore({
  reducer: {
    auth: authReducer,
    debug: debugReducer,
    modals: modalsReducer,
    ui: uiReducer,
    messages: messagesReducer,
  },
});

export default store;
