import Link from 'next/link';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { setLanguage } from '../shared/cookies';
import packageJson from '../package.json';

const Footer = () => {
  const { locale, asPath } = useRouter();
  const { formatMessage: t } = useIntl();

  return (
    <footer className="flex flex-col md:flex-row justify-between items-center text-sm p-3 bg-gray-800 text-gray-200 font-light gp-3 border-t-2 border-cyan-200">
      <span className="text-xs text-center sm:text-left">
        © Copyright 2022 PromocionesWeb 2016, S.L. All pictures © their
        respective owners. v{packageJson.version}
      </span>
      <ul className="flex flex-col mt-3 items-center text-xs sm:flex-row sm:mt-0 sm:mx-1 sm:flex-wrap sm:space-x-2">
        <li>
          <Link href="#" className="mr-4 hover:underline p-2">
            Legal
          </Link>
        </li>
        <li>
          <Link href="#" className="mr-4 hover:underline p-2" legacyBehavior>
            {t({ id: 'privacy_policy' })}
          </Link>
        </li>
        {locale === 'en' && (
          <li>
            <Link
              href={asPath}
              locale="es"
              onClick={() => {
                setLanguage('es');
              }}
            >
              Español
            </Link>
          </li>
        )}
        {locale === 'es' && (
          <li>
            <Link
              href={asPath}
              locale="en"
              onClick={() => {
                setLanguage('en');
              }}
            >
              English
            </Link>
          </li>
        )}
      </ul>
    </footer>
  );
};

export default Footer;
