export const isSSR = () => typeof window === 'undefined';

export const isAndroid = (userAgentParam) => {
  const userAgent = userAgentParam || (!isSSR() && window.navigator.userAgent);
  return typeof userAgent === 'string' && /(Android)/i.test(userAgent);
};

export const isIOS = () => {
  const platform = navigator?.userAgent || navigator?.platform || 'unknown';
  return /iPhone|iPod|iPad/.test(platform);
};

export const isIpad = (userAgentParam) => {
  const userAgent = userAgentParam || (!isSSR() && window.navigator.userAgent);
  return typeof userAgent === 'string' && /(iPad)/i.test(userAgent);
};

export const isCrawler = (userAgentParam) => {
  const userAgent = userAgentParam || (!isSSR() && window.navigator.userAgent);
  return /bot|googlebot|crawler|spider|robot|crawling|lighthouse|prerender/i.test(
    userAgent
  );
};
