import { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import { useAuth } from './auth';
import MonitorService from '../shared/monitor-service';
import StreamingProfilesService from '../shared/streaming-profiles';
import SuperviseService from '../shared/supervise-service';
import Logger from '../shared/logger';
import { MESSAGE_TYPES, ROOM_MODE } from '../shared/constants';
import { getPlayerVolume, setPlayerVolume } from '../shared/cookies';

import { useSocketRoom } from '../hooks/useSocketRoom';
import { useDisconnection } from '../hooks/useDisconnection';
import { useRealtime } from './realtime';

const logger = new Logger('MonitorContext');

const MonitorContext = createContext({});

export const MonitorProvider = ({ children }) => {
  const router = useRouter();
  const [prepared, setPrepared] = useState(false);
  const [preparing, setPreparing] = useState(false);
  const [userData, setUserData] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [modelId, setModelId] = useState(null);
  const [nextPerformer, setNextPerformer] = useState(null);
  const [prevPerformer, setPrevPerformer] = useState(null);
  const [manifest, setManifest] = useState(null);
  const [instance, setInstance] = useState(null);
  const [roomName, setRoomName] = useState(null);
  const [roomMode, setRoomMode] = useState(null);
  const [roomData, setRoomData] = useState(null);
  const [roomMessages, setRoomMessages] = useState([]); //message chats
  const [roomUsers, setRoomUsers] = useState([]); //room users
  const [roomUserCounter, setRoomUserCounter] = useState(0);
  const [paymentType, setPaymentType] = useState(20010);
  const [streamingProfiles, setStreamingProfiles] = useState(20010);
  const [monitorFloating, setMonitorFloating] = useState(true);
  const [fullscreen, setFullscreen] = useState(false);
  const [canMonitorRoom, setCanMonitorRoom] = useState(false);
  const [screenShot, setScreenShot] = useState(null);
  const [volume, setVolume] = useState(getPlayerVolume());

  //kick room/forceCloseRoom
  const [kickRoom, setKickRoom] = useState(null);
  const auth = useAuth();
  const { socketConnected: wsConnected } = useRealtime();
  const { formatMessage: t } = useIntl();

  const [disconnectionState, disconnectionDispatch] = useDisconnection();

  useEffect(() => {
    if (auth.isAuthLoading || !auth.isAuthenticated) return;
    StreamingProfilesService.getStreamingProfiles()
      .then((data) => {
        setStreamingProfiles(data);
      })
      .catch((e) => logger.error('getStreamingProfiles', e));
  }, [auth]);

  useEffect(() => {
    //disable rooms navigation when isn't index
    if (router.asPath !== '/') {
      setNextPerformer(null);
      setPrevPerformer(null);
      setMonitorFloating(true);
    }
  }, [router.asPath]);

  const getStreamingProfileData = (profile_id) => {
    logger.log('getStreamingProfileData', { profile_id, streamingProfiles });
  };

  const {
    socketRoom,
    socketIdRoom,
    connected: socketConnected,
    connecting: socketConnecting,
    disconnect: socketDisconnect,
    performerSocketId,
  } = useSocketRoom({
    prepared,
    userData,
    accessToken,
    instance,
    roomName,
    paymentType,
    disconnectionDispatch,
    setRoomMessages,
    setRoomUsers,
    setRoomUserCounter,
  });

  const closeRoom = () => {
    logger.log('closeRoom: Closing socket and resetting room states');
    socketDisconnect();
    setPrepared(false);
    setPreparing(false);
    setUserData(null);
    setAccessToken(null);
    setModelId(null);
    setManifest(null);
    setInstance(null);
    setRoomName(null);
    setRoomMode(null);
    setPaymentType(null);
    setRoomData(null);
  };

  const prepareRoom = async ({
    modelId,
    instance,
    roomName,
    roomMode,
    manifest,
    paymentType,
  }) => {
    setInstance(instance);

    setModelId(modelId);
    setManifest(manifest);
    setRoomName(roomName);
    setRoomMode(roomMode);
    if (!roomMode) return;
    if (roomMode === ROOM_MODE.OFFLINE) return;

    logger.log('prepareRoom');

    setPreparing(true);
    if (accessToken) {
      logger.log('prepareRoom delete previous accessToken', accessToken);
      const { dataDelete, errorDelete } =
        await MonitorService.deleteAccessToken({
          accessToken,
        });
    }

    const { data, error } = await MonitorService.getAccessToken({
      modelId,
      roomName,
      nick: auth.userName,
      tipo: paymentType,
    });

    if (error) {
      logger.error(`prepareRoom failed, ${error.message}`);
      setPreparing(false);
      setPrepared(false);
      return;
    }

    logger.log(`prepareRoom connection result`, data);
    setAccessToken(data.accessToken);
    setUserData(data);
    setPreparing(false);
    setPrepared(true);
  };

  const sendDisconnectReason = (reason) => {
    if (!socketRoom) return;
    logger.log('sendDisconnectReason');
    const data = {
      roomName: roomName,
      reason: reason,
    };
    logger.log('sending disconnect reason', data);
    socketRoom.emitToServer('userDisconnectReason', data);
  };

  const sendMessage = (text) => {
    if (!socketRoom) return;
    logger.log('sendMessage', { nick: auth.userName, text });
    socketRoom.sendChat({
      nick: auth.userName,
      msg: text,
      messageType: MESSAGE_TYPES.TEXT,
      to: performerSocketId,
    });
  };

  const forceCloseRoom = (id) => {
    SuperviseService.kickRoom(id)
      .then((msg) => {
        toast.success(t({ id: 'room_closed' }));
      })
      .catch((e) => {
        console.error(e);
        toast.error(t({ id: 'room_closed_error' }));
      })
      .finally(() => {
        setKickRoom(null);
      });
  };
  const displayMonitor = (performer) => {
    closeRoom();
    setPaymentType(20010);
    setRoomMessages([]); //empty chat
    setRoomUsers(null); //empty user
    setRoomData(performer);
    setModelId(performer.id);
  };

  useEffect(() => {
    if (!auth.monitorAllowed) return;
    if (!wsConnected) setCanMonitorRoom(false);
    if (wsConnected && auth.monitorAllowed) setCanMonitorRoom(true);
  }, [wsConnected, canMonitorRoom, auth.monitorAllowed]);

  useEffect(() => {
    setPlayerVolume(volume);
  }, [volume]);

  return (
    <MonitorContext.Provider
      value={{
        prepareRoom,
        closeRoom,
        prepared,
        preparing,
        accessToken,
        socketConnected,
        socketConnecting,
        manifest,
        roomMode,
        modelId,
        setModelId,
        roomName,
        sendDisconnectReason,
        disconnectionState,
        disconnectionDispatch,
        performerSocketId,
        roomData,
        setRoomData,
        socketRoom,
        socketIdRoom,
        roomMessages,
        setRoomMessages,
        roomUsers,
        setRoomUsers,
        setRoomUserCounter,
        roomUserCounter,
        paymentType,
        setPaymentType,
        getStreamingProfileData,
        sendMessage,
        forceCloseRoom,
        kickRoom,
        setKickRoom,
        prevPerformer,
        setPrevPerformer,
        nextPerformer,
        setNextPerformer,
        displayMonitor,
        monitorFloating,
        setMonitorFloating,
        fullscreen,
        setFullscreen,
        canMonitorRoom,
        volume,
        setVolume,
        getPlayerVolume,
        setPlayerVolume,
        screenShot,
        setScreenShot,
      }}
    >
      {children}
    </MonitorContext.Provider>
  );
};

export const useMonitor = () => useContext(MonitorContext);
