import { memo } from 'react';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useMonitor } from '../../contexts/monitor';
import Logger from '../../shared/logger';

import ButtonPanelReport from './Controls/ButtonPanelReport.js';
import ButtonCloseRoom from './Controls/ButtonCloseRoom.js';
import ButtonAlarm from './Controls/ButtonAlarm.js';
import LinkOCProfile from './Controls/LinkOCProfile.js';
import LinkAnonymousProfile from './Controls/LinkAnonymousProfile.js';
import ButtonModerateRoom from './Controls/ButtonModerateRoom.js';
import ButtonCloseMonitor from './Controls/ButtonCloseMonitor.js';
import ChatInputMessage from './Controls/ChatInputMessage.js';
import ButtonsChangeRoom from './Controls/ButtonsChangeRoom.js';

const logger = new Logger('MonitorFooter');
const MonitorFooter = ({ performer, roomOffline, containerRef = null }) => {
  const { paymentType } = useMonitor();

  return (
    <div className="grid grid-cols-6 p-1 items-center justify-end">
      <div className="flex col-start-1 col-end-4 gap-2 text-2xl pr-2 items-center">
        {!roomOffline && (
          <>
            <ButtonCloseRoom performer={performer} />
            <ButtonAlarm performer={performer} />
          </>
        )}
        <ButtonPanelReport containerRef={containerRef} />
        <LinkOCProfile nick={performer.nick} />
        <LinkAnonymousProfile nick={performer.nick} />
        <div className="grow" />
        {!roomOffline && <ButtonModerateRoom performer={performer} />}
      </div>
      <ChatInputMessage />

      <div
        className={twMerge(
          'cursor-pointer',
          paymentType !== 25010 && 'col-span-3'
        )}
      >
        <div className="flex gap-2 justify-end">
          <ButtonsChangeRoom />
          <ButtonCloseMonitor />
        </div>
      </div>
    </div>
  );
};
export default memo(MonitorFooter);
