import { useState } from 'react';

import { useAuth } from '../../contexts/auth';

import AvatarImage from './AvatarImage';
import AvatarDetail from './AvatarDetail';

const Avatar = ({ editable = true, width = 100, showUserStatus = false }) => {
  const { getUserAvatar } = useAuth();

  const [avatarDetails, setAvatarDetails] = useState(false);

  return (
    <>
      <AvatarImage
        width={width}
        onAvatarClick={() => setAvatarDetails(true)}
        editable={editable}
        showUSerStatus={showUserStatus}
      />

      {avatarDetails && (
        <AvatarDetail
          onClose={() => setAvatarDetails(false)}
          onAvatarChanged={getUserAvatar}
        />
      )}
    </>
  );
};

export default Avatar;
