import { createSlice } from '@reduxjs/toolkit';

import { setApiEnvironmentInClient } from '../../shared/api';

const initialState = {
  apiEnv: 'dev',
  stagingWs: false,
  devbugBarPosition: 'down',
};

export const debugSlice = createSlice({
  name: 'debugReducer',
  initialState,
  reducers: {
    setApiEnv: (state, action) => {
      state.apiEnv = action.payload;
      setApiEnvironmentInClient(action.payload);
    },
    setStagingWs: (state, action) => {
      state.stagingWs = action.payload;
    },
    setDevbugBarPosition: (state, action) => {
      state.devbugBarPosition = action.payload;
    },
    unsetDebug: () => initialState,
  },
});

export const { setApiEnv, unsetDebug } = debugSlice.actions;

export default debugSlice.reducer;
