import { useCallback, useEffect, useState } from 'react';
import Logger from '../shared/logger';
import useSounds from '../hooks/useSounds';
import { ROOM_EVENTS, SUPERADMIN_LEVEL } from '../shared/constants';
import {
  MESSAGE_TYPES,
  BROWSER_NOTIFICATIONS_TYPES,
} from '../shared/constants';
import { useAuth } from '../contexts/auth';

const logger = new Logger('useSocket');

const useSocket = ({
  prepared,
  userData,
  instance,
  roomName,
  disconnectionDispatch,
  setMessages,
  browserNotification,
  setUserCounter,
  setRMode,
  setRoomPaused,
}) => {
  const { level: userLevel, loadingAvatar } = useAuth();
  const [socketId, setSocketId] = useState(null); //Socket ID
  const [socket, setSocket] = useState(null); // Socket instance
  const [roster, setRoster] = useState([]);
  const [affectedUser, setAffectUser] = useState(null);
  const [connected, setConnected] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const { playNewUser, playIncomingChat, playIncomingCall, stopIncomingCall } =
    useSounds();
  // Socket Invertred events:
  const onWSStatus = useCallback(
    (data) => {
      //logger.log("socket event received:", data);
      switch (data.status) {
        case 'connected':
          // WS connected, waiting for connection_accepted
          break;
        case 'connection_accepted':
          setConnected(true);
          setConnecting(false);
          setSocketId(data.message.socket);
          disconnectionDispatch({ type: 'socketConnected' });
          socket.emitToServer('getTurnCredentials', {});

          // ToDo: Credits interval on
          break;
        case 'connection_failed':
          if (data.message.toLowerCase() === ROOM_EVENTS.ROOM_CLOSED) {
            disconnectionDispatch({
              type: 'socketDisconnected',
              payload: { reason: ROOM_EVENTS.ROOM_CLOSED, reconnecting: false },
            });

            //ToDo
            /*
          - show message on chat "Room disconnect" - see context Chat
          - wait 5 seconds to recovery in case is a connection problem from perfomer and redirect to menu
          */
          } else if (data.message.toLowerCase() === ROOM_EVENTS.USER_KICKED) {
            disconnectionDispatch({
              type: 'socketDisconnected',
              payload: {
                reason: ROOM_EVENTS.USER_KICKED,
                reconnecting: false,
              },
            });
            logout();
          } else if (
            data.message.toLowerCase() === ROOM_EVENTS.FORBIDDEN ||
            data.message.toLowerCase() === ROOM_EVENTS.USER_EXISTS
          ) {
            disconnectionDispatch({
              type: 'socketDisconnected',
              payload: {
                reason: ROOM_EVENTS.FORBIDDEN,
                reconnecting: false,
              },
            });
            logger.log('socket auth failed', data);
            //ToDo shall we logout user????
          }
        case 'disconnect':
          setConnected(false);
          setConnecting(true);
          if (data.message.toLowerCase() === ROOM_EVENTS.CONNECTION_LOST) {
            disconnectionDispatch({
              type: 'socketDisconnected',
              payload: {
                reason: ROOM_EVENTS.CONNECTION_LOST,
                reconnecting: false,
              },
            });
          }
          break;
        case 'reconnect_failed':
          setConnected(false);
          setConnecting(false);
          socket.close(); // ToDo: Confirm
          break;
        case 'reconnect':
          setConnected(false);
          setConnecting(true);
          break;
        case ROOM_EVENTS.ROSTER:
          if (data.message.action === 'add') playNewUser();
          if (
            data.message.action === 'remove' ||
            data.message.action === 'update'
          ) {
          }
          setAffectUser({ who: data.message.who, action: data.message.action });
          setRoster(data.message);
          break;
        case ROOM_EVENTS.CHAT:
          if (!Object.values(MESSAGE_TYPES).includes(data.message.messageType))
            return;
          playIncomingChat();
          setMessages((currentMessages) => {
            return [
              ...currentMessages,
              {
                nick: data.message.nick,
                text: data.message.msg,
                userId: data.message.userId,
                id: data.message.userId,
                messageType: data.message.messageType,
              },
            ];
          });
          break;
        case ROOM_EVENTS.CALL_REQUEST:
          playIncomingCall();
          break;
        case ROOM_EVENTS.CALL_REJECTED:
        case ROOM_EVENTS.CALL_CANCELLED:
        case ROOM_EVENTS.CALL_ACCEPTED:
        case ROOM_EVENTS.WEBRTC_SIGNAL:
          stopIncomingCall();
          break;
        case ROOM_EVENTS.CHAT_ALERT:
          if (!data.message.msg) return;
          browserNotification({
            nick: data.message.msg.nick,
            id: data.message.id_emisor,
            forbiddenText: data.message.msg.msg,
            //not in use ATM, but don't remove just in case
            //to: data.message.to,
            // roomName:data.message.roomName,
            //socketId: data.message.socket
            type: BROWSER_NOTIFICATIONS_TYPES.FORBIDDEN_WORD,
          });
          break;
        case ROOM_EVENTS.ROOM_UPDATE:
          switch (data.message.key) {
            case 'freeusers':
            case 'payingusers':
              setUserCounter(data.message);
              break;
            case 'paused':
              setRoomPaused({
                id: data.message.id,
                paused: data.message.value,
              });
              break;
            case 'roomMode':
              if (data.message.value.toLowerCase() === 'group chat')
                data.message.value = 'private';
              setRMode(data.message);
              break;
            // no-default
          }
        case ROOM_EVENTS.REPORT_RECEIVED:
          if (!data.message.id_emisor) return;
          browserNotification({
            nick: data.message.nick,
            id: data.message.id_emisor,
            subject: data.message.subject,
            type: BROWSER_NOTIFICATIONS_TYPES.REPORT_RECEIVED,
          });
          break;

          break;
        // no-default
      }
    },
    [disconnectionDispatch, socket]
  );

  const disconnect = () => {
    logger.log('disconnect');
    if (socket) socket.close();
    setSocket(null);
    setSocketId(null);
    setConnecting(false);
    setConnected(false);
  };

  const connect = useCallback(async () => {
    logger.log('connect', { instance, userData });
    if (!instance) {
      logger.warn('empty instance on connect');
      return;
    }
    if (!userData) {
      logger.warn('empty userData on connect');
      return;
    }
    if (!roomName) {
      logger.warn('empty roomName on connect');
      return;
    }
    if (socket) {
      logger.warn('socket already created, closing...');
      socket.close();
    }
    const SocketInvertred = (await import('../shared/socket')).default;
    setSocket(
      new SocketInvertred({
        instance,
        userData,
        roomName,
      })
    );
  }, [instance, socket, userData]);

  const connectToRoom = useCallback(() => {
    logger.log('connectToRoom', { connected, connecting });
    if (connected) {
      logger.warn('connectToRoom already connected');
      return;
    }
    if (connecting) {
      logger.warn('connectToRoom already connecting');
      return;
    }

    connect();
  }, [connect, connected, connecting]);

  useEffect(() => {
    if (loadingAvatar) return;
    if (!prepared) return;
    if (connected || connecting) return;
    setConnecting(true);
    connectToRoom();
  }, [
    prepared,
    connected,
    connecting,
    setConnecting,
    connectToRoom,
    setSocket,
    userLevel,
    loadingAvatar,
  ]);

  useEffect(() => {
    if (!socket) return;
    logger.log('setting ws events...');
    socket.on('ws_status', onWSStatus);

    return () => {
      logger.log('removing ws events...');
      socket.off('ws_status', onWSStatus);
      logger.log('closing ws...');
      socket.close();
    };
  }, [onWSStatus, socket]);

  return {
    socketId,
    socket,
    connected,
    connecting,
    disconnect,
    roster,
    affectedUser,
    setAffectUser,
  };
};

export { useSocket };
