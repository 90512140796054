import request from './api';

function log(msg, evt, code, data) {
  if (typeof msg != 'string') return false;
  if (typeof evt != 'string') return false;
  if (typeof code != 'number') return false;

  return request({
    url: `/log`,
    method: 'POST',
    data: {
      msg,
      evt,
      code,
      extraData: data,
    },
  });
}

const BBService = {
  log,
};

export default BBService;
