import request from './api';

function getMessages(
  paginationpage = 1,
  paginationitems = 100,
  filters = null,
  newsRead = []
) {
  return request({
    url: `/messages/user`,
    method: 'GET',
    params: {
      paginationitems,
      paginationpage,
      sent: filters.sent > -1 ? filters.sent : null,
      archived: filters.archived > -1 ? 1 : null,
      newsread: newsRead.join(','), //send ids separated by comma
    },
  });
}

function getRecipients(idStudio = null) {
  let url = '/messages/recipients';

  if (idStudio !== null) {
    url += `?id_studio=${idStudio}`;
  }

  return request({
    url,
    method: 'GET',
    params: {},
  });
}

function getThread(id) {
  let url = `/messages/thread/${id}`;
  return request({
    url,
    method: 'GET',
    params: {},
  });
}

function getMessage(id) {
  let url = `/messages/message/${id}`;
  return request({
    url,
    method: 'GET',
    params: {},
  });
}

function getMessageTypeNews(id) {
  let url = `/messages/message/news/${id}`;
  return request({
    url,
    method: 'GET',
    params: {},
  });
}

function sendMessage(recipient, subject, content, type, panel = 1) {
  return request({
    url: `/messages/message`,
    method: 'POST',
    params: {
      title: subject,
      content,
      recipient: recipient.id,
      user_to_id: recipient.id,
      user_to_level: recipient.level,
      user_to_nick: recipient.nick,
      type,
      panel,
    },
  });
}
function markMessageAsRead(id, action = 'read') {
  return request({
    url: `/messages/message/${id}`,
    method: 'PUT',
    params: {
      action,
    },
  });
}

function replyMessage(id, content) {
  return request({
    url: `/messages/thread`,
    method: 'POST',
    data: {
      id,
      content,
    },
  });
}

function archiveMessage(id, action) {
  return request({
    url: `/messages/archive/${id}`,
    method: 'PUT',
    data: {
      action,
    },
  });
}
const MessagesService = {
  getMessages,
  getRecipients,
  sendMessage,
  getThread,
  getMessage,
  markMessageAsRead,
  replyMessage,
  archiveMessage,
  getMessageTypeNews,
};

export default MessagesService;
