import { useEffect } from 'react';
import { useRouter } from 'next/router';

import useRehydrate from '../hooks/useRehydrate';
import useInterval from '../hooks/useInterval';

import { useMessages } from '../contexts/messages';
import { useAuth } from '../contexts/auth';

const BackgroundUpdater = () => {
  const router = useRouter();
  const { isAuthenticated } = useAuth();
  const interval = router.asPath.includes('messages')
    ? 60 * 1000 //every minute when messages section
    : 5 * 60 * 1000; //every 5 minutes when outside of messages
  const { getMessagesList } = useMessages();

  useRehydrate();

  useEffect(() => {
    if (!isAuthenticated) return;
    getMessagesList();
  }, [isAuthenticated]);

  useInterval(() => getMessagesList(), isAuthenticated ? interval : null);
};

export default BackgroundUpdater;
