import { useState } from 'react';

const Maintenance = ({ children }) => {
  const [apiActive, setApiActive] = useState(true);

  const startDate = new Date(Date.UTC(2023, 8, 28, 8, 0, 0));
  const endDate = new Date(Date.UTC(2023, 8, 28, 10, 0, 0));

  //Check if a maintenance period
  const isMaintenanceDay =
    startDate.getTime() <= Date.now() && endDate.getTime() >= Date.now();

  async function checkAPIStatus() {
    try {
      const response = await fetch('https://apiv2.olecams.com/status');
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      return false;
    }
  }

  if (!isMaintenanceDay) return children;

  checkAPIStatus()
    .then((response) => setApiActive(!response ? false : true))
    .catch(() => setApiActive(false));

  if (isMaintenanceDay && apiActive) return children;

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-neutral-900 border-t-4 border-b-4 border-[#25b9b9] text-white">
      <img src="/assets/images/logo.svg" alt="Models on Webcam logo" />
      <div className="h-4" />
      <h1 className="font-semibold text-neutral-100 text-xl">
        Site under maintenance
      </h1>
      <div className="h-4" />
      <p className="text-neutral-100 text-sm">
        This site is down because of a scheduled maintenance.
      </p>
      <p className="text-neutral-100 text-sm">
        We will be back soon, please try again later.
      </p>
    </div>
  );
};

export default Maintenance;
