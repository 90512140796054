import { useGlobalContext } from '../contexts/global';

const OldReleaseAlert = () => {
  const { isOldRelease, lastVersion } = useGlobalContext();
  if (!isOldRelease) return null;

  return (
    <div className="flex flex-col md:flex-row gap-1 w-full justify-center items-center bg-blue-200 p-1 text-center text-sm">
      Hay una nueva versión disponible
      <button
        className="text-xs p-[6px] bg-blue-800 text-white rounded-md w-full md:w-auto"
        onClick={() => window.location.reload()}
      >
        Actualizar ahora
      </button>
    </div>
  );
};
export default OldReleaseAlert;
