import { useIntl } from 'react-intl';
import Link from 'next/link';

import Tooltip from '../../Tooltip';

import IconOC from '../../../public/assets/images/icons/oc_logo.svg';

const LinkOCProfile = ({ nick = '' }) => {
  const { formatMessage: t } = useIntl();

  return (
    <Link
      href={`${process.env.NEXT_PUBLIC_OLECAMS_PROFILE_URL}${nick}`}
      target="_blank"
    >
      <Tooltip content={t({ id: 'olecams_profile' })}>
        <IconOC className="text-orange-600" />
      </Tooltip>
    </Link>
  );
};

export default LinkOCProfile;
