import { useState } from 'react';

import IconWebcam from '../../../public/assets/images/icons/webcam.svg';
import IconMicrophone from '../../../public/assets/images/icons/microphone.svg';

const ButtonShowDevices = ({ roomData = null }) => {
  const [showDevices, setShowDevices] = useState(false);

  const cleanDeviceName = (name) => {
    let cleaned = name.split('(');
    if (cleaned.length > 1) {
      return `${cleaned[0]} ${cleaned[1].replace(')', '')}`;
    }
    return cleaned;
  };

  if (!roomData) return null;
  return (
    <>
      {!showDevices && (
        <span
          data-tip="Usando App"
          className="flex items-center justify-center p-1 bg-gray-800/50  text-white rounded-md cursor-pointer"
          onClick={(e) => setShowDevices(!showDevices)}
        >
          <IconMicrophone className="w-4 h-4" />
          <IconWebcam className="w-4 h-4" />
        </span>
      )}
      {showDevices && (
        <div
          className="bg-gray-800/50 text-white text-xs p-1 rounded-md cursor-pointer"
          onClick={(e) => setShowDevices(!showDevices)}
        >
          <div className="flex gap-1">
            <IconWebcam className="w-4 h-4 text-gray-400" />
            {cleanDeviceName(roomData.deviceCam)}
          </div>
          <div className="flex gap-1 pt-1">
            <IconMicrophone className="w-4 h-4 text-gray-400" />
            {cleanDeviceName(roomData.deviceMic)}
          </div>
        </div>
      )}
    </>
  );
};

export default ButtonShowDevices;
