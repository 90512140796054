import { createSlice } from '@reduxjs/toolkit';

import { setAuthInClient, setIsAuthLoadingInClient } from '../../shared/api';

const initialState = {
  isAuthenticated: false,
  token: null,
  userName: null,
  userId: 0,
  studio: null,
  idstudio: null,
  level: null,
  isAuthLoading: true,
};

export const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.userName = action.payload.username;
      state.userId = action.payload.userid;
      state.studio = action.payload.studio;
      state.idstudio = action.payload.idstudio;
      state.level = action.payload.level;
    },
    unsetAuth: () => initialState,
    setToken: (state, action) => {
      state.token = action.payload;
      setAuthInClient(action.payload);
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setIsAuthLoading: (state, action) => {
      state.isAuthLoading = action.payload;
    },
  },
});

export const {
  setAuth,
  unsetAuth,
  setToken,
  setIsAuthenticated,
  setIsAuthLoading,
} = authSlice.actions;

export default authSlice.reducer;
