import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setApiEnv } from '../store/slices/debug';
import { setNewsReadList } from '../store/slices/messages';

import { getCookiesDebug, getNewsRead } from '../shared/cookies';
import Logger from '../shared/logger';

const logger = new Logger('useRehydrate');

const useRehydrate = () => {
  const dispatch = useDispatch();

  const makeRehydrate = () => {
    //get data from persistent storage
    const { apiEnv } = getCookiesDebug();
    const newsReadList = getNewsRead();

    logger.log('Rehydrating', { apiEnv, newsReadList });

    //update Redux state
    dispatch(setApiEnv(apiEnv));
    dispatch(setNewsReadList(newsReadList));
  };

  useEffect(() => {
    makeRehydrate();
  }, []);
};

export default useRehydrate;
