import request from './api';

const getAccessToken = ({ modelId, tipo, roomName }) =>
  request({
    method: 'post',
    url: `/admin/roommonitor/${modelId}`,
    data: {
      tipo,
      roomName,
    },
  });
const deleteAccessToken = ({ accessToken }) =>
  request({
    method: 'delete',
    url: `/admin/roommonitor/${accessToken}`,
  });

const soundAlarm = (id) =>
  request({
    method: 'get',
    url: `/admin/alarm/${id}`,
  });
const MonitorService = {
  getAccessToken,
  deleteAccessToken,
  soundAlarm,
};

export default MonitorService;
