import { useEffect, useState } from 'react';
import { useMonitor } from '../../../contexts/monitor';

import WebcammerService from '../../../shared/webcamer-service';

import IconVideo from '../../../public/assets/images/icons/file-video.svg';

const StreamProfile = () => {
  const { roomData, modelId } = useMonitor();

  const [showStreamProfile, setShowStreamProfile] = useState(false);
  const [rawStreamProfile, setRawStreamProfile] = useState(null);
  const [streamProfile, setStreamProfile] = useState(null);

  //set stream profile based on performer device
  useEffect(() => {
    if (!roomData) return;
    if (!rawStreamProfile) return;
    if (Number(roomData.device) === 1) {
      setStreamProfile(rawStreamProfile.mobile.name);
      return;
    }
    setStreamProfile(rawStreamProfile.desktop.name);
  }, [rawStreamProfile, roomData]);

  //get performer data
  useEffect(() => {
    if (!modelId) return;

    WebcammerService.getPerformer(parseInt(modelId))
      .then((performerData) => {
        //set raw stream profile
        setRawStreamProfile({
          desktop: performerData.stream_profile_data,
          mobile: performerData.mobile_stream_profile_data,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }, [modelId]);

  return (
    <div className="absolute bottom-0 m-2 bg-gray-800/40 p-1 cursor-pointer rounded-lg text-white">
      {!showStreamProfile && (
        <IconVideo
          className="w-5 h-5 text-xl"
          onClick={(e) => {
            setShowStreamProfile(!showStreamProfile);
          }}
        />
      )}
      {showStreamProfile && (
        <div
          className="text-xs"
          onClick={(e) => {
            setShowStreamProfile(!showStreamProfile);
          }}
        >
          <b>{streamProfile}</b>
          <br />
          {roomData.videow}x{roomData.videoh} - {roomData.qual} / {roomData.fps}{' '}
          fps
        </div>
      )}
    </div>
  );
};

export default StreamProfile;
