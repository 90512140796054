import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useNotifications } from '../contexts/notifications';

import { toast } from 'react-toastify';
import Logger from '../shared/logger';
import { BROWSER_NOTIFICATIONS_TYPES } from '../shared/constants';
import { useIntl } from 'react-intl';

const logger = new Logger('BrowserNotifications');

const BrowserNotificationsStatus = () => {
  const { formatMessage: t } = useIntl();
  const { allowed: notificationsAllowed } = useNotifications();
  const toastId = 'notificationsToast';

  useEffect(() => {
    if (notificationsAllowed) return;
    toast.warn(t({ id: 'browser_notifications_denied' }), {
      closeOnClick: true,
      autoClose: false,
      toastId,
    });
  }, [notificationsAllowed]);
};

export default BrowserNotificationsStatus;
