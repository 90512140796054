import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: [
    'b',
    'i',
    'em',
    'strong',
    'a',
    'ul',
    'li',
    'ol',
    'p',
    'img',
    'br',
  ],
  allowedAttributes: {
    a: ['href', 'target'],
    img: ['src'],
  },
  allowedIframeHostnames: ['www.youtube.com'],
};

const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }),
});

const SanitizeHTML = ({ tagName = 'div', html, options }) => {
  const Tag = tagName === 'div' ? 'div' : tagName === 'span' ? 'span' : tagName;
  return <Tag dangerouslySetInnerHTML={sanitize(html, options)} />;
};

export default SanitizeHTML;
