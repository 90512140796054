import axios from 'axios';
import Logger from './logger';

import { unsetCookieToken } from './cookies';

const logger = new Logger('ApiService');

let token = null;
let isAuthenticated = false;
let apiEnvironment = 'prod';
let client;

export const setClient = () => {
  let headers = {
    Accept: 'application/json; charset=utf-8',
    'Content-Type': 'application/json; charset=utf-8',
  };

  if (token !== null) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  client = axios.create({
    baseURL:
      apiEnvironment === 'dev'
        ? process.env.NEXT_PUBLIC_APIURL_DEV
        : apiEnvironment === 'local'
        ? process.env.NEXT_PUBLIC_APIURL_LOCAL
        : process.env.NEXT_PUBLIC_APIURL,
    headers,
  });
};

export const setAuthInClient = (_token = null) => {
  if (_token === null) return;
  token = _token;
  setClient();
};

export const setIsAuthenticatedInClient = (_isAuthenticated = false) => {
  isAuthenticated = _isAuthenticated;
  setClient();
};

export const setApiEnvironmentInClient = (environment = 'prod') => {
  apiEnvironment = environment;
  setClient();
};

setClient();

const forceLogout = () => {
  unsetCookieToken();
};

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {
  const start = Date.now();

  const onSuccess = function (response) {
    logger.log(
      response.config.method.toUpperCase(),
      response.config.url,
      `successful in ${Date.now() - start}ms`
    );

    return options.fullResponse ? response.data : response.data.message;
  };

  const onError = function (error) {
    logger.error('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      logger.error('Status:', error.response.status);
      logger.error('Data:', error.response.data);
      logger.error('Headers:', error.response.headers);
      if (error.response.status === 401 && isAuthenticated) {
        //if get 401 and user is logged in then force logout
        forceLogout();
      }
    } else {
      // Something else happened while setting up the request
      // triggered the error
      logger.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
